import { Flex } from '@chakra-ui/react'
import { displayTime } from 'components/common/utils/timeUtils'
import { DocumentPermissions } from 'data/common'
import { User } from 'firebase/auth'
import React, { CSSProperties, useCallback, useMemo, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { EventDefinition } from 'templates/TemplateConfig'
import { Colors } from '../Colors'
import { useWindowDimensions } from '../UseWindowDimensions'
import { PwaInstaller } from '../components/common/InstallPwa'
import { RoundButton } from '../components/common/RoundButton'
import { ReviewSelection } from '../data/ReviewSelectionInit'
import { TimelineEvent, TimelineNote } from '../data/TimelineEvent'
import { ViewingMode } from '../data/ViewingMode'
import { isNoteDefinition } from '../data/common'
import comment_icon from '../icons/comment.png'
import message_icon from '../icons/message_icon.svg'
import sketch_icon from '../icons/squiggle.svg'
import { ActionProps } from '../util/dialogUtils'
import { ClipboardCopyFn, getThumbnail, useCopyLinkToClipBoard } from '../util/links'
import { ClipCard, InstructionCard } from './ClipCard'
import { PlayerStore } from './PlayerStore'
import { ShowHidePanel } from './ShowHidePanel'
import { SelectedEventState, TimelineStore } from './TimelineStore'
import { NotificationRegisterer } from './useNotificationsRegistration'

let globalShowCommentPanel: (show: boolean) => void = () => {
  throw Error("Comments Panel hasn't been rendered")
}

export const showCommentPanel: (show?: boolean) => void = (show = true) =>
  globalShowCommentPanel(show)
const COMMENT_BAR_FONT_SCALE_KEY = 'comments_bar_font_scale'

// const COMMENT_BAR_EXPAND_KEY = 'comments_bar_expand'

export function VerticalCommentsPanel({
  isLocalReview,
  timelineStore,
  viewingMode,
  baseViewingMode,
  playerStore,
  title,
  style,
  onCommentPlayRequest,
  displayStyle = 'medium',
  enterEditMode,
  navigateToSignIn,
  user,
  showSignInToEditDialog,
  documentPermissions,
  notificationRegistrationStatus,
  reviewSelectionState,
  pwaInstaller,
  onSketchShowRequest,
  watchMode,
  ...props
}: {
  title: string | undefined
  pwaInstaller: PwaInstaller | undefined
  reviewSelectionState: ReviewSelection | undefined
  notificationRegistrationStatus: NotificationRegisterer
  isLocalReview: boolean
  showSignInToEditDialog: undefined | ((props?: ActionProps) => void)
  enterEditMode?: (user: User) => void
  user: User | undefined
  navigateToSignIn: () => void
  timelineStore: TimelineStore | undefined
  viewingMode: ViewingMode
  baseViewingMode: ViewingMode
  playerStore: PlayerStore
  style?: CSSProperties
  documentPermissions: DocumentPermissions | undefined
  onCommentPlayRequest: (time: number, eventId: string) => void
  onSketchShowRequest: ((event: TimelineEvent<EventDefinition>) => void) | undefined
  displayStyle?: 'large' | 'medium'
  watchMode?: boolean
  onAddComment?: () => void
  onAddSketch?: () => void
  onEditSketch?: () => void
}) {
  const panelWidth = useMemo(() => (displayStyle === 'large' ? '350px' : '300px'), [displayStyle])
  const defaultContentFontSize = useMemo(() => (displayStyle === 'large' ? 20 : 15), [displayStyle])
  const windowDimensions = useWindowDimensions()
  const comments = useMemo(() => {
    return timelineStore?.sortedEvents
      .filter((i) => i.specialType === 'note')
      .filter(
        (e) =>
          !e?.who?.length ||
          !playerStore.selectedPlayers?.length ||
          e.who.any((w) => !!playerStore.selectedPlayers?.includes(w)),
      )
  }, [timelineStore?.sortedEvents, playerStore.selectedPlayers])

  const clipboardCopier = useCopyLinkToClipBoard()
  const handleShareCommentClicked = useCallback(
    (copyToClipboard: ClipboardCopyFn) => async (event: TimelineEvent<EventDefinition>) => {
      if (!reviewSelectionState) {
        return
      }

      const commentBy =
        event.createBy?.displayName?.trim() || event.modifiedBy?.lastOrNull()?.displayName?.trim()
      const trimmedComment = event.extra?.message?.slice(0, 40)
      const startTime = Math.max(event.time - 4, 0)
      await copyToClipboard({
        canonical: `https://playbackreview.com/editor?id=${reviewSelectionState.reviewId}&mode=${documentPermissions}&eventid=${event.id}&t=${startTime}`,
        pageTitle: `${displayTime(event.time)} | ${commentBy ? `Comment by ${commentBy} | ` : ''}"${trimmedComment}..."`,
        thumbnail: await getThumbnail(reviewSelectionState.videoId, reviewSelectionState.source),
        reviewTitle: title,
        socialMediaText: `Check out this PlayBack clip!\n\n"${event.extra?.message}" ${commentBy ? ` by ${commentBy} | ` : ''}`,
      })
    },
    [documentPermissions, reviewSelectionState, title],
  )

  const defaultVisibility = useMemo(
    () =>
      (reviewSelectionState?.eventId &&
        isNoteDefinition(timelineStore?.getEvent(reviewSelectionState?.eventId)?.definition_key)) ||
      ((viewingMode === 'edit' || !!comments?.length) && !isMobile),
    [viewingMode, comments?.length, timelineStore, reviewSelectionState],
  )
  const userVisibilityState = useState<boolean | undefined>(undefined)
  const [, setUserSetVisibility] = userVisibilityState

  globalShowCommentPanel = useCallback(
    (show: boolean) => {
      setUserSetVisibility(show)
    },
    [setUserSetVisibility],
  )

  const Footer = useCallback(
    () => (
      <>
        {watchMode && documentPermissions === 'edit' && (
          <Flex
            justifyContent={'center'}
            padding={10}
            gap={8}>
            {props.onAddComment && (
              <RoundButton
                onClick={props.onAddComment}
                icon={message_icon}
                alt={'Add a note'}
                backgroundColor={Colors.color_comments_orange}
                squashedChildrenFallback={['Note']}>
                Add Note
              </RoundButton>
            )}
            {!props.onEditSketch && props.onAddSketch && (
              <RoundButton
                onClick={props.onAddSketch}
                color={'white'}
                icon={sketch_icon}
                alt={'Draw a sketch'}
                backgroundColor={Colors.color_sketch_green}
                squashedChildrenFallback={['Sketch', 'Draw']}>
                Add Sketch
              </RoundButton>
            )}
            {props.onEditSketch && (
              <RoundButton
                onClick={props.onEditSketch}
                color={'white'}
                icon={sketch_icon}
                alt={'Draw a sketch'}
                backgroundColor={Colors.color_sketch_green}
                squashedChildrenFallback={['Sketch', 'Draw']}>
                Add Sketch
              </RoundButton>
            )}
          </Flex>
        )}
      </>
    ),
    [props.onAddSketch, props.onAddComment, props.onEditSketch, documentPermissions, watchMode],
  )

  return (
    <>
      <ShowHidePanel
        side={'right'}
        style={style}
        fontScaleStorageKey={COMMENT_BAR_FONT_SCALE_KEY}
        width={panelWidth}
        defaultFontSize={defaultContentFontSize}
        color={'#f1bf46'}
        title={'Notes'}
        userVisibilityState={userVisibilityState}
        defaultVisibility={defaultVisibility}
        tabIcon={comment_icon}
        FloatingFooter={Footer}>
        <CommentPanelContent
          pwaInstaller={pwaInstaller}
          notificationRegistrationStatus={notificationRegistrationStatus}
          displayStyle={displayStyle}
          timelineStore={timelineStore}
          isLocalReview={isLocalReview}
          showSignInToEditDialog={showSignInToEditDialog}
          onShareCommentClicked={
            clipboardCopier.copy && handleShareCommentClicked(clipboardCopier.copy)
          }
          enterEditMode={enterEditMode}
          user={user}
          documentPermissions={documentPermissions}
          navigateToSignIn={navigateToSignIn}
          viewingMode={viewingMode}
          baseViewingMode={baseViewingMode}
          comments={comments}
          onCommentPlayRequest={(time, eventId) => {
            onCommentPlayRequest(time, eventId)
            windowDimensions.width < 600 && setUserSetVisibility(false)
          }}
          onSketchShowRequest={(event: TimelineEvent<EventDefinition>) => {
            onSketchShowRequest?.(event)
            windowDimensions.width < 600 && setUserSetVisibility(false)
          }}
        />
      </ShowHidePanel>
    </>
  )
}

export function CommentPanelContent({
  baseViewingMode,
  displayStyle,
  documentPermissions,
  enterEditMode,
  isLocalReview,
  navigateToSignIn,
  onCommentPlayRequest,
  onShareCommentClicked,
  showSignInToEditDialog,
  timelineStore,
  user,
  viewingMode,
  comments,
  notificationRegistrationStatus,
  pwaInstaller,
  onSketchShowRequest,
}: {
  pwaInstaller: PwaInstaller | undefined
  notificationRegistrationStatus: NotificationRegisterer
  comments: TimelineNote<EventDefinition>[] | undefined
  isLocalReview: boolean
  showSignInToEditDialog: undefined | ((props?: ActionProps) => void)
  onShareCommentClicked: ((event: TimelineEvent<EventDefinition>) => void) | undefined
  enterEditMode?: (user: User) => void
  user: User | undefined
  navigateToSignIn: () => void
  timelineStore: TimelineStore | undefined
  viewingMode: ViewingMode
  baseViewingMode: ViewingMode
  documentPermissions: DocumentPermissions | undefined
  onCommentPlayRequest: (time: number, eventId: string) => void
  onSketchShowRequest: (event: TimelineEvent<EventDefinition>) => void
  displayStyle: 'large' | 'medium' | undefined
}) {
  const iconSize = useMemo(() => (displayStyle === 'large' ? 35 : 30), [displayStyle])
  const selectedEvents: Map<string, SelectedEventState[]> | undefined = useMemo(() => {
    return timelineStore?.selectedEvents.groupBy((it) => it.id)
  }, [timelineStore?.selectedEvents])
  return (
    <>
      <InstructionCard
        pwaInstaller={pwaInstaller}
        notificationRegisterer={notificationRegistrationStatus}
        isLocalReview={isLocalReview}
        baseViewingMode={baseViewingMode}
        user={user}
        enterEditMode={enterEditMode}
        viewingMode={viewingMode}
        documentPermissions={documentPermissions}
        navigateToSignIn={navigateToSignIn}
      />
      {comments?.map((e) => {
        // { isSelected: !!selectedState, hovered: selectedState?.hovered, play: selectedState?.play! }
        const selectedState = selectedEvents?.get(e.id)
        return (
          <ClipCard
            editMode={viewingMode === 'edit' && e.source === 'local'}
            canEnableEditing={documentPermissions === 'edit' && e.source === 'local'}
            isLocalReview={isLocalReview}
            isSelected={!!selectedState?.length}
            isHovered={!!selectedState?.any((it) => it.hovered)}
            showSignInToEditDialog={showSignInToEditDialog}
            key={e.id}
            user={user}
            fontSize={'1em'}
            playerIconSize={iconSize}
            timelineStore={timelineStore}
            timelineEvent={e}
            viewingMode={viewingMode}
            onPlayButtonClicked={() => {
              timelineStore?.setSelectedEvents([
                {
                  id: e.id,
                  hovered: true,
                },
              ])
              onCommentPlayRequest(e.time, e.id)
            }}
            onShowSketchButtonClicked={() => {
              timelineStore?.setSelectedEvents([
                {
                  id: e.id,
                  hovered: true,
                },
              ])
              onSketchShowRequest(e)
            }}
            onShareButtonClicked={onShareCommentClicked && (() => onShareCommentClicked(e))}
            documentPermissions={documentPermissions}
          />
        )
      })}
    </>
  )
}
