import { FirebaseGroupEntry, MetaStatsCache, RecentsFirebaseEntry } from 'data/common'
import { User } from 'firebase/auth'
import { limitToFirst } from 'firebase/database'
import getVideoId from 'get-video-id'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  FirebaseDb,
  useDatabaseNullableRefLiveValue,
  useDatabasePathLiveValue,
  useNullableDatabasePathLiveValue,
} from './components/common/Firebase'
import {
  BroadcastFirebaseEntry,
  FirebaseBroadCastReviews,
  FirebaseUserReviews,
  VideoSourceData,
} from './data/ReviewSelectionInit'

export interface ReviewsStore {
  orderedReviews: (RecentsFirebaseEntry & MetaStatsCache)[] | undefined | null
  setFilterByMe: (filterByMe: boolean) => void
  deleteReview: (entry: RecentsFirebaseEntry) => Promise<void>
  findMatchingReview: (videoSource: VideoSourceData) => (RecentsFirebaseEntry & MetaStatsCache)[]
}

export function useRecentsReviewStore(
  firebaseDb: FirebaseDb,
  user: User | undefined,
  groupId?: string,
): ReviewsStore {
  const userReviews = useNullableDatabasePathLiveValue<FirebaseUserReviews>(
    firebaseDb,
    user?.uid && !groupId ? `users/${user?.uid}/reviews` : undefined,
  )
  const groupReviews = useNullableDatabasePathLiveValue<FirebaseGroupEntry['reviews']>(
    firebaseDb,
    groupId && `groups/${groupId}/reviews`,
  )

  const reviewEntries = useMemo(() => {
    if (groupId) {
      if (groupReviews instanceof Error) return null
      return groupReviews
    }

    if (userReviews instanceof Error) return null
    return userReviews ? { ...userReviews.owned, ...userReviews.visited } : userReviews
  }, [userReviews, groupId, groupReviews])

  const deleteReview = useCallback(
    async (entry: RecentsFirebaseEntry) => {
      if (!user) return
      if (groupId) {
        await firebaseDb.getRef(`groups/${groupId}/reviews/${entry.reviewId}`).remove()

        await firebaseDb.getRef(`reviews/${entry.reviewId}/groups/${groupId}`).remove()
        return
      }
      await firebaseDb.getRef(`users/${user.uid}/reviews/owned/${entry.reviewId}`).remove()
      await firebaseDb.getRef(`users/${user.uid}/reviews/visited/${entry.reviewId}`).remove()
    },
    [firebaseDb, user, groupId],
  )
  return useReviewStore(user, reviewEntries, deleteReview)
}

function useReviewStore(
  user: User | undefined,
  reviewEntries: { [p: string]: RecentsFirebaseEntry & MetaStatsCache } | undefined | null,
  deleteReview: (entry: RecentsFirebaseEntry) => Promise<void>,
): ReviewsStore {
  const [filterByMe, setFilterByMe] = useState(false)

  const orderedReviews = useMemo(() => {
    return !reviewEntries ? reviewEntries : (
        Object.entries(reviewEntries)
          .filter(([reviewId, entry]) => (filterByMe ? entry.ownerUID === user?.uid : true))
          // Some review entries became broken
          .mapNotNull(([reviewId, entry]) => ({ ...entry, reviewId: reviewId }))
          .orderByDesc((entry) => entry.lastVisited)
      )
  }, [reviewEntries, filterByMe, user])

  const findMatchingReview = useCallback(
    (videoSource: VideoSourceData) =>
      Object.values(reviewEntries ?? {}).filter(
        (entry) =>
          entry.videoId &&
          ((entry.source === videoSource.source && entry.videoId === videoSource.id) ||
            (entry.source === 'Youtube_url' &&
              videoSource.source === 'Youtube_url' &&
              getVideoId(videoSource.id).id === getVideoId(entry.videoId).id)),
      ),
    [reviewEntries],
  )

  return useMemo(
    () => ({ orderedReviews, setFilterByMe, findMatchingReview, deleteReview }),
    [orderedReviews, setFilterByMe, findMatchingReview, deleteReview],
  )
}

export function useBroadcastedReviewStore(firebaseDb: FirebaseDb) {
  const [reviewEntries, setReviewEntries] = useState<{
    [id: string]: BroadcastFirebaseEntry & MetaStatsCache
  }>({})
  useEffect(() => {
    const unsubscribe = firebaseDb.getRef(`broadcasts`, limitToFirst(2))?.onValue(
      (snapshot) => {
        if (!snapshot.exists()) {
          setReviewEntries({})
          return
        }
        const broadCastReviews: FirebaseBroadCastReviews = snapshot.val()
        setReviewEntries(broadCastReviews.global || {})
      },
      (error) => console.log(error),
      {},
    )
    return () => unsubscribe?.()
  }, [firebaseDb])
  const orderedReviews = useMemo(
    () => Object.values(reviewEntries).orderByDesc((entry) => entry.createdTime),
    [reviewEntries],
  )
  return useMemo(() => ({ broadcastedReviews: orderedReviews }), [orderedReviews])
}
