import '@stripe/stripe-js'
import {
  FlagsContextProvider,
  getDefaultFlagProviders,
} from 'components/common/hooks/useFeatureFlags'
import { StrictMode, useMemo } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import ReactDOM from 'react-dom/client'
import ReactTagManager from 'react-ga4'
import { BrowserRouter, Routes } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { firebaseConfig } from 'services/FirebaseConfig'
import './App.css'
import { routes } from './appRoutes'
import { DialogServer } from './components/common/Dialog'
import { disableErrorOverlay, usePromiseState } from './components/common/utils/reactUtils'
import './index.css'
import './locales/i18n'
import reportWebVitals from './reportWebVitals'
import './ui/EventFilterBar.css'
import './util/stringUtils'

ReactTagManager.initialize([{ trackingId: 'G-XGBS9ZG3EX' }])

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
if (process.env.NODE_ENV === 'development') {
  disableErrorOverlay()
}
// We listen to the resize event
window.addEventListener('resize', () => {
  // We execute the same script as before
  const vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty('--vh', `${vh}px`)
})

const flagProviders = getDefaultFlagProviders(firebaseConfig)

root.render(
  <StrictMode>
    <DndProvider
      backend={HTML5Backend}
      context={window}>
      {/* <ColorModeScript init ialColorMode={theme.config.initialColorMode} /> */}
      {/* <ChakraProvider theme={theme}> */}
      <FlagsContextProvider flagProviders={flagProviders}>
        <BrowserRouter>
          <Routes>{routes}</Routes>
        </BrowserRouter>
        <DialogServer />
        <ToastContainer style={{ zIndex: 100000 }} />
        {/* </ChakraProvider> */}
      </FlagsContextProvider>
    </DndProvider>
  </StrictMode>,
)
// root.render(
//   <React.StrictMode>
//     <RouterProvider router={router} />
//   </React.StrictMode>
// )
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
