import { Box, BoxProps, Flex } from '@chakra-ui/react'
import * as CSS from 'csstype'
import * as React from 'react'
import {
  CSSProperties,
  ForwardedRef,
  PropsWithChildren,
  ReactElement,
  forwardRef,
  useMemo,
} from 'react'

export const Marker = forwardRef(function Marker(
  {
    backgroundColor = 'white',
    tagBackgroundColor = 'black',
    displayStyle,
    onContainerHoverChange,
    onCircleHoverChange,
    tag,
    verticalUi,
    horizontalUi,
    outline,
    outlineColor,
    ...props
  }: Omit<BoxProps, 'outline'> &
    PropsWithChildren & {
      backgroundColor?: CSS.Property.Color
      displayStyle: 'down' | 'up' | 'circle'
      onContainerHoverChange?: (hover: boolean) => void
      onCircleHoverChange?: (hover: boolean) => void
      tag?: () => ReactElement | undefined
      verticalUi?: () => ReactElement | undefined
      horizontalUi?: () => ReactElement | undefined
      tagBackgroundColor?: CSS.Property.Color
      outline?: boolean | undefined
    },
  ref: ForwardedRef<HTMLDivElement>,
) {
  const variationStyles = useMemo(() => {
    const variationStyles: {
      container?: CSSProperties
      point?: CSSProperties
      circle?: CSSProperties
      tag?: CSSProperties
      verticalUI?: CSSProperties
      horizontalUI?: CSSProperties
    } = {}
    switch (displayStyle) {
      case 'down':
        variationStyles.point = {
          top: 12,
          borderTop: `12px solid ${backgroundColor}`,
        }
        variationStyles.tag = {
          paddingBottom: '14px',
        }
        break
      case 'up':
        variationStyles.container = {
          flexDirection: 'column-reverse',
          justifyContent: 'flex-end',
        }
        variationStyles.point = {
          bottom: 12,
          borderBottom: `12px solid ${backgroundColor}`,
        }
        variationStyles.tag = {
          paddingTop: '14px',
        }
        variationStyles.verticalUI = {
          flexDirection: 'column',
          justifyContent: 'flex-start',
        }
        break
      case 'circle':
        variationStyles.point = {
          display: 'none',
        }
        variationStyles.tag = {
          paddingBottom: '12px',
        }
        break
    }
    return variationStyles
  }, [displayStyle, backgroundColor])

  return (
    <Box
      {...props}
      ref={ref}
      style={{
        flexDirection: 'column',
        justifyContent: 'flex-end',
        display: 'flex',
        alignItems: 'flex-start',
        transition: 'transform 100ms ease-in-out, background-color 200ms ease-in-out',
        pointerEvents: 'none',
        width: '20px',
        height: '20px',
        ...variationStyles.container,
        ...props.style,
      }}>
      {horizontalUi ?
        <Flex
          alignItems={'center'}
          style={{
            position: 'absolute',
            fontSize: '12px',
            flexDirection: 'column',
            fontFamily: 'LeagueSpartan, sans-serif',
            fontVariationSettings: "'wght' 500",
            zIndex: 2,
            left: '40px',
            paddingRight: '4px',
            justifyContent: 'flex-start',
            pointerEvents: 'auto',
            height: 20,
            ...variationStyles.horizontalUI,
          }}>
          {horizontalUi()}
        </Flex>
      : undefined}
      {verticalUi ?
        <Flex
          alignItems={'center'}
          style={{
            flexDirection: 'column-reverse',
            justifyContent: 'flex-start',
            fontSize: '12px',
            fontFamily: 'LeagueSpartan, sans-serif',
            fontVariationSettings: "'wght' 500",
            pointerEvents: 'all',
            zIndex: -2,
            paddingBottom: '4px',
            paddingTop: '4px',
            width: '20px',
            ...variationStyles.verticalUI,
          }}>
          {verticalUi()}
        </Flex>
      : undefined}
      {tag ?
        <div
          style={{
            width: '20px',
            fontSize: '14px',
            fontFamily: 'LeagueSpartan, sans-serif',
            fontVariationSettings: "'wght' 550",
            color: 'white',
            pointerEvents: 'all',
            backgroundColor: tagBackgroundColor,
            zIndex: -1,
            paddingBottom: '4px',
            paddingTop: '4px',
            marginBottom: '-10px',
            marginTop: '-10px',
            borderRadius: 9999,
            ...variationStyles.tag,
          }}>
          {tag()}
        </div>
      : undefined}
      <Box
        backgroundColor={backgroundColor}
        style={{
          flexGrow: 0,
          flexShrink: 0,
          borderRadius: '50%',
          border: '0 solid #fff',
          width: '20px',
          height: '20px',
          display: 'flex',
          justifyContent: 'center',
          cursor: 'grab',
          pointerEvents: 'all',
          outline: outline ? `${outlineColor ?? 'rgba(255, 255, 255, 1.0)'} solid 2px` : undefined,
          transition: 'transform 200ms ease-in-out, background-color 200ms ease-in-out',
          ...variationStyles.circle,
        }}>
        {props.children}
      </Box>
      {displayStyle !== 'circle' && (
        <div
          style={{
            position: 'absolute',
            content: '',
            width: 0,
            height: 0,
            left: 0,
            border: '10px solid transparent',
            pointerEvents: 'none',
            zIndex: -1,
            ...variationStyles.point,
          }}
        />
      )}
    </Box>
  )
})
