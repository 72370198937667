import { ChevronLeftIcon } from '@chakra-ui/icons'
import {
  CollapsableTopBar,
  NavButton,
  NavItemContainer,
  TextOnlyNavButton,
  VerticalMenu,
  VerticalSubMenu,
} from 'components/CollapsableTopBar'
import { useFeatureFlag } from 'components/common/hooks/useFeatureFlags'
import { useQueryString } from 'components/common/utils/QueryString'
import { cn } from 'components/common/utils/tailwindUtils'
import { DocumentPermissions } from 'data/common'
import { ReactNode, useCallback, useState } from 'react'
import { isMobile } from 'react-device-detect'
import ReactTagManager from 'react-ga4'
import { useNavigate } from 'react-router-dom'
import { firebaseConfig } from 'services/FirebaseConfig'
import { useWindowDimensions } from './UseWindowDimensions'
import { ShareDialogOptions, showShareReviewDialog } from './components/ShareReviewDialog'
import { VideoController } from './components/VideoController'
import { WrappedAuth, useFirebase } from './components/common/Firebase'
import { useUser } from './components/common/UseUser'
import create_review from './icons/create_review.png'
import graphIcon from './icons/graph_icon.png'
import homeIcon from './icons/home_icon.png'
import shareIcon from './icons/share_button.png'
import { HighlightsButtons, HighlightsMode } from './ui/HighlightsButtons'
import { HighlightPlayer, HighlightTypes } from './ui/HighlightsPlayer'
import { useCopyLinkToClipBoard } from './util/links'

export function HomeNavigationBar({
  auth,
  shareDialogOptions,
  showCreateButton = true,
  leftSideChildren,
  rightSideChildren,
}: {
  showCreateButton?: boolean
  leftSideChildren?: ReactNode
  rightSideChildren?: ReactNode
  auth: WrappedAuth
  shareDialogOptions?: ShareDialogOptions
}) {
  const clipboardCopier = useCopyLinkToClipBoard()
  const navigate = useNavigate()
  const { SignIn } = useUser({
    auth,
  })
  const { value: bookReviewPage } = useFeatureFlag('bookReviewPage', false)
  const [backbuttonDestination] = useQueryString('referrer_url', undefined)

  const handleShareButtonClicked = useCallback((shareDialogOptions: ShareDialogOptions) => {
    showShareReviewDialog(shareDialogOptions)
  }, [])

  return (
    <CollapsableTopBar
      leftMainButton={() => (
        <>
          {backbuttonDestination && (
            <NavButton
              onClick={() => navigate(backbuttonDestination)}
              className='bg-[rgba(189,32,38,0.54)] text-white font-semibold'
              alt={'Go to back to the previous page'}>
              <ChevronLeftIcon fontSize={30}></ChevronLeftIcon>
              Back
            </NavButton>
          )}
          {!backbuttonDestination && <PlaybackHomeButton showCreateButton={showCreateButton} />}
        </>
      )}
      rightFixedChildren={() => [
        rightSideChildren,
        <NavItemContainer key='signin'>
          <SignIn />
        </NavItemContainer>,
        clipboardCopier.copy && shareDialogOptions && (
          <NavButton
            key='share'
            onClick={() => handleShareButtonClicked(shareDialogOptions)}
            icon={shareIcon}
            className='bg-[#1c1c1c] text-white'
            alt={'share this review as a link'}
            disabled={clipboardCopier.isCopying}
            squashedChildrenArray={['']}>
            Share
          </NavButton>
        ),
      ]}>
      {() => [
        <TextOnlyNavButton
          key='leaderboard'
          onClick={() => navigate('/leaderboards?id=-NzhvbHFWR6At-AwpyzC')}>
          NSWDL Leaderboard
        </TextOnlyNavButton>,
        <TextOnlyNavButton
          key='media'
          onClick={() => navigate('/media')}>
          Media
        </TextOnlyNavButton>,
        bookReviewPage && (
          <TextOnlyNavButton
            key='book'
            onClick={() => navigate('/bookareview')}>
            Book a PRO review
          </TextOnlyNavButton>
        ),
        <VerticalSubMenu
          key={'partnerships'}
          label='Partnerships'>
          <TextOnlyNavButton
            key='pbdodgeball'
            onClick={() => window.open('https://www.pbdodgeball.co.uk/')}>
            PB Dodgeball Club | London UK
          </TextOnlyNavButton>
          <TextOnlyNavButton
            key='dodgeballhub'
            onClick={() => window.open('https://www.dodgeballhub.com/')}>
            Dodgeball Hub | Events
          </TextOnlyNavButton>
        </VerticalSubMenu>,
        <TextOnlyNavButton
          key='about'
          onClick={() => navigate('/about')}>
          About
        </TextOnlyNavButton>,
        leftSideChildren,
      ]}
    </CollapsableTopBar>
  )
}

export function EditorNavigationBar({
  canExitWatchMode,
  onFullscreenClicked,
  highlightPlayer,
  highlightsMode,
  isFullScreen,
  isPlayingHighlights,
  onShowAnalysisClicked,
  onWatchModeClicked,
  documentPermissions,
  auth,
  shareDialogOptions,
  videoController,
  watchMode,
  hasPlayersOrFilters,
  showCreateButton = true,
  height = 48,
  trackingProgress = 0,
}: {
  height?: number
  showCreateButton?: boolean
  documentPermissions: DocumentPermissions | undefined
  videoController: VideoController | undefined
  isPlayingHighlights: false | HighlightTypes
  highlightPlayer: HighlightPlayer | undefined
  auth: WrappedAuth
  highlightsMode: HighlightsMode
  hasPlayersOrFilters: boolean
  onFullscreenClicked: () => void
  watchMode: boolean
  onWatchModeClicked: () => void
  canExitWatchMode: boolean
  isFullScreen: boolean
  onShowAnalysisClicked?: (show: boolean) => void
  shareDialogOptions: ShareDialogOptions | undefined
  trackingProgress: number
}) {
  const clipboardCopier = useCopyLinkToClipBoard()
  const windowDimensions = useWindowDimensions()
  const navigate = useNavigate()
  const { SignIn } = useUser({
    auth,
  })
  const compactMode = windowDimensions.width <= 900
  const extremeCompact = windowDimensions.width <= 500

  const handleShareButtonClicked = useCallback((shareDialogOptions: ShareDialogOptions) => {
    showShareReviewDialog(shareDialogOptions)
  }, [])

  const [backbuttonDestination] = useQueryString('referrer_url', undefined)

  return (
    <CollapsableTopBar
      leftMainButton={() => [
        backbuttonDestination ?
          <NavButton
            onClick={() => navigate(backbuttonDestination)}
            className='bg-[rgba(189,32,38,0.54)] text-white font-semibold'
            alt={'Go to back to the previous page'}>
            <ChevronLeftIcon fontSize={30}></ChevronLeftIcon>
            Back
          </NavButton>
        : <PlaybackHomeButton
            documentPermissions={documentPermissions}
            showCreateButton={showCreateButton}
            compactMode={compactMode}
          />,
      ]}
      rightFixedChildren={() => [
        <NavItemContainer
          showOnlyInVerticalMenu={true}
          key='signin'>
          <SignIn />
        </NavItemContainer>,
        onShowAnalysisClicked && (
          <NavButton
            key='analysis'
            icon={graphIcon}
            onClick={() => {
              ReactTagManager.event('show_insights_clicked', {
                category: 'insights',
              })
              onShowAnalysisClicked(true)
              videoController?.pause()
            }}
            className={cn(
              'bg-white text-black',
              trackingProgress >= 0.8 && 'animate-rainbowBorder border-[3px]',
            )}
            alt={'View statistics'}
            squashedChildrenArray={['']}>
            Insights
          </NavButton>
        ),
        clipboardCopier.copy && shareDialogOptions && !watchMode && (
          <NavButton
            key='share'
            onClick={() => handleShareButtonClicked(shareDialogOptions)}
            icon={shareIcon}
            className='bg-[#1c1c1c] text-white'
            disabled={clipboardCopier.isCopying}
            squashedChildrenArray={['Share', '']}>
            {highlightPlayer?.isPlayingHighlights ? `Share ${highlightsMode}` : 'Share Review'}
          </NavButton>
        ),
      ]}>
      {() => [
        <>
          {videoController && highlightPlayer && (
            <NavItemContainer>
              <HighlightsButtons
                height={height - 8}
                hasPlayersOrFilters={hasPlayersOrFilters}
                videoController={videoController}
                isPlayingHighlights={isPlayingHighlights}
                highlightPlayer={highlightPlayer}
                displayStyle={
                  extremeCompact ? 'smallest'
                  : compactMode ?
                    'small'
                  : 'large'
                }
                highlightsMode={highlightsMode}
                fullscreenClicked={onFullscreenClicked}
                watchMode={watchMode}
                onWatchModeClicked={onWatchModeClicked}
                canExitWatchMode={canExitWatchMode}
                isFullScreen={isFullScreen}
              />
            </NavItemContainer>
          )}
        </>,
      ]}
    </CollapsableTopBar>
  )
}

export function PlaybackHomeButton({
  documentPermissions,
  showCreateButton,
  compactMode,
}: {
  documentPermissions?: DocumentPermissions | undefined
  showCreateButton: boolean
  compactMode?: boolean
}) {
  const navigate = useNavigate()
  return (
    <NavButton
      icon={documentPermissions === 'edit' || !showCreateButton ? homeIcon : create_review}
      onClick={() => navigate('/')}
      className='bg-[rgba(189,32,38,0.54)] text-white font-semibold'
      alt={'Go to the home page or create a new playback'}
      squashedChildrenArray={
        isMobile || compactMode ?
          []
        : [
            <>
              {documentPermissions === 'view' && showCreateButton && 'New'}
              <PlaybackText key='0' />
            </>,
            <PlaybackText key='0' />,
            '',
          ]
      }>
      {!(isMobile || compactMode) &&
        documentPermissions === 'view' &&
        showCreateButton &&
        'Start a new'}
      {!(isMobile || compactMode) && <PlaybackText />}
    </NavButton>
  )
}

export const PlaybackText = () => (
  <span translate={'no'}>
    Play
    <span
      style={{ color: '#DD3D4E' }}
      translate={'no'}>
      Back
    </span>
  </span>
)
/** for offline testing */
export function StoryBook() {
  const firebase = useFirebase(firebaseConfig)
  return (
    <div className='flex h-svh w-svw flex-col'>
      <EditorNavigationBar
        trackingProgress={100}
        auth={firebase.auth}
        canExitWatchMode={true}
        documentPermissions='view'
        onFullscreenClicked={() => ''}
        hasPlayersOrFilters={false}
        highlightPlayer={undefined}
        highlightsMode='Highlights'
        isFullScreen={false}
        isPlayingHighlights={'highlights'}
        onWatchModeClicked={() => ''}
        shareDialogOptions={undefined}
        videoController={undefined}
        watchMode={false}
      />
    </div>
  )
}
