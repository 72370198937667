// Import the functions you need from the SDKs you need
import { useEffectDebugger } from 'components/UseMemoDebug'
import { FirebaseApp, FirebaseError, FirebaseOptions, getApps, initializeApp } from 'firebase/app'
import {
  Auth,
  AuthProvider,
  EmailAuthProvider,
  FacebookAuthProvider,
  GithubAuthProvider,
  GoogleAuthProvider,
  OAuthCredential,
  SignInMethod as SIGN_IN_METHODS,
  TwitterAuthProvider,
  User,
  UserCredential,
  browserLocalPersistence,
  browserPopupRedirectResolver,
  getRedirectResult,
  initializeAuth,
  isSignInWithEmailLink,
  linkWithCredential,
  linkWithRedirect,
  onAuthStateChanged,
  sendSignInLinkToEmail,
  signInAnonymously,
  signInWithEmailLink,
  signInWithRedirect,
  signOut,
  updateProfile,
} from 'firebase/auth'
import {
  DataSnapshot,
  Database,
  DatabaseReference,
  QueryConstraint,
  child,
  get,
  getDatabase,
  onValue,
  push,
  query,
  ref,
  remove,
  runTransaction,
  set,
  setPriority,
  setWithPriority,
  update,
} from 'firebase/database'
import {
  Query as CollectionQuery,
  QueryConstraint as CollectionQueryConstraint,
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Firestore,
  FirestoreDataConverter,
  FirestoreError,
  QuerySnapshot,
  Unsubscribe,
  WithFieldValue,
  addDoc,
  collection,
  doc,
  getDoc,
  getDocFromCache,
  getDocFromServer,
  getDocs,
  getFirestore,
  onSnapshot,
  query as queryCollection,
} from 'firebase/firestore'
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions'
import { DependencyList, useEffect, useMemo, useState } from 'react'
import { UnreachableError } from './utils/error'

// https://firebase.google.com/docs/web/setup#available-libraries

// Initialize Firebase
type Tail<T extends unknown[]> = T extends [any, ...infer Tail] ? Tail : never

export function getApp(config: FirebaseOptions) {
  return getApps()?.firstOrNull() ?? initializeApp(config)
}

export function initFirebase(config: FirebaseOptions) {
  const app = getApp(config)
  const database = getDatabase(app)
  const firestore = getFirestore(app)
  const auth = initializeAuth(app, {
    persistence: browserLocalPersistence,
    popupRedirectResolver: browserPopupRedirectResolver,
  })
  if (process.env.NODE_ENV === 'development') {
    // connectAuthEmulator(auth, 'http://127.0.0.1:9099')
  }

  if (typeof window !== 'undefined' && window.location.hostname === 'localhost') {
    connectFunctionsEmulator(getFunctions(app), 'localhost', 5001)
  }

  return { app, database, auth, firestore }
}

export interface FirebaseComponents {
  firebaseApp: FirebaseApp
  firebaseDb: FirebaseDb
  firestoreDb: FirestoreDb
  auth: WrappedAuth
}

export function useFirebase(config: FirebaseOptions): FirebaseComponents {
  const { app, database, auth, firestore } = useMemo(() => initFirebase(config), [config])
  const wrappedAuth = useMemo(() => {
    return getWrappedAuth(auth)
  }, [auth])
  const firebaseDb = useMemo(() => {
    return getWrappedRealtimeDb(database)
  }, [database])
  const firestoreDb = useMemo(() => {
    return getWrappedFirestoreDb(firestore)
  }, [firestore])

  // Initialize Realtime Database and get a reference to the service
  return useMemo(
    () => ({
      firebaseApp: app,
      firebaseDb,
      firestoreDb,
      auth: wrappedAuth,
    }),
    [app, wrappedAuth, firebaseDb, firestoreDb],
  )
}

export function getWrappedAuth(auth: Auth): WrappedAuth {
  return {
    firebaseAuth: auth,
    signOut: async () => {
      return await signOut(auth)
    },
    onAuthStateChanged: headFunctionFactory(onAuthStateChanged, auth),
    checkRedirectResult: async () => {
      return getRedirectResult(auth)
    },
    signInAnonymously: async () => {
      return await signInAnonymously(auth)
    },
    signInWithFacebook: async () => {
      const provider = new FacebookAuthProvider()
      provider.addScope('public_profile')
      provider.addScope('email')
      await signInWithRedirect(auth, provider)
      // return (await getRedirectResult(auth))!!
    },
    linkWithFacebook: async (user: User) => {
      const provider = new FacebookAuthProvider()
      provider.addScope('public_profile')
      provider.addScope('email')
      await linkWithRedirect(user, provider)
      // return (await getRedirectResult(auth))!!
    },
    hydrateProfileIfRequired: async (user: User) => {
      const credential = await user.getIdToken()
      const isFacebook = auth.currentUser?.providerData?.any(
        (it) => it.providerId === FacebookAuthProvider.PROVIDER_ID,
      )
      if (credential && isFacebook) {
        const token = await FacebookAuthProvider.credential(credential)?.accessToken
        const graphResponse = await fetch(
          `https://graph.facebook.com/me?fields=name,email&access_token=${token}`,
        )
        const profile = JSON.parse(await graphResponse.text()) as {
          name?: string
          email?: string
        }
        if (profile.name !== user.displayName) {
          await updateProfile(user, { displayName: profile.name })
          await auth.updateCurrentUser(user)
          return user
        }
        return user
      } else {
        return user
      }
    },
    signInWithGoogle: async () => {
      const provider = new GoogleAuthProvider()
      provider.addScope('https://www.googleapis.com/auth/userinfo.profile')
      provider.addScope('https://www.googleapis.com/auth/userinfo.email')
      provider.setCustomParameters({ prompt: 'select_account' })
      await signInWithRedirect(auth, provider)
      // return (await getRedirectResult(auth))!!
    },
    linkWithGoogle: async (user: User) => {
      const provider = new GoogleAuthProvider()
      provider.addScope('https://www.googleapis.com/auth/userinfo.profile')
      provider.addScope('https://www.googleapis.com/auth/userinfo.email')
      provider.setCustomParameters({ prompt: 'select_account' })
      await linkWithRedirect(user, provider)
      // return (await getRedirectResult(auth))!!
    },
    sendSignInEmail: async (email: string, redirect: string | undefined) => {
      const redirectComponent = redirect ? `&redirect=${encodeURIComponent(redirect)}` : ''
      localStorage.setItem('signinemail', email)
      await sendSignInLinkToEmail(auth, email, {
        url: `${window.location.origin}/signin?` + redirectComponent,
        handleCodeInApp: true,
      })
    },
    signInIfEmailLink: async () => {
      const email = localStorage.getItem('signinemail')
      if (email && isSignInWithEmailLink(auth, window.location.href)) {
        return await signInWithEmailLink(auth, email)
      }
    },
    linkIfEmailLink: async (previous: User) => {
      const email = localStorage.getItem('signinemail')
      if (email && isSignInWithEmailLink(auth, window.location.href)) {
        const credential = EmailAuthProvider.credentialWithLink(email, window.location.href)

        return await linkWithCredential(previous, credential)
      }
    },
  }
}

export function getWrappedRealtimeDb(database: Database) {
  return {
    getRef: (path?: string, queryConstraint?: QueryConstraint) =>
      getFirebaseDbRef(ref(database, path), queryConstraint),
    get: (path?: string, queryConstraint?: QueryConstraint) =>
      get(queryConstraint ? query(ref(database, path), queryConstraint) : ref(database, path)),
    getVal: <T>(path?: string, queryConstraint?: QueryConstraint) =>
      get(queryConstraint ? query(ref(database, path), queryConstraint) : ref(database, path)).then(
        (it) => it.val() as T | undefined,
      ),
  }
}

export function getWrappedFirestoreDb(firestore: Firestore): FirestoreDb {
  return {
    collection: (path: string, ...pathSegments: string[]) =>
      getWrappedCollection(collection(firestore, path, ...pathSegments)),
    doc: (path: string, ...pathSegments: string[]) =>
      getWrappedDocument(doc(firestore, path, ...pathSegments)),
  }
}

export function getWrappedCollection(collection: CollectionReference): WrappedCollection {
  function query(...constraints: CollectionQueryConstraint[]): WrappedQuery
  function query(
    convert: FirestoreDataConverter<DocumentData>,
    ...constraints: CollectionQueryConstraint[]
  ): WrappedQuery
  function query(
    convert: FirestoreDataConverter<DocumentData> | CollectionQueryConstraint,
    ...constraints: CollectionQueryConstraint[]
  ): WrappedQuery {
    if (convert instanceof CollectionQueryConstraint) {
      return getWrappedQuery(queryCollection(collection, convert, ...constraints))
    } else {
      return getWrappedQuery(queryCollection(collection.withConverter(convert), ...constraints))
    }
  }

  return {
    doc: (path: string, ...pathSegments: string[]) =>
      getWrappedDocument(doc(collection, path, ...pathSegments)),
    ref: collection,
    add: async (data) => getWrappedDocument(await addDoc(collection, data)),
    query,
    toString: () => collection.path,
  }
}

export function getWrappedQuery(query: CollectionQuery): WrappedQuery {
  return {
    ref: query,
    docs: async () => getWrappedQuerySnapshot(await getDocs(query)),
    onSnapshot: (
      next: (snapshot: WrappedQuerySnapshot) => void,
      error?: (error: FirestoreError) => void,
      onCompletion?: () => void,
    ) =>
      onSnapshot(
        query,
        (snapshot) => {
          next(getWrappedQuerySnapshot(snapshot))
        },
        error,
        onCompletion,
      ),
  }
}

export function getWrappedQuerySnapshot(snapshot: QuerySnapshot): WrappedQuerySnapshot {
  return {
    ref: snapshot,
    data: () => {
      return snapshot.docs.map((it) => it.data())
    },
  }
}

export function getWrappedDocument(document: DocumentReference): WrappedDocument {
  return {
    collection: (path: string, ...pathSegments: string[]) =>
      getWrappedCollection(collection(document, path, ...pathSegments)),
    ref: document,
    onSnapshot: (
      next: (snapshot: DocumentSnapshot) => void,
      error?: (error: FirestoreError) => void,
      onCompletion?: () => void,
    ) => onSnapshot(document, next, error, onCompletion),
    get: (converter?: FirestoreDataConverter<DocumentData>) =>
      getDoc(converter ? document.withConverter(converter) : document),
    getFromCache: (converter?: FirestoreDataConverter<DocumentData>) =>
      getDocFromCache(converter ? document.withConverter(converter) : document),
    getFromServer: (converter?: FirestoreDataConverter<DocumentData>) =>
      getDocFromServer(converter ? document.withConverter(converter) : document),
    toString: () => document.toString(),
  }
}

function getFirebaseDbRef<T extends object | string | number | unknown = unknown>(
  databaseRef: DatabaseReference,
  queryConstraint?: QueryConstraint,
): FirebaseDbReference {
  const thisQuery = queryConstraint ? query(databaseRef, queryConstraint) : databaseRef
  return {
    set: headFunctionFactory(set, databaseRef),
    get: headFunctionFactory(get, thisQuery),
    checkExists: async () => get(thisQuery).then((it) => it.exists()),
    getVal: <K extends object | string | number | unknown = T>() =>
      headFunctionFactory(get, thisQuery)().then((it) => it.val() as K | undefined),
    setWithPriority: headFunctionFactory(setWithPriority, databaseRef),
    setPriority: headFunctionFactory(setPriority, databaseRef),
    push: headFunctionFactory(push, databaseRef),
    update: headFunctionFactory(update, databaseRef),
    runTransaction: headFunctionFactory(runTransaction, databaseRef),
    onValue: headFunctionFactory(onValue, thisQuery),
    remove: headFunctionFactory(remove, databaseRef),
    child: <K extends object | string | number | unknown = unknown>(
      path: string,
      queryConstraint?: QueryConstraint,
    ) => getFirebaseDbRef<K>(child(databaseRef, path), queryConstraint),
    childFromKey: <K extends keyof T & string>(path: K, queryConstraint?: QueryConstraint) =>
      getFirebaseDbRef<T[K]>(child(databaseRef, path), queryConstraint),
    toString: () => databaseRef.toString(),
  }
}

type OmitHeadField<F extends (...args: any) => any> = (
  ...props: Tail<Parameters<F>>
) => ReturnType<F>

function headFunctionFactory<F extends (...args: any) => any>(
  fn: F,
  headParam: Parameters<F>[0],
): OmitHeadField<F> {
  return (...props: Tail<Parameters<F>>) => fn(headParam, ...props)
}

export interface FirebaseDbReference<T extends object | string | number | unknown = unknown> {
  set: (value: T) => Promise<void>
  get: OmitHeadField<typeof get>
  checkExists: () => Promise<boolean>
  getVal: <K extends object | string | number | unknown = T>() => Promise<K | undefined>
  setWithPriority: (value: T, priority: string | number | null) => Promise<void>
  setPriority: (priority: string | number | null) => Promise<void>
  push: OmitHeadField<typeof push>
  update: OmitHeadField<typeof update>
  runTransaction: OmitHeadField<typeof runTransaction>
  onValue: OmitHeadField<typeof onValue>
  remove: OmitHeadField<typeof remove>
  child: <K extends object | string | number | unknown = unknown>(
    path: string,
    queryConstraint?: QueryConstraint,
  ) => FirebaseDbReference<K>
  childFromKey: <K extends keyof NonNullable<T>>(
    path: K,
    queryConstraint?: QueryConstraint,
  ) => FirebaseDbReference<K extends keyof NonNullable<T> ? NonNullable<T>[K] : unknown>
  toString: () => string
}

export function useDatabaseNullableRefLiveValue<T extends object | number | string>({
  ref,
}: {
  ref?: FirebaseDbReference<T | any> | null
}): T | null | undefined | Error {
  // eslint-disable-next-line
  const result = useMappedDatabaseRefLiveValue<T>(() => ref, [ref])
  if (result.type === 'SUCCESS') {
    return result.value ?? null
  }
  if (result === undefined) return undefined
  if (result.type === 'ERROR') return result.error
}

export function useMappedDatabaseRefLiveValue<T>(
  ref: () => FirebaseDbReference<T> | undefined | null,
  deps: DependencyList,
):
  | {
      type: 'SUCCESS'
      value: T | undefined
    }
  | { type: 'PENDING' }
  | { type: 'ERROR'; error: Error } {
  // eslint-disable-next-line
  const dbref = useMemo(ref, deps)

  const [state, setState] = useState<
    | {
        type: 'SUCCESS'
        value: T | undefined
      }
    | { type: 'PENDING' }
    | { type: 'ERROR'; error: Error }
  >({ type: 'PENDING' })

  useEffect(() => {
    setState({ type: 'PENDING' })
    if (dbref === null) {
      setState({ type: 'SUCCESS', value: undefined })
      return
    }
    if (!dbref) return
    const unsubscribe = dbref.onValue(
      (snapshot) => {
        if (!snapshot.exists()) {
          setState({ type: 'SUCCESS', value: undefined })
          return
        }
        setState({ type: 'SUCCESS', value: snapshot.val() })
      },
      (error) => {
        setState({ type: 'ERROR', error: error })
        if (process.env.NODE_ENV === 'development') console.error(dbref.toString(), error)
      },
      {},
    )
    return () => unsubscribe?.()
  }, [dbref])
  return state
}

export type MultiKeyedDatabaseResult<K extends string, T> = {
  [Key in K]?: T | null | undefined | Error
}

/** Changes to keys only will not be detected */
export function useDatabaseMultiPathLiveValue<K extends string, T extends object | number | string>(
  firebase: FirebaseDb,
  getPaths: () => { key: K; path: string | undefined }[],
  deps: DependencyList,
): MultiKeyedDatabaseResult<K, T> {
  return useDatabaseMultiRefLiveValue<K, T>(() => {
    return getPaths().map(({ key, path }) => ({
      key,
      ref: path ? firebase.getRef(path) : undefined,
    }))
  }, [firebase, ...deps])
}
/** Changes to keys only will not be detected */
export function useDatabaseMultiRefLiveValue<K extends string, T extends object | number | string>(
  getRefs: () => { ref: FirebaseDbReference | undefined; key: K }[],
  deps: DependencyList,
): MultiKeyedDatabaseResult<K, T> {
  const [state, setState] = useState<MultiKeyedDatabaseResult<K, T>>({})
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const refsDefs = useMemo(getRefs, deps)
  useEffect(
    () => {
      const unsubscribables: Unsubscribe[] = []
      setState({})
      refsDefs.forEach(({ ref, key }) => {
        if (!ref) return
        const castedKey = key as K
        const unsubscribe = ref.onValue(
          (snapshot) => {
            if (!snapshot.exists()) {
              setState((it) => ({ ...it, [castedKey]: null }))
              return
            }
            setState((it) => ({ ...it, [castedKey]: snapshot.val() }))
          },
          (error) => {
            setState((it) => ({ ...it, [castedKey]: error as Error }))

            if (process.env.NODE_ENV === 'development') console.error(ref.toString(), error)
          },
          {},
        )
        unsubscribables.push(unsubscribe)
      })

      return () => unsubscribables.forEach((unsub) => unsub?.())
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [refsDefs],
  )
  return state
}

export function useMappedFirestoreDocLiveValue<T>(
  ref: () => WrappedDocument | undefined,
  deps: DependencyList,
):
  | {
      type: 'SUCCESS'
      value: T | undefined
    }
  | { type: 'PENDING' }
  | { type: 'ERROR'; error: Error } {
  const result = useFirestoreNullableDocLiveValue<T>(ref, deps)
  if (result instanceof Error) {
    const error = result as Error
    return { type: 'ERROR', error: error }
  }
  if (result === undefined) return { type: 'PENDING' }
  return { type: 'SUCCESS', value: result ?? undefined }
}

export function useFirestoreNullableDocLiveValue<T>(
  ref: () => WrappedDocument | undefined,
  deps: DependencyList,
): T | null | undefined | Error {
  const [state, setState] = useState<T | undefined | null | Error>(undefined)

  // eslint-disable-next-line
  const dbref = useMemo(ref, deps)
  useEffect(() => {
    setState(undefined)
    if (!dbref) return
    const unsubscribe = dbref.onSnapshot(
      (snapshot) => {
        if (!snapshot.exists()) {
          setState(null)
          return
        }
        setState(snapshot.data() as T)
      },
      (error) => {
        setState(error as Error)
        if (process.env.NODE_ENV === 'development') console.error(dbref.ref.path, error)
      },
    )
    return () => unsubscribe?.()
  }, [dbref])
  return state
}

export function useMappedFirestoreQueryLiveValue<T>(
  ref: () => WrappedQuery | undefined,
  deps: DependencyList,
):
  | {
      type: 'SUCCESS'
      value: T[]
    }
  | { type: 'PENDING' }
  | { type: 'ERROR'; error: Error } {
  const result = useFirestoreNullableQueryLiveValue<T>(ref, deps)
  if (result instanceof Error) {
    const error = result as Error
    return { type: 'ERROR', error: error }
  }
  if (result === undefined) return { type: 'PENDING' }
  return { type: 'SUCCESS', value: result }
}

export function useFirestoreNullableQueryLiveValue<T>(
  ref: () => WrappedQuery | undefined,
  deps: DependencyList,
): T[] | undefined | Error {
  const [state, setState] = useState<T[] | undefined | Error>(undefined)

  // eslint-disable-next-line
  const dbref = useMemo(ref, deps)
  useEffect(() => {
    setState([])
    if (!dbref) return
    const unsubscribe = dbref.onSnapshot(
      (snapshot) => {
        setState(snapshot.data() as T[])
      },
      (error) => {
        setState(error as FirestoreError)
        if (process.env.NODE_ENV === 'development') console.error(dbref.toString(), error)
      },
    )
    return () => unsubscribe?.()
  }, [dbref])
  return state
}

export function useDatabasePathLiveValue<T>(
  firebase: FirebaseDb,
  path: string | undefined,
): T | undefined {
  const ref = useMemo(() => {
    return path ? firebase.getRef(path) : undefined
  }, [firebase, path])
  return useDatabaseRefLiveValue<T>({ ref })
}
export function useNullableDatabasePathLiveValue<
  T extends string | object | number | undefined | null,
>(firebase: FirebaseDb, path: string | undefined): T | undefined | null | Error {
  const ref = useMemo(() => {
    return path ? firebase.getRef(path) : undefined
  }, [firebase, path])
  return useDatabaseNullableRefLiveValue<NonNullable<T>>({ ref })
}
export function useMappedDatabasePathLiveValue<T extends object | number | string>(
  firebase: FirebaseDb,
  path: string | undefined,
) {
  const ref = useMemo(() => {
    return path ? firebase.getRef(path) : undefined
  }, [firebase, path])
  const result = useMappedDatabaseRefLiveValue<T>(() => ref, [ref])
  return result
}

export function useDatabaseRef<T>(
  firebase: FirebaseDb,
  path: string | undefined,
): FirebaseDbReference<T> | undefined {
  return useMemo(() => {
    return path ? firebase.getRef<T>(path) : undefined
  }, [firebase, path])
}

export function useDatabaseRefLiveValue<T>({
  ref,
}: {
  ref?: FirebaseDbReference<T> | null
}): T | undefined {
  const result = useDatabaseNullableRefLiveValue<NonNullable<T>>({ ref }) ?? undefined
  if (result instanceof Error) {
    return undefined
  } else return result
}

export function useDatabaseRefLiveValueMemo<T>(
  getRef: () => FirebaseDbReference<T> | undefined | false | null,
  deps: DependencyList,
): T | undefined {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const ref = useMemo(getRef, deps)
  const result =
    useDatabaseNullableRefLiveValue<NonNullable<T>>({ ref: ref === false ? undefined : ref }) ??
    undefined
  if (result instanceof Error) {
    return undefined
  } else return result
}

export interface FirebaseDb {
  getRef: <T = unknown>(path?: string, queryConstraint?: QueryConstraint) => FirebaseDbReference<T>
  get: (path?: string, queryConstraint?: QueryConstraint) => Promise<DataSnapshot>
  getVal: <T = string>(path?: string, queryConstraint?: QueryConstraint) => Promise<T | undefined>
}

export interface FirestoreDb {
  collection: (path: string, ...pathSegments: string[]) => WrappedCollection
  doc: (path: string, ...pathSegments: string[]) => WrappedDocument
}

export interface WrappedCollection {
  ref: CollectionReference
  doc: (path: string, ...pathSegments: string[]) => WrappedDocument
  add: (data: WithFieldValue<DocumentData>) => Promise<WrappedDocument>
  query: ((
    converter: FirestoreDataConverter<DocumentData>,
    ...constraints: CollectionQueryConstraint[]
  ) => WrappedQuery) &
    ((...constraints: CollectionQueryConstraint[]) => WrappedQuery)
  toString: () => string
}

export interface WrappedQuery {
  ref: CollectionQuery
  docs: () => Promise<WrappedQuerySnapshot>
  onSnapshot: (
    onNext: (snapshot: WrappedQuerySnapshot) => void,
    onError?: ((error: FirestoreError) => void) | undefined,
    onCompletion?: (() => void) | undefined,
  ) => Unsubscribe
}

export interface WrappedQuerySnapshot {
  ref: QuerySnapshot
  data: () => DocumentData[]
}

export interface WrappedDocument<T = DocumentData> {
  ref: DocumentReference<T>
  collection: (path: string, ...pathSegments: string[]) => WrappedCollection
  onSnapshot: (
    next: (snapshot: DocumentSnapshot<T>) => void,
    error?: (error: FirestoreError) => void,
    complete?: () => void,
  ) => Unsubscribe
  get: (convert?: FirestoreDataConverter<DocumentData>) => Promise<DocumentSnapshot>
  getFromCache: (convert?: FirestoreDataConverter<DocumentData>) => Promise<DocumentSnapshot>
  getFromServer: (convert?: FirestoreDataConverter<DocumentData>) => Promise<DocumentSnapshot>
  toString: () => string
}

export interface WrappedAuth {
  firebaseAuth: Auth
  onAuthStateChanged: OmitHeadField<typeof onAuthStateChanged>
  signOut: () => Promise<void>
  checkRedirectResult: () => Promise<UserCredential | null>
  signInAnonymously: () => Promise<UserCredential>
  signInWithFacebook: () => Promise<void>
  linkWithFacebook: (user: User) => Promise<void>
  signInWithGoogle: () => Promise<void>
  linkWithGoogle: (user: User) => Promise<void>
  sendSignInEmail: (email: string, redirect: string | undefined) => Promise<void>
  signInIfEmailLink: () => Promise<UserCredential | undefined>
  linkIfEmailLink: (user: User) => Promise<UserCredential | undefined>
  hydrateProfileIfRequired: (user: User, suggestedName?: string) => Promise<User>
}

//
// export type SignInMethod =
//   | 'emailLink'
//   | 'password'
//   | 'facebook.com'
//   | 'github.com'
//   | 'google.com'
//   | 'phone'
//   | 'twitter.com'

export function getPreferredProvider(signInMethods: string[]):
  | {
      credentialFromError: (error: FirebaseError) => OAuthCredential | null
      provider: AuthProvider
      method: string
    }
  | undefined {
  const preferredMethod = signInMethods.orderByDesc((it) => getProviderPriority(it)).firstOrNull()
  if (!preferredMethod) return undefined
  const preferredProvider = getProvider(preferredMethod)
  return preferredProvider && { ...preferredProvider, method: preferredMethod }
}

/** Higher is preferred **/
export function getProviderPriority(signInMethod: string) {
  const [key, method] =
    Object.entries(SIGN_IN_METHODS).find(([key, value]) => value === signInMethod) ?? []
  const parsedKey = key as keyof typeof SIGN_IN_METHODS | undefined
  switch (parsedKey) {
    case 'EMAIL_LINK':
      return -1
    case 'EMAIL_PASSWORD':
      return -1
    case 'FACEBOOK':
      return 1
    case 'GITHUB':
      return 0
    case 'GOOGLE':
      return 2
    case 'PHONE':
      return -1
    case 'TWITTER':
      return 0
    case undefined:
      return -1
    default:
      throw UnreachableError(parsedKey)
  }
}

export function getProvider(
  signInMethod: string,
  email_hint?: string,
):
  | {
      provider: AuthProvider
      credentialFromError: (error: FirebaseError) => OAuthCredential | null
    }
  | undefined {
  const [key, method] =
    Object.entries(SIGN_IN_METHODS).find(([key, value]) => value === signInMethod) ?? []
  const parsedKey = key as keyof typeof SIGN_IN_METHODS | undefined
  switch (parsedKey) {
    case 'EMAIL_LINK':
      return undefined
    case 'EMAIL_PASSWORD':
      return undefined
    case 'FACEBOOK':
      return {
        provider: new FacebookAuthProvider(),
        credentialFromError: (error) => FacebookAuthProvider.credentialFromError(error),
      }
    case 'GITHUB':
      return {
        provider: new GithubAuthProvider(),
        credentialFromError: (error) => GithubAuthProvider.credentialFromError(error),
      }
    case 'GOOGLE':
      return {
        provider: new GoogleAuthProvider(),
        credentialFromError: (error) => GoogleAuthProvider.credentialFromError(error),
      }
    case 'PHONE':
      return undefined
    case 'TWITTER':
      return {
        provider: new TwitterAuthProvider(),
        credentialFromError: (error) => TwitterAuthProvider.credentialFromError(error),
      }
    case undefined:
      return undefined
    default:
      throw UnreachableError(parsedKey)
  }
}
