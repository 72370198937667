// import all translation resource groups
import i18next from 'i18next'
import detector from 'i18next-browser-languagedetector'
import backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'
import { englishLocales } from './en/en'
import { vietnameseLocales } from './vn/vn'

const languageTranslation = {
  en: englishLocales,
  vn: vietnameseLocales,
}

i18next
  .use(detector)
  .use(backend)
  .use(initReactI18next)
  .init({
    resources: languageTranslation,
    fallbackLng: 'en', // fallback language
    interpolation: {
      escapeValue: false,
    },
  })

export default i18next
