import { VideoSourceData } from 'data/ReviewSelectionInit'
import { DownloadClips, FunctionTrigger } from 'data/common'
import { User } from 'firebase/auth'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { useProFeaturesStore } from 'hooks/UseProFeaturesStore'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { firebaseConfig } from 'services/FirebaseConfig'
import { getChildCategories } from 'templates/TemplateConfig'
import { ActivityTemplates } from 'templates/TemplateLibrary'
import { HighlightCreator } from 'ui/HighlightCreator'
import { ReviewActivityType } from 'ui/ReviewMetaStore'
import crownIcon from '../icons/crown_icon.svg'
import { HighlightProps } from './ShareReviewDialog'
import {
  FirebaseComponents,
  FirebaseDb,
  FirestoreDb,
  getApp,
  useDatabasePathLiveValue,
} from './common/Firebase'
import { RoundButton } from './common/RoundButton'

export const HighlightsDownloadButton: React.FC<{
  reviewId: string
  activityType: ReviewActivityType
  firebaseDb: FirebaseDb
  videoSourceData: VideoSourceData
  highlightProps: HighlightProps | undefined
  highlightCreator: HighlightCreator | undefined
  fireStore: FirestoreDb
  user: User | undefined
}> = ({ videoSourceData, highlightCreator, highlightProps, ...props }) => {
  const [databasePath, setFunctionTriggerDbPath] = useState<string>()
  const [renderStarting, setRenderStarting] = useState(false)
  const [isDownloading, setDownloading] = useState(false)

  const functionTrigger = useDatabasePathLiveValue<
    Extract<FunctionTrigger, { type: 'DownloadClips' }>
  >(props.firebaseDb, databasePath)

  const {
    featureFlags: { exportVideoEnabled },
  } = useProFeaturesStore({
    user: props.user,
    reviewId: props.reviewId,
  })

  const clips = useMemo(() => {
    return highlightProps ?
        highlightCreator?.createHighlightClips({
          ...highlightProps,
          eventFilters: highlightProps?.eventFilters?.flatMap((filter) =>
            getChildCategories(ActivityTemplates[props.activityType], filter),
          ),
        })
      : undefined
  }, [highlightCreator, highlightProps, props.activityType])

  const inProgress =
    (functionTrigger && !functionTrigger.result && !!functionTrigger.totalSteps) ||
    renderStarting ||
    isDownloading

  const isCompositing = !!(
    functionTrigger &&
    !functionTrigger.result &&
    functionTrigger.totalSteps &&
    functionTrigger.totalSteps === functionTrigger?.progress
  )

  const handleProcessClick = useCallback(async () => {
    if (!exportVideoEnabled) {
      toast('You need to be a PRO user to export a highlights video', {
        type: 'info',
        position: 'bottom-center',
      })
      return
    }

    if (inProgress) {
      console.log('Export video already in progress')
      return
    }

    if (!clips?.length) {
      console.error('No highlights found')
      return
    }

    const youtubeUrl =
      videoSourceData.source === 'Youtube_url' ? videoSourceData.id
      : videoSourceData.source === 'Youtube' ?
        `https://www.youtube.com/watch?v=${videoSourceData.id}`
      : null

    if (!youtubeUrl) {
      console.error('Unsupported Video Source')
      return
    }
    try {
      setRenderStarting(true)
      const request: DownloadClips.DownloadClipsRequest = {
        sources: [
          {
            url: youtubeUrl,
            clips: clips.map((clip) => ({ startSeconds: clip.start, endSeconds: clip.end })),
          },
        ],
      }
      const functions = getFunctions(getApp(firebaseConfig))
      const response = await httpsCallable<
        DownloadClips.DownloadClipsRequest,
        DownloadClips.DownloadClipsAsyncResponse
      >(functions, 'downloadClipsAsync', { timeout: 540000 })(request)

      setFunctionTriggerDbPath(response.data.databaseRef)
      console.log('function trigger ref', response.data.databaseRef)
    } catch (error) {
      console.error('Error processing videos:', error)
      setRenderStarting(false)
    }
  }, [clips, videoSourceData, exportVideoEnabled, inProgress])

  useEffect(() => {
    if (functionTrigger?.totalSteps) {
      setRenderStarting(false)
    }
  }, [functionTrigger?.totalSteps])

  const successResult =
    functionTrigger?.result?.type === 'SUCCESS' ? functionTrigger.result : undefined

  useEffect(() => {
    if (successResult) {
      setDownloading(true)
      triggerXHRDownload(successResult.payload.downloadURL).finally(() => {
        setFunctionTriggerDbPath(undefined)
        setRenderStarting(false)
        setDownloading(false)
      })
    }
  }, [successResult])

  const expectedDuration = useMemo(() => {
    return clips?.map((clip) => clip.end - clip.start).sum()
  }, [clips])

  return (
    <RoundButton
      alt='Export Highlights Video'
      icon={crownIcon}
      onClick={handleProcessClick}
      aria-disabled={inProgress || isCompositing || !clips?.length}>
      {!isCompositing && inProgress && !!functionTrigger?.totalSteps && expectedDuration && (
        <LoadingProgressContainer
          expectedLoadingTimeSeconds={Math.max(expectedDuration, 120)}
          realProgress={(functionTrigger.progress ?? 0) / functionTrigger.totalSteps}>
          {(progress) => `${Math.floor(progress)}% `}
        </LoadingProgressContainer>
      )}
      {isCompositing && expectedDuration && (
        <LoadingProgressContainer
          expectedLoadingTimeSeconds={expectedDuration / 20}
          realProgress={0}>
          {(progress) => `${Math.floor(progress)}% `}
        </LoadingProgressContainer>
      )}
      {isDownloading ?
        'Downloading...'
      : isCompositing ?
        'Combining clips...'
      : inProgress ?
        'Generating clips...'
      : clips?.length ?
        'Export Highlights Video'
      : 'No events found'}
    </RoundButton>
  )
}

async function triggerXHRDownload(url: string, filename?: string) {
  // const response = await fetch(url, {
  //   headers: new Headers({
  //     Origin: location.origin,
  //   }),
  //   mode: 'cors',
  // })
  // const blob = await response.blob()

  // // Create an invisible anchor element
  // const link = document.createElement('a')
  // link.href = url
  // link.download = 'video.mp4' // The name for the downloaded file

  // // Append the anchor to the body
  // document.body.appendChild(link)

  // // Programmatically click the anchor to trigger the download
  // link.click()

  // // Remove the anchor from the document
  // document.body.removeChild(link)
  // window.URL.revokeObjectURL(url)
  return await new Promise<void>((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    xhr.responseType = 'blob'
    xhr.onload = (event) => {
      if (xhr.status === 200) {
        // Handle the response
        const blob = xhr.response
        const contentDisposition = xhr.getResponseHeader('Content-Disposition')
        if (!filename && contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
          const matches = filenameRegex.exec(contentDisposition)
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '')
          }
        }

        // Create a Blob URL
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.download = filename || 'downloadedFile'

        // Append to the document
        document.body.appendChild(link)

        // Trigger the download
        link.click()

        // Cleanup
        document.body.removeChild(link)
        window.URL.revokeObjectURL(url)
      } else {
        console.error('Failed to download file:', xhr.statusText)
      }
      resolve()
    }
    xhr.open('GET', url)
    xhr.send()
  })
  // window.location.href = url;
}

interface LoadingProgressProps {
  realProgress?: number // Real progress value (0 to 100)
  expectedLoadingTimeSeconds: number

  children: (progress: number) => React.ReactNode
}

const LoadingProgressContainer: React.FC<LoadingProgressProps> = ({
  realProgress,
  expectedLoadingTimeSeconds,
  children,
}) => {
  const [displayProgress, setDisplayProgress] = useState(0)
  const startTime = useMemo(() => Date.now(), [])

  useEffect(() => {
    let animationFrameId: number

    const updateProgress = () => {
      const elapsedTime = Date.now() - startTime
      const progressFraction = elapsedTime / (expectedLoadingTimeSeconds * 1000)

      // Calculate fake progress with an exponential decay function
      const fakeValue = 100 * (1 - Math.exp(-3 * progressFraction))

      // Incorporate real progress if it's faster
      const combinedProgress = Math.max(fakeValue, realProgress ?? 0)

      setDisplayProgress(combinedProgress)

      if (combinedProgress < 100) {
        animationFrameId = requestAnimationFrame(updateProgress)
      }
    }

    animationFrameId = requestAnimationFrame(updateProgress)

    return () => {
      cancelAnimationFrame(animationFrameId)
    }
  }, [expectedLoadingTimeSeconds, realProgress, startTime])

  return <>{children(displayProgress)}</>
}

export default LoadingProgressContainer
