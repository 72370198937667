import {
  StorageError,
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from 'firebase/storage'
import React, { ChangeEvent, useState } from 'react'
import { useCallback, useRef } from 'react'
import { ThreeDots } from 'react-loading-icons'
import { toast } from 'react-toastify'
import { Colors } from '../Colors'
import editIcon from '../icons/edit_button_black.png'
import { randomUUID } from './common/utils/randomUUID'
import { cn } from './common/utils/tailwindUtils'

const TARGET_WIDTH = 720
const TARGET_HEIGHT = 720

const resizeImage = (
  file: File,
  targetWidth: number = TARGET_WIDTH,
  targetHeight: number = TARGET_HEIGHT,
): Promise<Blob> => {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.onload = (e) => {
      const img = new Image()
      img.onload = () => {
        const canvas = document.createElement('canvas')
        let width = img.width
        let height = img.height

        if (width > height) {
          if (width > targetWidth) {
            height *= targetWidth / width
            width = targetWidth
          }
        } else {
          if (height > targetHeight) {
            width *= targetHeight / height
            height = targetHeight
          }
        }

        canvas.width = width
        canvas.height = height
        const ctx = canvas.getContext('2d')
        ctx?.drawImage(img, 0, 0, width, height)

        canvas.toBlob((blob) => {
          resolve(blob as Blob)
        }, file.type)
      }
      img.src = e.target?.result as string
    }
    reader.readAsDataURL(file)
  })
}

/**
 * Uploads an avatar image to the storage.
 *
 * @param file - The file to be uploaded.
 * @throws {Error} If there is an error resizing or processing the image.
 * @throws {StorageError} If there is an error uploading the image to the storage.
 */
export const uploadAvatar = async ({
  file,
  targetHeight,
  targetWidth,
}: {
  file: File
  targetWidth?: number
  targetHeight?: number
}) => {
  const storage = getStorage()
  return new Promise<string>(async (resolve, onError) => {
    try {
      const resizedImage = await resizeImage(file, targetWidth, targetHeight)
      const storageRef = ref(storage, `images/${randomUUID()}_${file.name}`)
      const uploadTask = uploadBytesResumable(storageRef, resizedImage)

      uploadTask.on(
        'state_changed',
        () => {
          //
        },
        onError,
        () => getDownloadURL(uploadTask.snapshot.ref).then(resolve).catch(onError),
      )
    } catch (error) {
      console.error('Error resizing image:', error)

      onError(error)
    }
  })
}

export const AvatarUploader = ({
  onChange,
  className,
  style,
  onUploadingState,
  children,
  filePath,
}: {
  filePath?: string
  onChange: (url: string) => void
  className?: string
  style?: React.CSSProperties
  onUploadingState?: (uploading: boolean) => void
  children?: React.ReactNode
}) => {
  const avatarUploadRef = useRef<HTMLInputElement>(null)

  const handleUploadImage = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.files) {
        const originalImage = event.target.files[0]

        try {
          onUploadingState?.(true)
          const url = await uploadAvatar({ file: originalImage })
          onChange(url)
        } catch (e) {
          if (e instanceof StorageError) {
            toast('Upload image failed', {
              type: 'error',
              position: 'bottom-right',
              autoClose: 2000,
            })
          } else {
            toast('Error processing image', {
              type: 'error',
              position: 'bottom-right',
              autoClose: 2000,
            })
          }
          console.error('Error uploading image:', e)
        } finally {
          onUploadingState?.(false)
        }
      }
    },
    [onChange, onUploadingState],
  )
  return (
    <>
      <input
        type='file'
        accept='image/*'
        hidden={true}
        ref={avatarUploadRef}
        onChange={handleUploadImage}
      />
      <div
        className={cn('cursor-pointer border-none', className)}
        style={style}
        onClick={() => avatarUploadRef.current?.click()}>
        {children}
      </div>
    </>
  )
}

// Todo: add 2 options when change group avatar
// - Select background color
// - Select avatar image
export const AvatarDisplay = ({
  backgroundColor: color,
  src,
  onChange,
  className,
  blur,
}: {
  backgroundColor?: string
  src?: string
  onChange?: (url: string) => void
  className?: string
  blur?: boolean
}) => {
  const [backgroundColor, setBackgroundColor] = useState(color)
  const [loading, setLoading] = useState(false)

  return (
    <>
      <div
        className={cn(
          'relative flex size-[100px] flex-row items-center rounded-full border-2 border-white',
          className,
        )}
        style={{ backgroundColor }}>
        <div className='absolute z-10 flex h-full w-full items-center justify-center'>
          Coming soon
        </div>
        {loading ?
          <div className='flex h-full w-full items-center justify-center'>
            <ThreeDots />
          </div>
        : <>
            {src && (
              <img
                draggable={false}
                src={src}
                className={cn(
                  'h-full w-full bg-inherit object-cover object-center',
                  blur && 'blur-lg',
                  className,
                )}
                alt='avatar'
              />
            )}
          </>
        }
        {onChange && (
          <AvatarUploader
            onUploadingState={(uploading) => setLoading(uploading)}
            onChange={onChange}
            className='absolute -bottom-[10px] -right-[10px] flex size-10 cursor-pointer items-center justify-center
              rounded-full bg-white hover:opacity-80'>
            <img
              src={editIcon}
              alt='upload image'
              draggable={false}
              className='h-5 w-5'
            />
          </AvatarUploader>
        )}
      </div>
    </>
  )
}
