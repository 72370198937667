export function extractCharsAndNumbers(inputString: string): [string, string] {
  const words = inputString.split(/\s+/)
  const alphanumericWords: string[] = []
  const numericWords: string[] = []

  for (const word of words) {
    if (/\d/.test(word)) {
      numericWords.push(word)
    } else {
      alphanumericWords.push(word)
    }
  }

  return [alphanumericWords.join(' '), numericWords.join(' ')]
}

export function normaliseName(playerName: string): string {
  if (!playerName) return ''
  if (/^\d+$|^\d+s\d+$/.test(playerName)) return playerName

  const [alphabetic, numeric] = extractCharsAndNumbers(playerName.trim())

  const formattedAlphabetic = alphabetic
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')

  return `${formattedAlphabetic} ${numeric}`.trim()
}
