import getVideoId from 'get-video-id'
import { bool } from 'prop-types'
import { useMemo, useState } from 'react'
import ReactTagManager from 'react-ga4'
import { toast } from 'react-toastify'
import { UnreachableError } from '../components/common/utils/error'
import { FirebaseVideoSource, VideoSource } from '../data/common'
import { firebaseConfig } from '../services/FirebaseConfig'

export function getYoutubeThumbnailLink(videoId: string) {
  return `https://img.youtube.com/vi/${videoId}/default.jpg`
}

export function getYoutubeHqThumbnailLink(videoId: string) {
  return `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`
}

export function getPlaceholderThumbnail() {
  return `https://playbackreview.com/preview.jpg`
}

export async function getFacebookThumbnail(url: string) {
  return getPlaceholderThumbnail()
  // const id = await getFacebookVideoId(url)
  // if (!id) return undefined
  // return `https://graph.facebook.com/${id}/picture`
}

// async function getFacebookVideoId(url: string): Promise<string | undefined> {
//   const response = await fetch(url, {
//     method: 'HEAD',
//     redirect: 'follow'
//   });
//   const finalUrl = response.url;
//   const regex = /^(?:https?:\/\/)?(?:www\.)?(?:m\.)?(?:watch\.)?facebook\.com\/.*\/videos\/(\d+)/;
//   const match = finalUrl.match(regex);
//   if (match) {
//     return match[1];
//   }
//   return undefined;
// }
/**
 * `getDynamicLink` is an asynchronous function that generates a short dynamic link using Firebase's Dynamic Links service.
 * The function takes an object as an argument with five properties and returns a Promise that resolves to a string, which is the short dynamic link.
 *
 * @param {Object} params - The parameters for generating the dynamic link.
 * @param {string} params.canonical - The full canonical URL of the page. This URL is used as the basis for the dynamic link that will be generated.
 * @param {string} params.pageTitle - The title of the page. This title is used in the dynamic link to provide context about the page the link leads to. The length and format of this parameter may be somewhat limited due to restrictions in the dynamic link service.
 * @param {string} [params.reviewTitle] - The title of a review. If provided, this title is appended to the `pageTitle` in the dynamic link. If not provided, the function will still work correctly, but the dynamic link will have less context for the user before they click on it.
 * @param {string} [params.description] - A description of the page. If not provided, a default description "Elevate your game with Replay Analysis" is used. This parameter is optional and most of the time is not shown on social media apps.
 * @param {string} params.thumbnail - The URL to a thumbnail image. This image URL is used in the dynamic link.
 *
 * @returns {Promise<string>} A Promise that resolves to the short dynamic link.
 *
 * @example
 * getDynamicLink({
 *   canonical: 'https://example.com',
 *   pageTitle: 'Example Page',
 *   reviewTitle: 'Example Review',
 *   description: 'This is an example page.',
 *   thumbnail: 'https://example.com/thumbnail.jpg',
 * })
 */
export async function getDynamicLink(props: {
  canonical: string
  pageTitle: string
  reviewTitle?: string
  description?: string
  thumbnail: string
}): Promise<string | undefined> {
  const shareProps = toShareProps(props)
  const playbackdynamicLink = `https://playbackreview.com/link/?link=${encodeURIComponent(shareProps.url)}&st=${encodeURIComponent(shareProps.title)}&sd=${encodeURIComponent(shareProps.description)}&si=${encodeURIComponent(shareProps.thumbnail)}`
  try {
    const response = await fetch(
      `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${firebaseConfig.apiKey}`,
      {
        method: 'POST',
        body: JSON.stringify({
          longDynamicLink: playbackdynamicLink,
          suffix: {
            option: 'SHORT',
          },
        }),
        headers: { 'Content-Type': 'application/json' },
      },
    )

    if (!response.ok) return undefined

    const result = (await response.json()) as {
      shortLink: string
      previewLink: string
    }
    return result.shortLink
  } catch (e) {
    console.error(e)
    return undefined
  }
}

function toShareProps({
  canonical,
  pageTitle,
  reviewTitle,
  description,
  thumbnail,
}: CopyLinkOpts): { title: string; description: string; thumbnail: string; url: string } {
  return {
    title: `PlayBack${pageTitle ? ` - ${pageTitle}` : ''}${reviewTitle ? ` - ${reviewTitle}` : ''}`,
    description: description || 'Elevate your game with Replay Analysis',
    thumbnail: thumbnail || getPlaceholderThumbnail(),
    url: canonical,
  }
}

export const copyToTextClipboard = async (clipboard: Clipboard, text: string) => {
  await clipboard.writeText(text)
}

export const copyToImageAndTextClipboard = async (
  clipboard: Clipboard,
  text: string,
  blob: Blob,
) => {
  await clipboard.write([
    new ClipboardItem({
      [blob.type]: blob,
      // ['text/plain']: new Blob([text], { type: 'text/plain' }),
    }),
    // new ClipboardItem({
    //   ['text/plain']: new Blob([text], { type: 'text/plain' }),
    // }),
  ])
  // await clipboard.writeText(text)
}

export type CopyLinkOpts = {
  // Dynamic link metadata. The full canonical URL of the page. This URL is used as the basis for the dynamic link that will be generated.
  canonical: string
  // Dynamic link metadata. The title of the page. This title is used in the dynamic link to provide context about the page the link leads to. The length and format of this parameter may be somewhat limited due to restrictions in the dynamic link service.
  pageTitle: string
  // Dynamic link metadata. The title of a review. If provided, this title is appended to the `pageTitle` in the dynamic link. If not provided, the function will still work correctly, but the dynamic link will have less context for the user before they click on it.
  reviewTitle?: string
  // Dynamic link metadata. A description of the page. If not provided, a default description "Elevate your game with Replay Analysis" is used. This parameter is optional and most of the time is not shown on social media apps.
  description?: string
  // Dynamic link metadata. The URL to a thumbnail image. This image URL is used in the dynamic link. If not provided, the function will still work correctly, but the dynamic link will not have a thumbnail.
  thumbnail: string | undefined
  // The message that will be copied or shared directly to the recipient. No limit in length. Defaults to "Check out this PlayBack!"
  socialMediaText?: string
}
export type ClipboardCopyFn = (opts: CopyLinkOpts) => Promise<void>

export async function copyToClipboard(props: CopyLinkOpts & { blob?: Blob }) {
  const { canonical, description, pageTitle, reviewTitle, socialMediaText } = props

  const imageUrl = props.thumbnail || getPlaceholderThumbnail()

  const link =
    (await getDynamicLink({
      canonical,
      reviewTitle,
      pageTitle,
      description,
      thumbnail: imageUrl,
    })) ?? canonical

  const files =
    props.blob ?
      [
        new File([props.blob], `capture.${props.blob.type.split('/').lastOrNull()}`, {
          type: props.blob.type,
        }),
      ]
    : undefined

  const shareProps = toShareProps({ ...props, thumbnail: imageUrl })

  const shareData = {
    url: link,
    text: socialMediaText,
    files,
    title: shareProps.title,
  } satisfies ShareData

  try {
    if (navigator.canShare && navigator.canShare(shareData)) {
      try {
        await navigator.share(shareData)
        ReactTagManager.event('shared_externally', { category: 'activation' })

        return
      } catch (e) {
        // share cancelled
      }
    } else if (navigator.canShare && navigator.canShare({ text: socialMediaText, url: link })) {
      try {
        await navigator.share({ text: socialMediaText, url: link })
        ReactTagManager.event('shared_externally', { category: 'activation' })

        return
      } catch (e) {
        // share cancelled
      }
    }
  } catch (e) {
    console.log(e)
  }

  if (props.blob) {
    await copyToImageAndTextClipboard(
      navigator.clipboard,
      `${socialMediaText ? `${socialMediaText}\n` : ''}${link}`,
      props.blob,
    )
  } else {
    await copyToTextClipboard(
      navigator.clipboard,
      `${socialMediaText ? `${socialMediaText}\n` : ''}${link}`,
    )
  }
  ReactTagManager.event('link_copied', { category: 'share_button' })

  toast('Copied to clipboard', {
    theme: 'dark',
    position: 'bottom-right',
    autoClose: 1000,
  })
}

export function useCopyLinkToClipBoard(): {
  isCopying: boolean
  copy: ClipboardCopyFn | undefined
} {
  const [isCopying, setCopying] = useState<boolean>(false)
  const copyLinkToClipboardFn = useMemo<undefined | ((opts: CopyLinkOpts) => Promise<void>)>(() => {
    return (
      navigator.clipboard &&
      (async (opts) => {
        setCopying(true)
        await copyToClipboard(opts)
        setCopying(false)
      })
    )
  }, [])
  return useMemo(
    () => ({ copy: copyLinkToClipboardFn, isCopying }),
    [copyLinkToClipboardFn, isCopying],
  )
}

export async function getThumbnail(
  videoId: string | null,
  baseSource: FirebaseVideoSource | null,
): Promise<string> {
  if (!videoId) return getPlaceholderThumbnail()
  const source: VideoSource = baseSource || 'Youtube'
  switch (source) {
    case 'Youtube':
      return getYoutubeThumbnailLink(videoId)
    case 'Facebook_url':
      return (await getFacebookThumbnail(videoId)) || getPlaceholderThumbnail()
    case 'Youtube_url':
      const youtubeID = getVideoId(videoId)
      if (baseSource === 'Youtube_url' && youtubeID.id && youtubeID.service === 'youtube')
        return getYoutubeHqThumbnailLink(youtubeID.id)
      return getPlaceholderThumbnail()
    default:
      throw UnreachableError(source)
  }
}
