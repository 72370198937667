import { ChevronDownIcon, ChevronLeftIcon, ChevronUpIcon } from '@chakra-ui/icons'
import React, {
  CSSProperties,
  PropsWithChildren,
  ReactNode,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from 'react'
import UpArrow from '../icons/Arrow_up.png'
import HamburgerIcon from '../icons/hamburger_menu.png'
import { useCompositeRefObject } from './UseCompositeRefObject'
import { SquashingBox } from './common/SquashingBox'
import { useElementBoundingRectSize } from './common/utils/reactUtils'
import { cn } from './common/utils/tailwindUtils'

export function CollapsableTopBar({
  children,
  rightFixedChildren,
  leftMainButton,
}: {
  children?: () => ReactNode[]
  leftMainButton?: () => ReactNode
  /** Requires an array so that wrapping can be done from the first element keeping the last element */
  rightFixedChildren?: () => ReactNode[]
}) {
  const [showPopover, setShowPopover] = useState(false)
  const heightClassName = 'h-[48px]'
  const topClassName = 'top-[48px]'
  const maxHeightClassName = 'max-h-[48px]'
  const navElementClassName = '[&_.navelement]:h-[48px]'

  const [wrappingLeftChildrenRef, wrappingLeftSize] = useElementBoundingRectSize()
  const [wrappingRightChildrenRef, wrappedRightSize] = useElementBoundingRectSize()
  const [shouldShowHamburger, setshouldShowHamburger] = useState(false)

  const leftChildrenShouldWrap =
    wrappingLeftSize.scrollWidth > wrappingLeftSize.width ||
    wrappingLeftSize.scrollHeight > wrappingLeftSize.height

  const rightChildrenShouldWrap =
    wrappedRightSize.scrollWidth > wrappedRightSize.width + 100 ||
    wrappedRightSize.scrollHeight > wrappedRightSize.height

  useEffect(() => {
    if (leftChildrenShouldWrap || rightChildrenShouldWrap) {
      setshouldShowHamburger(true)
    }
  }, [leftChildrenShouldWrap, rightChildrenShouldWrap])

  return (
    <div
      className={cn(
        `pointer-events-none flex w-svw shrink-0 grow-0 basis-auto flex-row justify-between shadow-md
        shadow-[rgb(0,0,0,0.5)]`,
        navElementClassName,
        heightClassName,
      )}>
      <div
        className={cn(
          `flex min-w-0 max-w-fit flex-shrink basis-[fit-content] flex-row flex-nowrap items-center
          justify-start`,
          maxHeightClassName,
        )}>
        {shouldShowHamburger && (
          <NavButton
            className={cn('min-w-0 bg-transparent text-white')}
            onClick={() => setShowPopover((it) => !it)}
            icon={showPopover ? UpArrow : HamburgerIcon}
          />
        )}
        {leftMainButton?.()}
      </div>
      <div
        ref={wrappingLeftChildrenRef}
        className={cn(
          'flex min-w-0 max-w-fit flex-1 flex-row items-center justify-start [&>*]:min-w-0 [&>*]:max-w-full',
          leftChildrenShouldWrap ? 'flex-wrap overflow-clip' : 'flex-nowrap',
          maxHeightClassName,
        )}>
        {children?.()}
      </div>

      <div
        ref={wrappingRightChildrenRef}
        className={cn(
          `flex min-w-0 max-w-fit flex-1 basis-0 flex-row-reverse flex-nowrap items-center justify-start
          [&>*]:min-w-0 [&>*]:max-w-full`,
          rightChildrenShouldWrap ? 'overflow-clip' : '',
        )}>
        {React.Children.toArray(rightFixedChildren?.() ?? []).toReversed()}
      </div>

      {showPopover && (
        <div
          className={cn(
            'fixed left-0 z-50 shadow-2xl shadow-slate-600',
            !shouldShowHamburger && 'hidden',
            topClassName,
            showPopover ? '' : 'hidden',
          )}>
          <VerticalMenu
            heightClassName={heightClassName}
            onClose={() => setShowPopover(false)}>
            {[...(children?.() ?? []), ...(rightFixedChildren?.() ?? [])]}
          </VerticalMenu>
        </div>
      )}
    </div>
  )
}
export const NavItemContainer = forwardRef<
  HTMLDivElement,
  {
    onClick?: () => void
    className?: string
    children?: ReactNode
    style?: CSSProperties
    showOnlyInVerticalMenu?: boolean
    isInVerticalMenu?: boolean
  }
>(function NavItemContainer(props, ref) {
  return (
    (!props.showOnlyInVerticalMenu || props.isInVerticalMenu) && (
      <div
        ref={ref}
        className={cn(
          `navelement pointer-events-auto flex h-full w-fit min-w-0 max-w-full flex-row items-center
          justify-center [&>*]:w-full [&>*]:min-w-0 [&>*]:max-w-fit`,
          props.className,
        )}
        style={props.style}>
        {props.children}
      </div>
    )
  )
})
export const TextOnlyNavButton = forwardRef<
  HTMLButtonElement,
  {
    onClick?: () => void
    disabled?: boolean
    className?: string
    children?: ReactNode
    style?: CSSProperties
    squashedChildrenArray?: ReactNode[]
  }
>(function TextOnlyNavButton(props, ref) {
  return (
    <NavButton
      ref={ref}
      {...props}
      className='navelement pointer-events-auto flex-none bg-transparent px-6 text-white
        hover:bg-[#ffffff22!important]'>
      {props.children}
    </NavButton>
  )
})

export const NavButton = forwardRef<
  HTMLButtonElement,
  {
    onClick?: () => void
    disabled?: boolean
    icon?: ReactNode
    className?: string
    children?: ReactNode
    style?: CSSProperties
    alt?: string
    squashedChildrenArray?: ReactNode[]
  }
>(function NavButton({ onClick, disabled, icon, children, style, alt, ...props }, ref) {
  const divRef = useRef<HTMLButtonElement>()
  const height = divRef.current?.getBoundingClientRect()?.height ?? 0
  const multiRef = useCompositeRefObject(ref, divRef)

  return (
    <button
      ref={multiRef}
      className={cn(
        `navelement pointer-events-auto box-border flex h-full w-fit min-w-0 max-w-full cursor-pointer
        flex-row items-center justify-center gap-2 overflow-clip whitespace-nowrap border-none px-2 py-3
        font-league-spartan text-[1em] hover:brightness-125 disabled:cursor-default`,
        props.className,
      )}
      disabled={disabled}
      translate={'no'}
      onClick={!disabled ? onClick : undefined}
      style={{ minHeight: height, minWidth: height, ...style }}>
      {typeof icon === 'string' && (
        <img
          src={icon}
          className='flex-none'
          style={{ objectFit: 'contain', height: '22px' }}
          alt={alt}
        />
      )}
      {typeof icon !== 'string' && icon}
      {children && (
        <SquashingBox
          className='flex h-full w-fit min-w-0 max-w-full flex-row items-center justify-center gap-2 whitespace-nowrap'
          squashedChildrenArray={props.squashedChildrenArray}>
          {children}
        </SquashingBox>
      )}
    </button>
  )
})

export const VerticalMenu = forwardRef<
  HTMLDivElement,
  PropsWithChildren<{
    heightClassName?: string
    onClose?: () => void
    className?: string
  }>
>(function SubMenu({ heightClassName, onClose, className, children }, ref) {
  return (
    <div
      ref={ref}
      className={cn(
        `pointer-events-auto flex max-h-[calc(100vh-48px)] min-w-[300px] flex-col overflow-y-auto
        bg-dark-grey scrollbar-thin scrollbar-thumb-gray-500 [&>*]:pointer-events-auto [&>*]:w-full
        [&>*]:max-w-full [&>*]:justify-center`,
        className,
      )}>
      {React.Children.map(children, (child) =>
        (
          React.isValidElement<{
            isInVerticalMenu?: boolean
          }>(child)
        ) ?
          React.cloneElement(child, { isInVerticalMenu: true })
        : child,
      )}
      {onClose && (
        <NavButton
          className={cn(
            `pointer-events-auto w-full cursor-pointer border-none bg-[#455A64] text-center font-league-spartan
            text-[1em] text-white hover:brightness-125`,
            heightClassName,
          )}
          onClick={onClose}>
          <ChevronUpIcon className='h-6 w-6' />
          Close menu
        </NavButton>
      )}
    </div>
  )
})

export function VerticalSubMenu({
  label,
  isInVerticalMenu,
  children,
  heightClassName,
}: PropsWithChildren<{ isInVerticalMenu?: boolean; label: string; heightClassName?: string }>) {
  const [expanded, setExpanded] = useState(false)
  return (
    <NavItemContainer className={cn('flex-none', isInVerticalMenu && 'relative !h-fit')}>
      <div
        className={cn(
          'flex w-fit min-w-0 flex-none flex-col',
          isInVerticalMenu &&
            '[&>*]:pointer-events-auto [&>*]:w-full [&>*]:max-w-full [&>*]:justify-center',
        )}>
        <NavButton
          className='w-fit min-w-0 flex-none bg-transparent text-center text-white hover:bg-[#ffffff22!important]'
          onClick={() => setExpanded((it) => !it)}>
          {expanded ?
            <ChevronUpIcon />
          : <ChevronDownIcon />}
          {label}
        </NavButton>
        {expanded && !isInVerticalMenu && (
          <VerticalMenu className='absolute top-[48px] z-10'>
            {children}
            <NavButton
              className={cn(
                `pointer-events-auto w-full cursor-pointer border-none bg-[#455A64] text-center font-league-spartan
                text-[1em] text-white hover:brightness-125`,
                heightClassName,
              )}
              onClick={() => setExpanded(false)}>
              <ChevronUpIcon className='h-6 w-6' />
              Close menu
            </NavButton>
          </VerticalMenu>
        )}
        {expanded && isInVerticalMenu && (
          <VerticalMenu className='bg-blue-grey/40'>{children}</VerticalMenu>
        )}
      </div>
    </NavItemContainer>
  )
}
