import React, { CSSProperties, Component, FormEvent } from 'react'
import { SingleAndDoubleClickHandler } from './UseSingleAndDoubleClick'

export type EditOnInteraction = 'click' | 'dblclick'

export interface EditableTextDivProps
  extends Omit<
    React.HTMLProps<HTMLDivElement>,
    'onChange' | 'ref' | 'onSubmit' | 'onBlur' | 'onClick' | 'onDoubleClick'
  > {
  onChange?: (changedValue: string) => void
  onSubmit?: (changedValue: string) => void
  onEnter?: (changedValue: string) => void
  onBlur?: (value: string, event?: React.FocusEvent) => void
  onClick?: (event: React.PointerEvent) => void
  onDoubleClick?: (event: React.PointerEvent) => void
  blurOnEnter?: boolean
  disabled?: boolean
  editOn?: EditOnInteraction
  focusOnMount?: boolean
  placeholderStyle?: CSSProperties
}

export interface EditableTextDivState {
  editing: boolean
  original: string | undefined
  pendingDblClick: boolean
}

export class EditableStringDiv extends Component<EditableTextDivProps, EditableTextDivState> {
  state: EditableTextDivState = {
    editing: false,
    original: undefined,
    pendingDblClick: false,
  }
  divRef = React.createRef<HTMLDivElement>()

  componentDidMount() {
    if (this.props.focusOnMount && !this.props.disabled) {
      this.focus()
    }
  }

  shouldComponentUpdate(
    nextProps: Readonly<EditableTextDivProps>,
    nextState: Readonly<EditableTextDivState>,
    nextContext: any,
  ): boolean {
    return (
      !nextState.editing &&
      (nextProps.children !== this.props.children ||
        nextProps !== this.props ||
        nextState !== this.state)
    )
  }

  value = () => this.divRef.current?.innerText

  hasFocus = (): boolean => this.state.editing

  focus = () => {
    if (this.getEditOn() === 'dblclick') {
      this.setState(
        (state) => ({ ...state, pendingDblClick: true }),
        () => {
          setTimeout(() => {
            this.divRef.current?.focus()
            this.setState((state) => ({ ...state, pendingDblClick: false }))
          }, 0)
        },
      )
    } else {
      this.divRef.current?.focus()
    }
  }

  blur = () => {
    this.divRef.current?.blur()
  }

  handleChange = (value: string) => {
    this.props.onChange?.(value)
  }

  handleFocus = (event: React.FocusEvent<HTMLDivElement>) => {
    this.setState((state) => ({
      ...state,
      editing: true,
      original: event.target.innerText,
    }))
  }

  handleBlur = (value: string, dontSubmit?: boolean, e?: React.FocusEvent) => {
    this.setState(
      (state) => ({ ...state, editing: false, original: undefined }),
      () => {
        if (this.state.original !== value && !dontSubmit) this.props.onSubmit?.(value)
        this.props.onBlur?.(value, e)
      },
    )
  }

  handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const div = this.divRef.current
    if (e.key === 'Escape' && div && this.state.editing) {
      e.stopPropagation()
      div.innerText = this.state.original || ''
      this.handleBlur(div.innerText, true)
      // we want to blur async so other key listeners know its coming from a contenteditable
      setTimeout(() => {
        div.blur()
      }, 100)
    } else if ((e.key === 'Enter' || e.key === 'NumpadEnter') && div && this.state.editing) {
      e.stopPropagation()
      if (this.props.blurOnEnter && !e.shiftKey) {
        e.preventDefault()
        this.handleBlur(div.innerText)
        this.props.onEnter?.(div.innerText)
        // we want to blur async so other key listeners know its coming from a contenteditable
        setTimeout(() => {
          div.blur()
        }, 100)
      }
    }
  }

  private isContentEditReady = () => {
    return (
      !this.props.disabled &&
      (this.getEditOn() === 'click' || this.state.pendingDblClick) &&
      !this.state.editing
    )
  }

  private isContentEditable = () => {
    return (
      !this.props.disabled &&
      (this.getEditOn() === 'click' || this.state.pendingDblClick || this.state.editing)
    )
  }

  private click = (e: React.PointerEvent) => {
    this.props.onClick?.(e)
    if (!this.props.disabled) {
      if (this.getEditOn() === 'dblclick') {
        this.setState((state) => ({ ...state, pendingDblClick: true }))
        setTimeout(() => {
          this.setState((state) => ({ ...state, pendingDblClick: false }))
        }, 300)
      }
    }
  }

  clickHandler = new SingleAndDoubleClickHandler<React.PointerEvent<HTMLDivElement>>({
    actionSingleClick: (e) => {
      if (this.hasFocus()) return
      this.props.onClick?.(e)
    },
    actionDoubleClick: (event) => {
      if (this.getEditOn() === 'dblclick') {
        const div = this.divRef.current
        if (!div) return
        this.focus()
        // div.contentEditable = "true";
      } else {
        this.props.onDoubleClick?.(event)
      }
    },
    stopPointerDownPropagation: true,
    stopPointerUpPropagation: true,
  })
  handleDown = (e: React.PointerEvent<HTMLDivElement>) => {
    !this.props.disabled && e.stopPropagation()
    !this.props.disabled && this.clickHandler.handleDown(e, !this.props.disabled)
  }
  handleUp = (e: React.PointerEvent<HTMLDivElement>) => {
    !this.props.disabled && e.stopPropagation()
    !this.props.disabled && this.clickHandler.handleUp(e, !this.props.disabled)
  }
  handleMove = (e: React.PointerEvent<HTMLDivElement>) => {
    !this.props.disabled && e.stopPropagation()
    !this.props.disabled && this.clickHandler.handleMove(e)
  }

  handleClick = (e: React.PointerEvent<HTMLDivElement>) => {
    !this.props.disabled && e.preventDefault()
    !this.props.disabled && e.stopPropagation()
    if (this.props.disabled) {
      this.props.onClick?.(e)
    }
    // this.click(e);
  }

  handleDoubleClick = (e: React.PointerEvent<HTMLDivElement>) => {
    !this.props.disabled && e.preventDefault()
    !this.props.disabled && e.stopPropagation()
    if (this.props.disabled) {
      this.props.onDoubleClick?.(e)
    }
    // this.click(e);
  }

  private getEditOn = (): EditOnInteraction => {
    if (this.props.editOn === undefined) return 'click'
    return this.props.editOn
  }

  render() {
    const {
      onChange,
      onSubmit,
      onEnter,
      onBlur,
      onClick,
      blurOnEnter,
      disabled,
      editOn,
      focusOnMount,
      placeholderStyle,
      translate = 'no',
      as,
      ...divProps
    } = this.props
    return (
      <div
        className={'editable-div'}
        {...divProps}
        ref={this.divRef}
        onChange={() => {
          //do nothing
        }}
        onKeyDown={this.handleKeyPress}
        data-text={divProps.placeholder}
        suppressContentEditableWarning={true}
        translate={translate}
        style={{
          cursor: disabled ? undefined : 'text',
          userSelect: 'text',
          ...divProps.style,
          ...(!divProps.children ? placeholderStyle : {}),
        }}
        data-dblclick-editable={!disabled && editOn === 'dblclick'}
        data-disabled-editable={disabled}
        contentEditable={this.isContentEditable()}
        onFocus={this.handleFocus}
        onPointerUp={this.handleUp}
        onPointerDown={this.handleDown}
        onPointerMove={this.handleMove}
        onClick={this.handleClick}
        onDoubleClick={this.handleDoubleClick}
        onInput={(e: FormEvent<HTMLDivElement>) =>
          this.handleChange((e.target as HTMLDivElement).innerText)
        }
        onBlur={(e: React.FocusEvent<HTMLDivElement>) =>
          this.handleBlur((e.target as HTMLDivElement).innerText, false, e)
        }
        onSubmit={undefined}>
        {divProps.children}
      </div>
    )
  }
}
