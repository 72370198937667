import { PlayerStatProfile } from 'components/PlayerLeaderboardStatProfile'
import {
  BaseProFeatureFlagsDefinition,
  BaseSubscriptionContext,
  GroupBasedFlagDefinition,
  ProFeatureFlags,
  ReviewBasedFlagDefinition,
  SubscriptionConfig,
  groupFlag,
  reviewFlag,
} from 'hooks/UseProFeaturesStore'

// ADDD NEW FLAGS HERE
export type ProFeatureFlagsDefinition = {
  exportVideoEnabled?: ReviewBasedFlagDefinition<boolean>
  createProtectedStatsRecord?: ReviewBasedFlagDefinition<boolean>
  autoTrackBothTeamsEnabled?: ReviewBasedFlagDefinition<boolean>
  publishPublicLeaderboardEnabled?: GroupBasedFlagDefinition<boolean>
  registerLeaderboardTeamEmails?: GroupBasedFlagDefinition<boolean>
  maxTeamMembersAllowed?: GroupBasedFlagDefinition<number>
  maxGamesOnLeaderboard?: GroupBasedFlagDefinition<number>
  playerStatProfileLevel?: GroupBasedFlagDefinition<PlayerStatProfile.StatsLevel>
} & BaseProFeatureFlagsDefinition

export const FLAG_DEFAULTS: ProFeatureFlags = {
  registerLeaderboardTeamEmails: undefined,
  autoTrackBothTeamsEnabled: undefined,
  createProtectedStatsRecord: undefined,
  exportVideoEnabled: undefined,
  maxTeamMembersAllowed: undefined,

  // TODO(DAvey): reduce to 2 when we have messaging
  maxGamesOnLeaderboard: 1000,
  publishPublicLeaderboardEnabled: undefined,
  playerProfilesEnabled: undefined,
  playerStatProfileLevel: PlayerStatProfile.BASIC,
}

/**
 * Defines the subscription context for pro feature flags.
 */
export type SubscriptionContext = BaseSubscriptionContext &
  (
    | { type: 'statsPro'; userId: string }
    | { type: 'staff'; userId: string }
    | { type: 'teamPro'; groupId: string; interval: string | number | null; seats: number }
    | { type: 'leaguePro'; groupId: string; interval: string | number | null; seats: number }
  )

export const FLAGS_CONFIG: SubscriptionConfig = {
  staff: (subsContext) => ({
    exportVideoEnabled: reviewFlag(() => true),
    autoTrackBothTeamsEnabled: reviewFlag(() => true),
    createProtectedStatsRecord: reviewFlag(() => true),
    maxTeamMembersAllowed: groupFlag(({ groupInView }) => 9999),
    publishPublicLeaderboardEnabled: groupFlag(({ groupInView }) => true),
    playerStatProfileLevel: groupFlag(({ groupInView }) => PlayerStatProfile.ADVANCED),
    registerLeaderboardTeamEmails: groupFlag(({ groupInView }) => true),
  }),
  statsPro: (subsContext) => ({
    exportVideoEnabled: reviewFlag(() => true),
    autoTrackBothTeamsEnabled: reviewFlag(() => true),
    playerStatProfileLevel: groupFlag(({ groupInView }) =>
      groupInView.isUserInGroup ? PlayerStatProfile.ADVANCED : PlayerStatProfile.BASIC,
    ),
  }),
  teamPro: (subsContext) => ({
    exportVideoEnabled: reviewFlag(
      ({ reviewInView: { groupIds, isUserAnEditor } }) =>
        !!groupIds?.[subsContext.groupId] && isUserAnEditor,
    ),
    maxTeamMembersAllowed: groupFlag(({ groupInView }) =>
      // TODO(DAvey):Check subscription for number of members
      groupInView.groupId === subsContext.groupId ? subsContext.seats : undefined,
    ),
    maxGamesOnLeaderboard: groupFlag(({ groupInView }) =>
      groupInView.groupId === subsContext.groupId ? subsContext.seats * 3 : undefined,
    ),
    playerStatProfileLevel: groupFlag(({ groupInView }) =>
      groupInView.groupId === subsContext.groupId ?
        PlayerStatProfile.ADVANCED
      : PlayerStatProfile.BASIC,
    ),
  }),
  leaguePro: (subsContext) => ({
    exportVideoEnabled: reviewFlag(
      ({ reviewInView: { groupIds, isUserAnEditor } }) =>
        !!groupIds?.[subsContext.groupId] && isUserAnEditor,
    ),
    maxTeamMembersAllowed: groupFlag(({ groupInView }) =>
      groupInView.groupId === subsContext.groupId ? subsContext.seats : undefined,
    ),
    maxGamesOnLeaderboard: groupFlag(({ groupInView }) =>
      groupInView.groupId === subsContext.groupId ? 1000 : undefined,
    ),
    autoTrackBothTeamsEnabled: reviewFlag(
      ({ reviewInView: { groupIds, isUserAnEditor } }) =>
        !!groupIds?.[subsContext.groupId] && isUserAnEditor,
    ),
    publishPublicLeaderboardEnabled: groupFlag(
      ({ groupInView }) => groupInView.groupId === subsContext.groupId && groupInView.isUserInGroup,
    ),
    createProtectedStatsRecord: reviewFlag(
      ({ reviewInView: { groupIds, isUserAnEditor } }) => !!groupIds?.[subsContext.groupId],
    ),
    playerStatProfileLevel: groupFlag(({ groupInView }) =>
      groupInView.groupId === subsContext.groupId ?
        PlayerStatProfile.ADVANCED
      : PlayerStatProfile.BASIC,
    ),
    registerLeaderboardTeamEmails: groupFlag(
      ({ groupInView }) => groupInView.groupId === subsContext.groupId,
    ),
  }),
}
