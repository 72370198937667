import { Flex, Input, useUpdateEffect } from '@chakra-ui/react'
import { useGroupDetailsStore, useGroupSelectionStore } from 'UseGroupSelectionStore'
import { DocumentPermissions } from 'data/common'
import { RecentsFirebaseEntry } from 'data/common'
import { User } from 'firebase/auth'
import { SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'
import { ThreeDots } from 'react-loading-icons'
import { firebaseConfig } from 'services/FirebaseConfig'
import { isFunctionLike } from 'typescript'
import { useReviewMetaStore } from 'ui/ReviewMetaStore'
import { Colors } from '../Colors'
import { ReviewTile } from '../ReviewTile'
import { useRecentsReviewStore } from '../UseRecentsReviewStore'
import { showDialog } from '../components/common/Dialog'
import { FirebaseDb, getApp, initFirebase } from '../components/common/Firebase'
import { RoundButton } from '../components/common/RoundButton'
import searchIcon from '../icons/magnify_graph.png'
import { GroupsList } from './ReviewsList'
import { ScrollBox } from './ScrollBox'
import { SearchInput } from './SearchInput'
import { useFeatureFlag } from './common/hooks/useFeatureFlags'
import { cn } from './common/utils/tailwindUtils'

export type ExistingReviewSelectionState = {
  selection:
    | {
        reviewId: string
        documentPermissions: DocumentPermissions
        strategy: 'open' | 'replace' | 'link'
      }
    | { strategy: 'create_new' }
    | undefined
}

export function showProcessExistingReviewsForVideoSourceDialog(props: {
  firebaseDb: FirebaseDb
  reviews: RecentsFirebaseEntry[]
  statsRecordId: string | undefined
  user: User | undefined
}): Promise<ExistingReviewSelectionState> {
  return new Promise<ExistingReviewSelectionState>((resolve, reject) => {
    showDialog<ExistingReviewSelectionState>(
      {
        title: 'Existing Review',
        user_dismissable: true,
        children: (Red, state, setState, triggerPositiveButton, focusPositiveButton, dismiss) => (
          <>
            <Red>You already have a review for this video.</Red>
            <br />
            <br /> Would you like to {props.statsRecordId ?
              'attach the stats record to'
            : 'open'}{' '}
            an existing review or create a new one?
            <br />
            <ScrollBox
              direction={'vertical'}
              className='max-h-[60svh]'>
              <ExistingReviewsList
                reviews={props.reviews}
                selectedReviewId={
                  state?.selection?.strategy !== 'create_new' ?
                    state?.selection?.reviewId
                  : undefined
                }
                statsRecordId={props.statsRecordId}
                user={props.user}
                firebaseDb={props.firebaseDb}
                onSelected={(selection) => {
                  if (selection.intentionLevel === 'implicit') setState({ selection })
                  else {
                    triggerPositiveButton({ selection })
                  }
                }}
              />
            </ScrollBox>
          </>
        ),
        positiveButtonProps: {
          disabled: (state) => !state?.selection,
          text: (state) => {
            const strategy = state?.selection?.strategy
            switch (strategy) {
              case 'link':
                return 'Attach Stats'
              case 'replace':
                return 'Replace Stats'
              default:
                return 'Open'
            }
          },
          onClicked: (state, setState) => {
            resolve(state)
            return true
          },
        },
        negativeButtonProps: {
          text: 'Create New Review',
          onClicked: (state, setState) => {
            resolve({ selection: { strategy: 'create_new' } })
            return true
          },
        },
        onDismiss: () => {
          resolve({ selection: undefined })
        },
      },
      { selection: undefined },
    )
  })
}

export function showAddReviewsToGroupDialog(props: {
  firebaseDb: FirebaseDb
  groupId: string
  user: User
}): Promise<{ reviewId: string; documentPermissions: DocumentPermissions }[]> {
  return new Promise<{ reviewId: string; documentPermissions: DocumentPermissions }[]>(
    (resolve, reject) => {
      showDialog<{ reviewId: string; documentPermissions: DocumentPermissions }[]>({
        title: 'Import Reviews',
        children: (
          FocusSpan,
          state,
          setState,
          triggerPositiveButton,
          focusPositiveButton,
          dismiss,
        ) => (
          <AddReviewsToGroupContent
            destinationGroupId={props.groupId}
            user={props.user}
            firebaseDb={props.firebaseDb}
            onSelectionChanged={(a) => {
              setState(a)
            }}
          />
        ),
        positiveButtonProps: {
          text: (state) => `Import ${state?.length ? state.length : ''} Reviews`,
          disabled: (state) => !state || !state.length,
          onClicked: (state, setState) => {
            resolve(state ?? [])
            return true
          },
        },
        negativeButtonProps: 'Cancel',
        onDismiss: () => {
          resolve([])
        },
      })
    },
  )
}
export function AddReviewsToGroupContent(props: {
  firebaseDb: FirebaseDb
  user: User
  destinationGroupId: string
  onSelectionChanged: (
    reviewIds: { reviewId: string; documentPermissions: DocumentPermissions }[],
  ) => void
}) {
  const { orderedReviews } = useRecentsReviewStore(
    props.firebaseDb,
    props.user,
    props.destinationGroupId,
  )
  const detailsStore = useGroupDetailsStore(props.firebaseDb, props.destinationGroupId)
  const groupSelectionStore = useGroupSelectionStore({
    firebaseDb: props.firebaseDb,
    user: props.user,
    defaultSelection: { type: 'option', groupId: undefined },
  })
  const { value: enableTextInput } = useFeatureFlag('textinputreviews', true, true)
  const alreadySelected = useMemo(
    () =>
      orderedReviews?.map((it) => ({
        reviewId: it.reviewId,
        documentPermissions: it.mode ?? 'edit',
      })),
    [orderedReviews],
  )
  const { onSelectionChanged } = props

  const handleSubmitUrls = useCallback(
    async (text: string) => {
      const urls = text.split(/[\s,]+/)
      const processedUrls = await Promise.all(
        urls.map(async (url) => {
          if (!url) return undefined
          let review = parseEditorUrl(url)
          if (!review) {
            const newUrl = await checkRedirect(url)
            review = newUrl ? parseEditorUrl(newUrl) : undefined
          }
          return review
        }),
      )
      const validReviews = processedUrls.filterNotNull()
      onSelectionChanged(
        validReviews.map((review) => ({
          reviewId: review.id,
          documentPermissions: review.mode,
        })),
      )
    },
    [onSelectionChanged],
  )

  return (
    <>
      Select Playback Reviews to be added to {detailsStore?.name}
      <br />
      <br />
      <ScrollBox
        direction={'vertical'}
        className='max-h-[60svh]'>
        {enableTextInput && (
          <>
            <SubmittableTextArea onSubmit={handleSubmitUrls} />
            <br />
          </>
        )}
        <GroupsList
          firebaseDb={props.firebaseDb}
          groupSelectionStore={groupSelectionStore}
          excludeGroupId={props.destinationGroupId}
          user={props.user}
          showPersonal={true}
        />
        <br />
        <ReviewsSelectionList
          user={props.user}
          // We want to add reviews from outside the group
          filterGroupId={groupSelectionStore.selectedGroupId}
          selected={alreadySelected}
          firebaseDb={props.firebaseDb}
          onSelectionChanged={props.onSelectionChanged}
        />
      </ScrollBox>
    </>
  )
}

function ExistingReview(props: {
  proposedStatsRecordId: string | undefined
  firebaseDb: FirebaseDb
  review: RecentsFirebaseEntry
  user: User | undefined
  selectedReviewId: string | undefined
  onSelected: (reviewId: {
    reviewId: string
    documentPermissions: DocumentPermissions
    strategy: 'open' | 'replace' | 'link'
    // Direct means the user's intention is clear and doesn't require another click to confirm
    // Implicit means it should require one more click to confirm the users intention
    intentionLevel: 'direct' | 'implicit'
  }) => void
}) {
  const { review } = props
  const metaStore = useReviewMetaStore(
    props.firebaseDb,
    props.user,
    review.reviewId,
    undefined,
    undefined,
    undefined,
  )

  let strategy: 'open' | 'replace' | 'link' = 'open'
  if (
    props.proposedStatsRecordId &&
    !metaStore.parentStatRecord &&
    !metaStore.statRecordAlias?.allTeams
  ) {
    strategy = 'link'
  }

  if (
    props.proposedStatsRecordId &&
    !metaStore.statRecordAlias?.allTeams &&
    metaStore.parentStatRecord &&
    metaStore.parentStatRecord !== props.proposedStatsRecordId
  ) {
    strategy = 'replace'
  }

  return (
    <Flex
      key={review.reviewId}
      dir={'row'}
      alignItems={'center'}
      gap={8}
      padding={4}
      border={props.selectedReviewId === review.reviewId ? '2px solid white' : undefined}>
      <ReviewTile<RecentsFirebaseEntry>
        key={review.reviewId}
        entry={review}
        user={props.user}
        onClick={() =>
          props.onSelected({
            reviewId: review.reviewId,
            documentPermissions: review.mode ?? 'edit',
            strategy,
            intentionLevel: 'implicit',
          })
        }
        firebaseDb={props.firebaseDb}
      />
      <Flex
        gap={8}
        fontFamily={'LeagueSpartan'}
        style={{
          fontVariationSettings: `'wght' 400`,
          fontSize: 14,
        }}
        w={100}
        alignItems={'center'}
        justifyContent={'center'}>
        {strategy === 'link' && (
          <RoundButton
            className='h-fit'
            color={Colors.color_white}
            backgroundColor={Colors.color_playback_crimson}
            onClick={() =>
              props.onSelected({
                reviewId: review.reviewId,
                documentPermissions: review.mode ?? 'edit',
                strategy: 'link',
                intentionLevel: 'direct',
              })
            }>
            Link Stats
          </RoundButton>
        )}
        {strategy === 'replace' && (
          <RoundButton
            className='h-fit whitespace-nowrap'
            color={Colors.color_black}
            backgroundColor={Colors.color_comments_orange}
            onClick={() =>
              props.onSelected({
                reviewId: review.reviewId,
                documentPermissions: review.mode ?? 'edit',
                strategy: 'replace',
                intentionLevel: 'direct',
              })
            }>
            Replace Link
          </RoundButton>
        )}
        {strategy === 'open' && (
          <RoundButton
            className='h-fit whitespace-nowrap'
            color={Colors.color_white}
            backgroundColor={Colors.color_playback_crimson}
            onClick={() =>
              props.onSelected({
                reviewId: review.reviewId,
                documentPermissions: review.mode ?? 'edit',
                strategy: 'open',
                intentionLevel: 'direct',
              })
            }>
            Open
          </RoundButton>
        )}
      </Flex>
    </Flex>
  )
}

export function ExistingReviewsList(props: {
  firebaseDb: FirebaseDb
  reviews: RecentsFirebaseEntry[]
  user: User | undefined
  statsRecordId: string | undefined
  selectedReviewId: string | undefined
  onSelected: (reviewId: {
    reviewId: string
    documentPermissions: DocumentPermissions
    strategy: 'open' | 'replace' | 'link'
    // Direct means the user's intention is clear and doesn't require another click to confirm
    // Implicit means it should require one more click to confirm the users intention
    intentionLevel: 'direct' | 'implicit'
  }) => void
}) {
  return (
    <Flex
      direction={'column'}
      gap={8}>
      {props.reviews.map((review) => {
        return (
          <ExistingReview
            key={review.reviewId}
            proposedStatsRecordId={props.statsRecordId}
            firebaseDb={props.firebaseDb}
            review={review}
            user={props.user}
            selectedReviewId={props.selectedReviewId}
            onSelected={props.onSelected}
          />
        )
      })}
    </Flex>
  )
}

export function ReviewsSelectionList(props: {
  firebaseDb: FirebaseDb
  filterGroupId?: string
  user: User | undefined
  selected?: { reviewId: string; documentPermissions: DocumentPermissions }[]
  onSelectionChanged: (
    reviewIds: { reviewId: string; documentPermissions: DocumentPermissions }[],
  ) => void
}) {
  const [showSearch, setShowSearch] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const reviewStore = useRecentsReviewStore(props.firebaseDb, props.user, props.filterGroupId)
  const [selected, setInternalSelected] = useState<
    { reviewId: string; documentPermissions: DocumentPermissions }[]
  >(props.selected ?? [])

  const setSelected = useCallback(
    (a: SetStateAction<{ reviewId: string; documentPermissions: DocumentPermissions }[]>) => {
      if (typeof a === 'function') {
        setInternalSelected((it) => {
          const newIt = a(it)

          props.onSelectionChanged(newIt)
          return newIt
        })
        return
      }
      setInternalSelected(a)
      props.onSelectionChanged(a)
    },
    [props],
  )

  const toggleReview = useCallback(
    (reviewId: string, documentPermissions: DocumentPermissions) => {
      setSelected((it) =>
        it.some(({ reviewId: id }) => id === reviewId) ?
          it.filter(({ reviewId: id }) => id !== reviewId)
        : it.concat([{ reviewId, documentPermissions }]),
      )
    },
    [setSelected],
  )

  return (
    <Flex
      direction={'column'}
      gap={8}>
      {!showSearch && (
        <RoundButton
          className='w-fit self-center'
          icon={searchIcon}
          alt={''}
          backgroundColor={Colors.color_playback_crimson}
          color={'white'}
          iconInvert={true}
          onClick={() => setShowSearch(true)}>
          Search
        </RoundButton>
      )}
      {showSearch && (
        <SearchInput
          autoFocus={true}
          onKeyDown={(e) => setSearchTerm(e.currentTarget.value)}
          placeholder={'Search'}
          submitText={'Search'}
          onSubmit={(e) => setSearchTerm(e)}
          onClose={() => {
            setShowSearch(false)
            setSearchTerm('')
          }}
        />
      )}

      {reviewStore.orderedReviews === undefined && <ThreeDots className='w-full' />}
      {reviewStore.orderedReviews
        ?.filter(
          (entry, index) =>
            !searchTerm ||
            entry.reviewId.includes(searchTerm) ||
            entry.videoId?.includes(searchTerm) ||
            entry.title?.toLowerCase()?.includes(searchTerm.toLowerCase()),
        )
        .map((review) => {
          const alreadyAdded = props.selected?.some(({ reviewId }) => reviewId === review.reviewId)
          return (
            <div
              key={review.reviewId}
              className={cn('relative flex')}>
              <Input
                type={'checkbox'}
                className={cn(alreadyAdded && 'opacity-30')}
                w={40}
                checked={alreadyAdded || selected.some((it) => it.reviewId === review.reviewId)}
                readOnly={alreadyAdded}
                onClick={
                  !alreadyAdded ?
                    () => toggleReview(review.reviewId, review.mode ?? 'edit')
                  : undefined
                }
                disabled={alreadyAdded}
              />
              {alreadyAdded && (
                <div className='absolute z-10 flex h-full w-full items-center justify-center text-white'>
                  <span className='rounded-full bg-slate-800/70 px-3 py-1'>Already added</span>
                </div>
              )}
              <div className={cn('w-full', alreadyAdded && 'opacity-30')}>
                <ReviewTile<RecentsFirebaseEntry>
                  key={review.reviewId}
                  onClick={() =>
                    !alreadyAdded ? toggleReview(review.reviewId, review.mode ?? 'edit') : undefined
                  }
                  entry={review}
                  user={props.user}
                  firebaseDb={props.firebaseDb}
                />
              </div>
            </div>
          )
        })}
    </Flex>
  )
}

function SubmittableTextArea(props: { onSubmit: (text: string) => void }) {
  const [showTextArea, setShowTextArea] = useState(false)

  return (
    <>
      {showTextArea ?
        <div>
          <textarea
            className='h-fit w-full'
            onChange={(e) => props.onSubmit(e.currentTarget.value)}
            onPaste={(e) => props.onSubmit(e.currentTarget.value)}
          />
          <RoundButton onClick={() => setShowTextArea(false)}>Cancel</RoundButton>
        </div>
      : <RoundButton
          className='bg-playback-crimson text-white'
          onClick={() => setShowTextArea(true)}>
          Import review links
        </RoundButton>
      }
    </>
  )
}

export const parseEditorUrl = (
  url: string,
): { id: string; mode: DocumentPermissions } | undefined => {
  try {
    const o = new URL(url)
    const id = o.searchParams.get('id')
    const mode = o.searchParams.get('mode')

    if (id && mode && (mode === 'view' || mode === 'edit')) {
      return { id, mode: mode as DocumentPermissions }
    }
    return undefined
  } catch (error) {
    console.error('Invalid URL:', error)
    return undefined
  }
}

async function checkRedirect(url: string): Promise<string | undefined> {
  try {
    const newUrl = new URL(url)
    newUrl.searchParams.set('_imcp', '1')
    const headResponse = await fetch(newUrl.toString(), { method: 'HEAD', redirect: 'follow' })
    return headResponse.url
  } catch (error) {
    console.error('Error checking URL for redirect:', error)
  }
}
