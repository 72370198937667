import {
  ExistingReviewSelectionState,
  showProcessExistingReviewsForVideoSourceDialog,
} from 'components/showAddReviewsToGroupDialog'
import { User } from 'firebase/auth'
import { MutableRefObject, useCallback, useEffect, useMemo, useRef } from 'react'
import ReactTagManager from 'react-ga4'
import { CreateReviewFirebaseEntry, TimelineStorage } from '../AppDataRepository'
import { ReviewsStore } from '../UseRecentsReviewStore'
import { showDialog } from '../components/common/Dialog'
import { FirebaseDb, FirebaseDbReference } from '../components/common/Firebase'
import { useQueryString } from '../components/common/utils/QueryString'
import { randomUUID } from '../components/common/utils/randomUUID'
import {
  getParentStatRecordId,
  getStatRecord,
  getStatRecordAlias,
} from '../hooks/UseStatRecordReviewId'
import { showActivityTypeChooseDialog } from '../ui/ChooseActivityType'
import { ReviewActivityType, ReviewMetaStore, useReviewMetaStore } from '../ui/ReviewMetaStore'
import { SetViewingModeProps, useDocumentPermissionsStore } from '../ui/UseDocumentPermissionsStore'
import { useReviewSelectionState } from './UseReviewSelectionState'
import { ViewingMode } from './ViewingMode'
import { DocumentPermissions } from './common'
import {
  FirebaseEventMetaStatsEntry,
  FirebaseVideoSource,
  MetaStatsCache,
  RecentsFirebaseEntry,
  ReviewFirebaseEntry,
  VideoSource,
} from './common'

import mapFirebaseFromActivityType = TimelineStorage.mapFirebaseFromActivityType

export interface ReviewSelection {
  videoId: string
  reviewId: string
  source: VideoSource
  eventId?: string
  initTime: number
}

export type LoadNewVideoProps =
  | {
      reviewId: string
      permissions: DocumentPermissions
      type: 'reviewId'
    }
  | { videoSourceData: VideoSourceData; type: 'videoSourceData' }

interface ReviewSelectionInit {
  selection?: ReviewSelection
  activityType: ReviewActivityType | undefined
  isLocalReview: boolean
  owner: string | undefined
  videoTitle: string | undefined
  setInitialVideoTitle: (title: string) => void
  viewingMode: ViewingMode
  documentPermissions?: DocumentPermissions
  setViewingMode: (props: SetViewingModeProps) => boolean
  showAnalysis: string | undefined
  setShowAnalysis: (showAnalysis: boolean) => void
}

export interface BroadcastFirebaseEntry {
  reviewId: string | null
  ownerUID: string | null
  videoId: string | null
  source: FirebaseVideoSource | null
  createdTime: number
  title: string | null
  mode: ViewingMode | null
  scheduled: number
  isPublicStatsRecord: boolean | null
  isAttachedToStatsRecord: boolean | null
}

export interface FirebaseUserReviews {
  owned: { [id: string]: RecentsFirebaseEntry & MetaStatsCache } | null
  visited: { [id: string]: RecentsFirebaseEntry & MetaStatsCache } | null
}

export interface FirebaseBroadCastReviews {
  global: { [id: string]: BroadcastFirebaseEntry } | null
}

export type VideoSourceData = {
  id: string
  source: VideoSource
}

export type StatRecordSource = { id: string; source: 'StatRecord' }

export async function createNewReview({
  activityType,
  firebaseDb,
  user,
  statRecordSourceData,
  videoSourceData,
}: {
  activityType: ReviewActivityType
  statRecordSourceData: StatRecordSource | undefined
  videoSourceData: VideoSourceData
  user: User | undefined
  firebaseDb: FirebaseDb
}) {
  const reviewId = randomUUID()
  await firebaseDb.getRef(`reviews/${reviewId}`).set({
    videoId: videoSourceData.id,
    source: videoSourceData.source,
    activityType: mapFirebaseFromActivityType(activityType),
    parentStatRecord: statRecordSourceData?.id || null,
  } satisfies CreateReviewFirebaseEntry)

  localStorage.setItem(`videoId:${videoSourceData.id}:${videoSourceData.source}`, reviewId)

  await claimCreateDate(reviewId, firebaseDb)

  ReactTagManager.event('review_created', { activity: activityType })
  if (user) {
    await claimOwnership(reviewId, user, firebaseDb)
  }
  return reviewId
}

/**
 * Maps stat record source data to video source data if source is StatRecord.
 *
 * returns null if stat record source data doesn't map to a valid video source
 * @param firebase
 * @param videoSourceData
 * @param activityType
 *
 */
async function mapVideoSourceInfo(
  firebase: FirebaseDb,
  videoSourceData: VideoSourceData | StatRecordSource,
  activityType?: ReviewActivityType,
): Promise<{ videoSourceData: VideoSourceData; activityType?: ReviewActivityType } | undefined> {
  if (videoSourceData.source !== 'StatRecord') {
    return videoSourceData && { videoSourceData: videoSourceData, activityType }
  }

  const entry = await getStatRecord({
    firebase,
    statsRecordId: videoSourceData.id,
  })
  if (!entry) return undefined

  const mappedVideoSource: VideoSourceData = {
    id: entry.videoId,
    source: entry.source,
  }

  return (
    mappedVideoSource && {
      videoSourceData: mappedVideoSource,
      activityType: TimelineStorage.mapFirebaseToActivityType(entry.activityType),
    }
  )
}

export async function processVideoSourceData({
  firebaseDb,
  user,
  videoSourceData,
  activityType,
  reviewStore,
}: {
  videoSourceData: VideoSourceData | StatRecordSource
  user: User | undefined
  activityType?: ReviewActivityType
  firebaseDb: FirebaseDb
  reviewStore: ReviewsStore
}) {
  const storedReviewId =
    localStorage.getItem(`videoId:${videoSourceData.id}:${videoSourceData.source}`) ||
    // backwards compatibility
    localStorage.getItem(`videoId:${videoSourceData.id}`)

  const mappedVideoSourceData = await mapVideoSourceInfo(firebaseDb, videoSourceData, activityType)

  if (!mappedVideoSourceData) {
    await showDialog({
      title: `Stat Record not found`,
      children: (Red) => {
        return (
          <>
            We couldn&apos;t find that Stat Record. Ask the owner of this Record to create a new
            link for you.
          </>
        )
      },
      positiveButtonProps: 'OK',
      user_dismissable: false,
    })
    return false
  }

  const recent = reviewStore.findMatchingReview(mappedVideoSourceData.videoSourceData)

  // TODO: Check if the user's team already has a review [PREMIUM]
  const hasExisting =
    (storedReviewId &&
      // There was a momentary bug (couple days) where creating reviews failed but the id's were still stored locally
      (await firebaseDb.getRef(`reviews/${storedReviewId}`).get()).exists()) ||
    recent.length

  let result: ExistingReviewSelectionState | undefined
  if (hasExisting) {
    if (storedReviewId && recent.none((it) => it.reviewId === storedReviewId)) {
      const title = await firebaseDb.getVal<string>(`reviews/${storedReviewId}/title`)
      const entry = await createRecentReviewEntry({
        reviewOwner: user?.uid,
        videoSourceData: mappedVideoSourceData.videoSourceData,
        title: title,
        reviewId: storedReviewId,
        createdTime: undefined,
        documentPermissions: 'edit',
        firebaseDb,
      })
      recent.push(entry)
    }

    result = await showProcessExistingReviewsForVideoSourceDialog({
      reviews: recent,
      statsRecordId: videoSourceData.source === 'StatRecord' ? videoSourceData.id : undefined,
      firebaseDb,
      user,
    })

    if (!result.selection) {
      return undefined
    }

    switch (result?.selection?.strategy) {
      case 'open':
        return result.selection.reviewId
      case 'link':
      case 'replace':
        await firebaseDb
          .getRef(`reviews/${result.selection.reviewId}/parentStatRecord`)
          .set(videoSourceData.id)
        return result.selection.reviewId
      case 'create_new':
        // continue to create new review
        break
    }
  }

  const chosenActivityType =
    mappedVideoSourceData.activityType ?? (await showActivityTypeChooseDialog())

  if (chosenActivityType)
    return await createNewReview({
      activityType: chosenActivityType,
      firebaseDb,
      user,
      statRecordSourceData: videoSourceData.source === 'StatRecord' ? videoSourceData : undefined,
      videoSourceData: mappedVideoSourceData.videoSourceData,
    })
  return undefined
}

export function useReviewSelectionInitialisation(
  firebaseDb: FirebaseDb,
  localStorage: Storage,
  browserId: string,
  user: User | undefined,
): ReviewSelectionInit {
  const { reviewId, isNewReview, videoSourceData, isLocalReview } = useReviewSelectionState(
    firebaseDb,
    localStorage,
  )
  const [showAnalysis, setShowAnalysis] = useQueryString('showanalysis', undefined)
  const [eventId] = useQueryString('eventid', undefined)
  const [timeParam] = useQueryString('t', undefined)
  const visitCountRecordedRef = useRef(false)
  const reviewMetaStore = useReviewMetaStore(
    firebaseDb,
    user,
    reviewId,
    undefined,
    undefined,
    undefined,
  )
  const {
    viewingMode,
    setViewingMode,
    documentPermissions,
    setDocumentPermissions: setDocumentPermissionsInternal,
    owner,
  } = useDocumentPermissionsStore({
    activityType: reviewMetaStore.activityType,
    reviewId: reviewId,
    firebaseDb: firebaseDb,
    user: user,
    isLocalReview: isLocalReview,
    localStorage,
  })

  const storedTitle = reviewMetaStore.title

  const setInitialVideoTitle = useCallback(
    (title: string) => {
      if (reviewId && !storedTitle && title&&documentPermissions) {
        reviewMetaStore.changeTitle?.(title,documentPermissions)
      }
    },
    [reviewId, storedTitle, reviewMetaStore,documentPermissions],
  )

  useEffect(() => {
    if (reviewId && isNewReview && user) {
      // NEW REVIEW AND LOGGED IN
      claimOwnership(reviewId, user, firebaseDb)
    }
  }, [reviewId, isNewReview, user, firebaseDb])

  useEffect(() => {
    if (reviewId && isNewReview) {
      // NEW REVIEW AND LOGGED IN
      claimCreateDate(reviewId, firebaseDb)
    }
  }, [reviewId, isNewReview, firebaseDb])

  const { regenerateMeta } = reviewMetaStore
  useEffect(() => {
    if (!reviewId) return
    firebaseDb.get(`reviews/${reviewId}`).then((reviewSnapshot) => {
      if (
        videoSourceData &&
        (!reviewSnapshot.exists() || !reviewSnapshot.child('videoId').exists())
      ) {
        // set videoId
        firebaseDb.getRef(`reviews/${reviewId}/videoId`).set(videoSourceData.id)
        firebaseDb.getRef(`reviews/${reviewId}/source`).set(videoSourceData.source)
      }

      if (!reviewSnapshot.exists()) return
      const review: ReviewFirebaseEntry = reviewSnapshot.val() as ReviewFirebaseEntry
      repairReviewMetaData({
        review,
        reviewId,
        user,
        videoSourceData,
        firebaseDb,
        title: storedTitle,
        documentPermissions,
        isLocalReview,
      }).then((it) => {
        if (!(reviewId && videoSourceData)) return
        return recordReviewVisit(
          documentPermissions,
          it?.reviewOwner,
          videoSourceData,
          it?.actualTitle,
          reviewId,
          it?.createTime,
          browserId,
          user,
          firebaseDb,
          visitCountRecordedRef,
          regenerateMeta,
        )
      })
    })
  }, [
    documentPermissions,
    reviewId,
    videoSourceData,
    firebaseDb,
    user,
    storedTitle,
    localStorage,
    isLocalReview,
    browserId,
    regenerateMeta,
  ])

  const selection: ReviewSelection | undefined = useMemo(() => {
    let time: number
    try {
      time = parseInt(timeParam ?? '0')
    } catch (e) {
      time = 0
    }
    return videoSourceData && reviewId ?
        {
          videoId: videoSourceData.id,
          source: videoSourceData.source,
          reviewId,
          eventId,
          initTime: time,
        }
      : undefined
  }, [videoSourceData, reviewId, eventId, timeParam])

  return useMemo(
    () => ({
      isLocalReview,
      selection,
      videoTitle: storedTitle,
      activityType: reviewMetaStore.activityType,
      setInitialVideoTitle,
      viewingMode: viewingMode,
      owner,
      documentPermissions,
      setViewingMode,
      showAnalysis: showAnalysis,
      setShowAnalysis: (showAnalysis) => setShowAnalysis(showAnalysis ? 'true' : undefined),
    }),
    [
      documentPermissions,
      selection,
      owner,
      storedTitle,
      reviewMetaStore.activityType,
      setInitialVideoTitle,
      viewingMode,
      setViewingMode,
      showAnalysis,
      setShowAnalysis,
      isLocalReview,
    ],
  )
}

async function recordReviewVisit(
  documentPermissions: DocumentPermissions | undefined,
  reviewOwner: string | undefined,
  videoSourceData: VideoSourceData,
  title: string | undefined,
  reviewId: string,
  createTime: number | undefined,
  browserId: string,
  user: User | undefined,
  firebaseDb: FirebaseDb,
  visitCountRecorded: MutableRefObject<boolean>,
  regenMeta:
    | (() => Promise<FirebaseEventMetaStatsEntry & { visits: ReviewFirebaseEntry['visits'] }>)
    | undefined,
) {
  if (documentPermissions && user) {
    await recordRecentReview({
      reviewOwner: reviewOwner,
      videoSourceData: videoSourceData,
      title: title,
      reviewId: reviewId,
      createdTime: createTime,
      user: user,
      documentPermissions: documentPermissions,
      firebaseDb: firebaseDb,
      regenMeta: regenMeta,
    })
  }
  if (!visitCountRecorded.current) {
    visitCountRecorded.current = true
    await addReviewViewedEntry({
      reviewId: reviewId,
      user: user,
      browserId,
      firebaseDb: firebaseDb,
    })
  }
}

async function repairReviewMetaData({
  review,
  reviewId,
  user,
  videoSourceData,
  firebaseDb,
  title,
  isLocalReview,
}: {
  review: ReviewFirebaseEntry
  reviewId: string
  user: User | undefined
  videoSourceData: VideoSourceData | undefined
  firebaseDb: FirebaseDb
  title: string | undefined
  documentPermissions?: DocumentPermissions
  isLocalReview: boolean
}) {
  repairMissingPlayers(review, reviewId, firebaseDb)

  if (!(user && reviewId && videoSourceData)) return
  const { reviewOwner } = await repairMissingOwner(
    review,
    reviewId,
    user,
    isLocalReview,
    firebaseDb,
  )
  const createTime = await repairMissingCreateTime(review, reviewId, reviewOwner, user, firebaseDb)
  const actualTitle = await repairMissingTitle(
    reviewOwner,
    reviewId,
    title,
    review,
    user,
    firebaseDb,
  )
  await repairMissingVideoSource(
    reviewOwner,
    reviewId,
    videoSourceData.source,
    review,
    user,
    firebaseDb,
  )
  return { reviewOwner, createTime, actualTitle }
}

async function repairMissingPlayers(
  review: ReviewFirebaseEntry,
  reviewId: string,
  firebaseDb: FirebaseDb,
) {
  if (!review.events) return
  const eventPlayerDictionary = Object.values(review.events)
    .flatMap((event) => event.who || [])
    .reduce(
      (dictionary, currentValue) => {
        if (!dictionary[currentValue.id]) {
          dictionary[currentValue.id] = currentValue
        }
        return dictionary
      },
      { ...review.players },
    )

  if (
    JSON.stringify(Object.keys(review.players || {})) !==
    JSON.stringify(Object.keys(eventPlayerDictionary))
  ) {
    await firebaseDb.getRef(`reviews/${reviewId}/players`).set(eventPlayerDictionary)
  }
}

async function claimOwnership(reviewId: string, user: User, firebaseDb: FirebaseDb) {
  await firebaseDb.getRef(`reviews/${reviewId}/owner`).set(user.uid)
}

async function claimCreateDate(reviewId: string, firebaseDb: FirebaseDb) {
  await firebaseDb.getRef(`reviews/${reviewId}/createdTime`).set(Date.now())
}

async function repairMissingOwner(
  review: ReviewFirebaseEntry,
  reviewId: string,
  user: User,
  isLocalReview: boolean,
  firebaseDb: FirebaseDb,
): Promise<{ reviewOwner: string | undefined }> {
  let reviewOwner = review.owner
  if (!review.owner && isLocalReview) {
    reviewOwner = user.uid
    await firebaseDb.getRef(`reviews/${reviewId}/owner`).set(user.uid)
  }
  return { reviewOwner: reviewOwner ?? undefined }
}

async function repairMissingCreateTime(
  review: ReviewFirebaseEntry,
  reviewId: string,
  reviewOwner: string | undefined,
  user: User,
  firebaseDb: FirebaseDb,
): Promise<number | undefined> {
  if (reviewOwner === user.uid && review.createdTime === undefined) {
    const createdTime = Date.now()
    await firebaseDb.getRef(`reviews/${reviewId}/createdTime`).set(createdTime)
    return createdTime
  }
  return review.createdTime ?? undefined
}

async function repairMissingTitle(
  reviewOwner: string | undefined,
  reviewId: string,
  title: string | undefined,
  review: ReviewFirebaseEntry,
  user: User,
  firebaseDb: FirebaseDb,
): Promise<string | undefined> {
  if (reviewOwner === user.uid && title && !review.title) {
    await firebaseDb.getRef(`reviews/${reviewId}/title`).set(title)
    return title
  }
  return review.title ?? undefined
}

async function repairMissingVideoSource(
  reviewOwner: string | undefined,
  reviewId: string,
  source: FirebaseVideoSource | undefined,
  review: ReviewFirebaseEntry,
  user: User,
  firebaseDb: FirebaseDb,
) {
  if (reviewOwner === user.uid && !review.source) {
    await firebaseDb.getRef(`reviews/${reviewId}/source`).set(source)
  }
}

export async function createRecentReviewEntry({
  reviewOwner,
  videoSourceData,
  title,
  reviewId,
  createdTime,
  documentPermissions,
  firebaseDb,
}: {
  reviewOwner: string | undefined
  videoSourceData: VideoSourceData
  title: string | undefined
  reviewId: string
  createdTime: number | undefined
  documentPermissions: DocumentPermissions
  firebaseDb: FirebaseDb
}): Promise<RecentsFirebaseEntry> {
  return {
    reviewId: reviewId,
    lastVisited: Date.now(),
    videoId: videoSourceData.id,
    source: videoSourceData.source,
    createdTime: createdTime ?? null,
    ownerUID: reviewOwner || null,
    title: title || null,
    mode: documentPermissions || null,
    isPublicStatsRecord: !!(await getStatRecordAlias({
      firebase: firebaseDb,
      reviewId,
    })),
    isAttachedToStatsRecord:
      (await getParentStatRecordId({
        firebase: firebaseDb,
        reviewId,
      })) ?? null,
  }
}
export async function getReviewEntryRefs(
  reviewOwner: string | undefined,
  user: User,
  reviewId: string,
  firebaseDb: FirebaseDb,
) {
  const refs: FirebaseDbReference<RecentsFirebaseEntry & MetaStatsCache>[] = []
  //TODO(If the review is locked to a group, we should not record it in the user's recent reviews.)
  if (reviewOwner !== user.uid) {
    refs.push(firebaseDb.getRef(`users/${user.uid}/reviews/visited/${reviewId}`))
  } else {
    refs.push(firebaseDb.getRef(`users/${user.uid}/reviews/owned/${reviewId}`))
  }

  const groupsMap =
    (await firebaseDb.getVal<ReviewFirebaseEntry['groups']>(`reviews/${reviewId}/groups`)) ?? {}

  Object.keys(groupsMap).forEach((groupId) => {
    refs.push(firebaseDb.getRef<RecentsFirebaseEntry & MetaStatsCache>(`groups/${groupId}/reviews/${reviewId}`))
  })

  return refs
}
async function recordRecentReview({
  reviewOwner,
  videoSourceData,
  title,
  reviewId,
  createdTime,
  user,
  documentPermissions,
  firebaseDb,
  regenMeta: regenMeta,
}: {
  reviewOwner: string | undefined
  videoSourceData: VideoSourceData
  title: string | undefined
  reviewId: string
  createdTime: number | undefined
  user: User
  documentPermissions: DocumentPermissions
  firebaseDb: FirebaseDb
  regenMeta:
    | (() => Promise<FirebaseEventMetaStatsEntry & { visits: ReviewFirebaseEntry['visits'] }>)
    | undefined
}) {
  const refs = await getReviewEntryRefs(
    reviewOwner,
    user,
    reviewId,
    firebaseDb,
  )
  //TODO(If the review is locked to a group, we should not record it in the user's recent reviews.)

  const recentsEntry: RecentsFirebaseEntry = await createRecentReviewEntry({
    reviewOwner,
    videoSourceData,
    title,
    reviewId,
    createdTime,
    documentPermissions,
    firebaseDb,
  })

  const meta = (await regenMeta?.()) ?? null
  await Promise.all(
    refs.map((ref) =>
      ref
        .getVal()
        .then((it) => {
          const newentry = {
            ...(it ?? {}),
            ...recentsEntry,
            metaStatsUserCache: {
              ...(it?.metaStatsUserCache ?? {}),
              [user.uid]: { commentCount: meta?.commentCount ?? 0 },
            } satisfies MetaStatsCache['metaStatsUserCache'],
            metaStats: meta,
          } satisfies RecentsFirebaseEntry & MetaStatsCache
          return ref.set(newentry)
        })
        .catch((e) => {
          console.warn('Error recording recent review', e)
        }),
    ),
  )
}

async function addReviewViewedEntry({
  reviewId,
  browserId,
  user,
  firebaseDb,
}: {
  reviewId: string
  browserId: string
  user: User | undefined
  firebaseDb: FirebaseDb
}) {
  await firebaseDb
    .getRef(`reviews/${reviewId}/visits/${user ? user.uid : `anon_${browserId}`}`)
    .runTransaction((it) => {
      const views = it as number | null
      return (views ?? 0) + 1
    })
}
