import { User } from 'firebase/auth'
import { orderByChild } from 'firebase/database'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { v4 as randomUUID } from 'uuid'
import {
  FirebaseDb,
  FirebaseDbReference,
  useDatabaseRefLiveValue,
} from '../components/common/Firebase'
import { PropType } from '../components/common/utils/typescriptUtils'
import { Player } from '../data/Player'
import { useParentStatRecord } from '../hooks/UseStatRecordReviewId'
import { FILTER_STAT_RECORD_PLAYERS, mergeDistinctWithOverride } from './TimelineStore'
import { TimelineStorage } from 'AppDataRepository'
import { PlayerFirebaseEntry, FirebaseNameChangeEntry } from 'data/common'

export type PlayerStore = PlayerRepo & {
  togglePlayerSelection: (id: PropType<Player, 'id'>) => void
  setPlayerSelections: (ids: PropType<Player, 'id'>[]) => void
  selectedPlayers: Player[] | undefined
}

export interface PlayerRepo {
  // request is true if the user is requesting a name change
  updatePlayerName: (player: Player, name: string, request?: boolean) => Promise<void>
  createPlayer: (team: number, match: number, name?: string) => Promise<Player>
  deletePlayer: (id: PropType<Player, 'id'>) => Promise<Player | undefined>
  players: Player[]
  getPlayer: (id: PropType<Player, 'id'>) => Player | undefined
}

export const ColorPalette = [
  '#0096cc',
  '#28C878',
  '#8706ff',
  '#FF7828',
  '#004AAD',
  '#ff3636',
  '#96bf01',
  '#ff41e0',
  '#16ad89',
  '#5200b2',
  '#13693e',
]

export function generateRandomColor(): string {
  return `hsl(${Math.random() * 360}, ${50 + Math.random() * 50}%, ${50 + Math.random() * 50}%)`
}

type PlayerFirebaseDictionary = {
  [key: PropType<Player, 'id'>]: PlayerFirebaseEntry
}

export function useFirebasePlayerStore({
  user,
  reviewId,
  firebase,
  /**
   * This will treat review players as external players
   */
  treatAsExternal,
}: {
  user: User | null
  reviewId: string | undefined
  firebase: FirebaseDb
  treatAsExternal?: boolean
}): PlayerStore {
  const initialPlayerSelection = useMemo(() => {
    const newUrl = new URL(window.location.href)
    return newUrl.searchParams.getAll('player')
  }, [])

  useEffect(() => {
    const newUrl = new URL(window.location.href)
    newUrl.searchParams.delete('player')
    window.history.replaceState({ path: newUrl.toString() }, '', newUrl.toString())
  }, [])
  const [playerIdSelections, setPlayerIdSelections] =
    useState<PropType<Player, 'id'>[]>(initialPlayerSelection)

  const reviewPlayerRef = useMemo(
    () =>
      (reviewId && firebase.getRef(`reviews/${reviewId}/players`, orderByChild('index'))) ||
      undefined,
    [reviewId, firebase],
  )
  const statRecord = useParentStatRecord({ reviewId, firebase })
  const statRecordPlayerRef = useMemo(
    () =>
      (statRecord?.reviewId &&
        firebase.getRef(`reviews/${statRecord?.reviewId}/players`, orderByChild('index'))) ||
      undefined,
    [statRecord?.reviewId, firebase],
  )
  const reviewPlayerRepo = useFirebasePlayerRepo(
    user,
    reviewPlayerRef,
    treatAsExternal ? 'external' : 'local',
  )

  const statRecordPlayerRepo = useFirebasePlayerRepo(user, statRecordPlayerRef, 'stat_record')
  const {
    createPlayer: reviewRepoCreatePlayer,
    deletePlayer: reviewRepoDeletePlayer,
    getPlayer: reviewRepoGetPlayer,
    players: reviewRepoPlayers,
    updatePlayerName: reviewRepoUpdatePlayerName,
  } = reviewPlayerRepo
  const {
    getPlayer: statRecordGetPlayer,
    players: statRecordPlayers,
    updatePlayerName: statRecordRepoUpdatePlayerName,
  } = statRecordPlayerRepo

  const togglePlayerSelection = useCallback((id: PropType<Player, 'id'>) => {
    setPlayerIdSelections((ids) => {
      if (ids.includes(id)) {
        return ids.exclude(id)
      } else {
        return ids.concat(id)
      }
    })
  }, [])

  const setPlayerSelections = useCallback((ids: PropType<Player, 'id'>[]) => {
    setPlayerIdSelections([...ids])
  }, [])

  const selectedPlayers = useMemo(
    () => playerIdSelections.mapNotNull((id) => reviewRepoGetPlayer(id) ?? statRecordGetPlayer(id)),
    [playerIdSelections, reviewRepoGetPlayer, statRecordGetPlayer],
  )

  const filteredStatRecordPlayers = useMemo(
    () => statRecordPlayers.filter(FILTER_STAT_RECORD_PLAYERS(statRecord)),
    [statRecordPlayers, statRecord],
  )

  return useMemo(
    () => ({
      createPlayer: reviewRepoCreatePlayer,
      deletePlayer: reviewRepoDeletePlayer,
      getPlayer: (id) => reviewRepoGetPlayer(id) ?? statRecordGetPlayer(id),
      players: mergeDistinctWithOverride({
        overrider: reviewRepoPlayers,
        base: filteredStatRecordPlayers,
        overrideBy: (it) => it.id,
      }),
      updatePlayerName: async (player, name, request) => {
        await Promise.all([
          reviewRepoUpdatePlayerName(player, name, request ?? treatAsExternal),
          statRecordRepoUpdatePlayerName(player, name, true),
        ])
      },
      selectedPlayers,
      setPlayerSelections,
      togglePlayerSelection,
    }),
    [
      treatAsExternal,
      reviewRepoCreatePlayer,
      reviewRepoDeletePlayer,
      reviewRepoGetPlayer,
      reviewRepoPlayers,
      reviewRepoUpdatePlayerName,
      statRecordRepoUpdatePlayerName,
      statRecordGetPlayer,
      selectedPlayers,
      setPlayerSelections,
      togglePlayerSelection,
      filteredStatRecordPlayers,
    ],
  )
}

export function useFirebasePlayerRepo(
  user: User | null,
  playersRef: FirebaseDbReference | undefined,
  source: Player['source'],
): PlayerRepo {
  const playerDictionary = useDatabaseRefLiveValue({ ref: playersRef }) as
    | PlayerFirebaseDictionary
    | undefined
  const players: Player[] = useMemo(
    () =>
      Object.values(playerDictionary || {})
        .sort((p1, p2) => p1.index - p2.index)
        .map((firebasePlayer) => {
          return TimelineStorage.mapFirebaseToPlayer(source)(firebasePlayer)
        }),
    [playerDictionary, source],
  )
  // only use this when adding one player at a time
  const getNextPlayerColor = (playerDictionary: PlayerFirebaseDictionary) => {
    const playerColors = new Map(
      Object.values(playerDictionary).map((player) => [player.color, player]),
    )
    return ColorPalette.find((color) => !playerColors.has(color)) || generateRandomColor()
  }

  const createPlayer = useCallback(
    async (team: number, matchNumber: number, name?: string): Promise<Player> => {
      const maxIndex = Math.max(
        ...Object.values(playerDictionary || {})
          .map((i) => i.index)
          .filter((i) => i)
          .concat(0),
      )
      const id = randomUUID()
      const newPlayer: Player = {
        id,
        name: name || '',
        color: getNextPlayerColor(playerDictionary || {}),
        index: maxIndex + 1,
        team,
        matchNumber,
        source,
      }

      // check compatibility
      const entry: PlayerFirebaseEntry = TimelineStorage.mapFirebaseFromPlayer(newPlayer)
      if (!playersRef && process.env.NODE_ENV === 'development') {
        console.error('playersRef is undefined')
      }
      playersRef?.child(id).set(entry)
      return newPlayer
    },
    [playerDictionary, playersRef, source],
  )

  const updatePlayerName = useCallback(
    async (player: Player, name: string, request?: boolean) => {
      if (player.source !== source) return

      if (!playersRef) {
        if (process.env.NODE_ENV === 'development') {
          console.error('playersRef is undefined')
        }
        return
      }
      if (!request) {
        const updatedPlayer = { ...player, name }
        if (player.requestedChanges?.name?.changes === name) {
          updatedPlayer.requestedChanges?.name === undefined
        }

        return await playersRef
          .child(player.id)
          .set(TimelineStorage.mapFirebaseFromPlayer(updatedPlayer))
      } else if (user) {
        await playersRef
          .child(player.id)
          .child('requestedChanges')
          .child('name')
          .set({
            changes: name,
            dateSubmitted: Date.now(),
            ownerEmail: user.email,
            ownerName: user.displayName,
            ownerUid: user.uid,
          } satisfies FirebaseNameChangeEntry)
      }
    },
    [playersRef, source, user],
  )

  const getPlayer = useCallback(
    (id: PropType<Player, 'id'>): Player | undefined => players.find((p) => p.id === id),
    [players],
  )

  const deletePlayer = useCallback(
    async (id: PropType<Player, 'id'>): Promise<Player | undefined> => {
      if (!playersRef) return
      const deletedPlayer = getPlayer(id)
      if (!deletedPlayer) return undefined

      await playersRef.child(id).remove()
      return deletedPlayer
    },
    [playersRef, getPlayer],
  )

  return useMemo(
    () => ({
      updatePlayerName,
      createPlayer,
      deletePlayer,
      players,
      getPlayer,
    }),
    [updatePlayerName, createPlayer, deletePlayer, players, getPlayer],
  )
}
