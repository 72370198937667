import { Flex, Image } from '@chakra-ui/react'
import { PwaInstaller } from 'components/common/InstallPwa'
import { RoundButton } from 'components/common/RoundButton'
import { useCallback } from 'react'
import ReactTagManager from 'react-ga4'

export function InstallAppTile({ pwaInstaller }: { pwaInstaller: PwaInstaller | undefined }) {
  const handleInstallTheAppClicked = useCallback(() => {
    if (pwaInstaller?.installPwa) {
      ReactTagManager.event('reviews_list_install_app_clicked', {
        category: 'app_install',
      })
      pwaInstaller.installPwa()
    }
  }, [pwaInstaller])
  return (
    <>
      {pwaInstaller?.installPwa && !pwaInstaller?.appInstallState && (
        <Flex
          direction={'column'}
          alignSelf={'center'}
          style={{
            backgroundColor: '#282c34',
            maxWidth: '200px',
            width: '100%',
            textAlign: 'center',
            padding: '12px',
            borderRadius: '8px',
            color: '#b0b0b0',
            fontFamily: 'LeagueSpartan, sans-serif',
            lineHeight: '1.2em',
            whiteSpace: 'pre-wrap',
            overflowWrap: 'break-word',
          }}>
          Install the PlayBack App
          <br />
          <Image
            alignSelf={'center'}
            src={'/favicon-192x192.png'}
            width={80}
            marginTop={20}
            filter={'drop-shadow(0 0 10px rgb(255,255,255,0.2))'}
            onClick={handleInstallTheAppClicked}
          />
          <br />
          <RoundButton
            alt={'Install Playback'}
            className='m-[8px] justify-center'
            backgroundColor='#DD3D4E'
            color='white'
            onClick={handleInstallTheAppClicked}>
            Install
          </RoundButton>
        </Flex>
      )}
    </>
  )
}
