import { displayTimeRange } from 'components/common/utils/timeUtils'
import { EventDefinition, SKETCH_NOTE_DEFINITION } from 'templates/TemplateConfig'
import { BackgroundIcon } from '../components/common/BackgroundIcon'
import { Player } from '../data/Player'
import { TimelineEvent } from '../data/TimelineEvent'
import playIcon from '../icons/play.png'
import plusButtonIcon from '../icons/plus_button_cutout.png'
import shareIcon from '../icons/share_arrow_button.png'
import squiggleIcon from '../icons/squiggle.svg'
import { PlayerTile } from './PlayerTile'

export function CommentTopInteractionsBar({
  onPlayButtonClicked,
  onShowSketchButtonClicked,
  timelineEvent,
  fontSize,
  playerIconSize,
  editMode,
  canEnableEditing,
  onPlayerDblClick,
  isCommentingPermissions,
  showShareButton,
  onAddPlayerClick,
  onShareButtonClicked,
  translateY = '0',
}: {
  timelineEvent: TimelineEvent<EventDefinition>
  onPlayButtonClicked: () => void
  onShowSketchButtonClicked: () => void
  onAddPlayerClick: (event: React.MouseEvent) => void
  onShareButtonClicked?: () => void
  onPlayerDblClick: (player: Player) => void
  fontSize: number | string
  editMode: boolean
  canEnableEditing: boolean
  playerIconSize: number
  isCommentingPermissions: boolean
  showShareButton: boolean
  translateY?: string
}) {
  const isSketch =
    timelineEvent.definition_key === SKETCH_NOTE_DEFINITION.key ||
    timelineEvent.extra?.drawing?.length
  return (
    <div
      style={{
        transform: `translateY(${translateY})`,
      }}
      className={'relative flex w-full flex-row items-end gap-1'}>
      {!isSketch && (
        <BackgroundIcon
          className='left-[-4px] box-border flex h-[35px] w-[35px] cursor-pointer rounded-full bg-[#bd2026] p-2
            shadow-[0_0_5px_rgba(0,0,0,0.5)] hover:brightness-[120%]'
          src={playIcon}
          onClick={onPlayButtonClicked}
          alt={'Play video where the comment was made'}
        />
      )}
      {isSketch && (
        <BackgroundIcon
          className={`left-[-4px] box-border flex h-[35px] w-[35px] cursor-pointer rounded-full p-2
          shadow-[0_0_5px_rgba(0,0,0,0.5)] hover:brightness-[120%]`}
          style={{
            backgroundColor: SKETCH_NOTE_DEFINITION.color,
          }}
          src={squiggleIcon}
          onClick={onShowSketchButtonClicked}
          alt={'Play video where the sketch was drawn'}
        />
      )}
      <div
        style={{
          fontFamily: 'LeagueSpartan, sans-serif',
          marginBottom: translateY,
        }}
        className={'grow text-left text-[#676767]'}>
        {displayTimeRange(timelineEvent.time)}
      </div>
      <div className='bottom-0 right-0 flex items-center overflow-clip'>
        {isCommentingPermissions && (
          <BackgroundIcon
            className={`transition-[all 100ms ease-in-out] cursor-pointer rounded-full opacity-40 mix-blend-difference
            hover:scale-[120%]`}
            src={plusButtonIcon}
            style={{
              width: playerIconSize * 0.8,
              height: playerIconSize * 0.8,
            }}
            onClick={onAddPlayerClick}
          />
        )}
        {timelineEvent.who
          ?.slice()
          .reverse()
          .map((w) => (
            <PlayerTile
              key={w.id}
              player={w}
              editMode={editMode}
              canEnableEditing={canEnableEditing}
              size={playerIconSize}
              displayStyle={'icon-only'}
              onPlayerDblClick={onPlayerDblClick}
            />
          ))}
      </div>
      {onShareButtonClicked && (
        <BackgroundIcon
          className={`box-border flex h-6 cursor-pointer rounded-full ${showShareButton ? 'opacity-70' : 'opacity-0'}
          hover:opacity-100 hover:drop-shadow-lg`}
          src={shareIcon}
          onClick={onShareButtonClicked}
          alt={'Share this comment'}
        />
      )}
    </div>
  )
}
