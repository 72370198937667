import {
  CSSProperties,
  ChangeEvent,
  ChangeEventHandler,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

export function useToggleSwitch(initial: boolean) {
  const [checked, setChecked] = useState(initial)
  const inputRef = useRef<HTMLInputElement>(null)
  const onChanged = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked)
  }, [])

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.checked = initial
    }
  }, [inputRef, initial])

  const Switch = useCallback(
    (props: { height?: number }) => (
      <ToggleSwitch
        ref={inputRef}
        height={props.height}
        onChanged={onChanged}
      />
    ),
    [onChanged],
  )
  return { Toggle: Switch, checked, setChecked }
}
export type ToggleSwitchProps = {
  height?: number
  onChanged: ChangeEventHandler<HTMLInputElement>
}

export const ToggleSwitch = forwardRef<HTMLInputElement, ToggleSwitchProps>(
  function ToggleSwitch(props, ref) {
    const labelref = useRef<HTMLLabelElement>(null)
    return (
      <label
        ref={labelref}
        className='relative inline-block h-[calc(var(--switch-height)_*_1px)] w-[calc(var(--switch-height)_*_2.5px)]'
        style={{ ['--switch-height']: props.height ?? 16 } as CSSProperties}>
        <input
          className='peer/toggle h-0 w-0 opacity-0'
          ref={ref}
          type='checkbox'
          onChange={props.onChanged}
        />
        <span
          className='duration-400 absolute inset-0 cursor-pointer rounded-[99999px] bg-[#dd3d4e] shadow-[0_0_1px_#dd3d4e]
            transition before:absolute before:bottom-[calc(var(--switch-height)_*_-0.25px)] before:left-0
            before:h-[calc(var(--switch-height)_*_1.5px)] before:w-[calc(var(--switch-height)_*_1.5px)]
            before:rounded-[50%] before:bg-white before:transition-[0.4s] before:content-[""]
            peer-checked/toggle:before:translate-x-[calc((var(--switch-height)_*_1px))]'></span>
      </label>
    )
  },
)
