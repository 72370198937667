import { FirebaseOptions } from 'firebase/app'
import { createContext, useContext } from 'react'
import { initFirebase } from '../Firebase'
import { getQueryStringValues } from '../utils/QueryString'

/**
 * Custom hook to retrieve the value of a feature flag.
 * @param feature - The name of the feature flag. e.g "enable-teams".
 * @param defaultValue - The default value for the feature flag. e.g "false"
 * @param safeInProd - Whether the flag is "data safe" to test in production. Data safe means that data in production will not be corrupted or lost if the feature is enabled.
 * @returns An object containing the value of the feature flag.
 */
export function useFeatureFlag(
  feature: string,
  defaultValue: boolean,
  safeInProd?: boolean,
): { value: boolean }
export function useFeatureFlag<T extends string | number | boolean>(
  feature: string,
  defaultValue: T,
  safeInProd = false,
): { value: T } {
  const flagProvider = useContext(FlagsProviderContext)

  return {
    value: flagProvider?.getFlagValue<T>(feature, defaultValue, safeInProd) ?? defaultValue,
  }
}

/**
 * Returns an array of default flag providers based on the given Firebase configuration.
 * @param firebaseConfig - The Firebase configuration options.
 * @returns An array of default flag providers.
 */
export const getDefaultFlagProviders = (firebaseConfig?: FirebaseOptions) => {
  const providers = [createUrlFlags()]
  if (firebaseConfig) providers.push(createFirebaseRemoteConfigFlags(firebaseConfig))

  return providers
}

/**
 * Context for the flag providers.
 */
const FlagsProviderContext = createContext<FlagProvider | undefined>(undefined)

/**
 * Provider component for the flag providers.
 * @param props - The component props.
 * @param props.children - The child components.
 * @param props.flagProviders - The flag providers.
 * @returns The rendered component.
 */
export function FlagsContextProvider(props: {
  children: React.ReactNode
  flagProviders: FlagProvider[] | undefined
}) {
  return (
    <FlagsProviderContext.Provider
      value={props.flagProviders && createCompositeFlags(props.flagProviders)}>
      {props.children}
    </FlagsProviderContext.Provider>
  )
}

/**
 * Interface for a flag provider.
 */
type FlagProvider = {
  /**
   * Retrieves the value of a feature flag.
   * @param feature - The name of the feature flag.
   * @param safeInProd - Whether the flag is safe to use in production.
   * @returns The value of the feature flag.
   */
  getFlagValue: <T extends number | string | boolean>(
    feature: string,
    // we only use this value to handle typing. The actual value is not used.
    typeHint: T,
    safeInProd?: boolean,
  ) => T | undefined
}

/**
 * Creates a composite flag provider from multiple flag providers.
 * @param flagProviders - The flag providers to combine.
 * @returns A composite flag provider.
 */
export function createCompositeFlags(flagProviders: FlagProvider[]): FlagProvider {
  return {
    getFlagValue: <T extends number | string | boolean>(
      feature: string,
      defaultValue: T,
      safeInProd?: boolean,
    ) => {
      for (const provider of flagProviders) {
        const value = provider.getFlagValue(feature, defaultValue, safeInProd)
        if (value !== undefined) return value as T
      }
      return undefined
    },
  }
}

/**
 * Creates a flag provider based on URL query parameters.
 * @returns A flag provider.
 */
export function createUrlFlags(): FlagProvider {
  return {
    getFlagValue: <T extends number | string | boolean>(
      feature: string,
      typeHint: T,
      safeInProd?: boolean,
    ) => {
      if (process.env.NODE_ENV === 'development' || safeInProd) {
        const flagString = getQueryStringValues('flag')?.find((f) => f.startsWith(feature))

        if (flagString && typeof typeHint === 'boolean' && flagString.split(':')[1]) {
          const value = flagString.split(':')[1]
          if (value === 'true') return true as T
          if (value === 'false') return false as T
          return undefined
        }

        return flagString?.split(':')?.[1] as T | undefined
      }
      return undefined
    },
  }
}

/**
 * Creates a flag provider based on Firebase Remote Config.
 * @param firebaseConfig - The Firebase configuration options.
 * @returns A flag provider.
 */
export function createFirebaseRemoteConfigFlags(firebaseConfig: FirebaseOptions): FlagProvider {
  initFirebase(firebaseConfig)
  return {
    getFlagValue: (feature: string) => {
      return undefined
    },
  }
}
