import { ChevronLeftIcon, ChevronRightIcon, CloseIcon, LinkIcon } from '@chakra-ui/icons'
import { FirebaseBaseLeaderboardEntry, NswdlDivFilter, RoundInfo } from 'data/leaderboardtypes'
import { Suspense, lazy, useMemo, useRef, useState } from 'react'
import { ThreeDots } from 'react-loading-icons'
import { PlayerLeaderboardStatProfile } from './PlayerLeaderboardStatProfile'
import { Measure } from './RankingsByDiv'
import { MeasureDividersTitle, RankedPlayerMeasureResults } from './RankingsTable'
import { ScrollBox } from './ScrollBox'
import { FirebaseDb, FirebaseDbReference } from './common/Firebase'
import { RoundButton } from './common/RoundButton'
import { cn } from './common/utils/tailwindUtils'

const StatsProfileShareButton = lazy(() => import('./PlayerStatsProfileShareButton'))

export default function PlayerStatProfileCarousel({
  MAX_RANKING,
  firebase,
  groupId,
  leaderboardTitle,
  selectedDivision,
  selectedPlayer,
  selectedMeasure,
  onClose,
  rankingsByMeasure,
  leaderboardRef,
  isGroupMember,
  onMeasureSelected,
  onPlayerSelected,
  onDivisionSelected,
  selectedRound,
  includeForFun,
}: {
  onClose: () => void
  selectedPlayer: string
  leaderboardTitle: string | undefined
  firebase: FirebaseDb
  groupId: string
  selectedDivision: NswdlDivFilter
  selectedMeasure: Measure
  selectedRound: RoundInfo | undefined
  rankingsByMeasure: Map<Measure, RankedPlayerMeasureResults[]>
  MAX_RANKING: number
  leaderboardRef: FirebaseDbReference<FirebaseBaseLeaderboardEntry>
  isGroupMember?: boolean
  includeForFun?: boolean
  onMeasureSelected: (measure: Measure) => void
  onDivisionSelected: (division: NswdlDivFilter) => void
  onPlayerSelected?: (player: string) => void
}) {
  const previousPlayerRanking = useMemo(() => {
    const index = rankingsByMeasure
      .get(selectedMeasure)
      ?.findIndex((it) => it.player === selectedPlayer)
    if (index === undefined || index === -1) return undefined
    return rankingsByMeasure.get(selectedMeasure)?.[index - 1]
  }, [rankingsByMeasure, selectedMeasure, selectedPlayer])

  const nextPlayerRanking = useMemo(() => {
    const index = rankingsByMeasure
      .get(selectedMeasure)
      ?.findIndex((it) => it.player === selectedPlayer)

    if ((index === undefined || index === -1) && !previousPlayerRanking)
      return rankingsByMeasure.get(selectedMeasure)?.firstOrNull()

    if (index === undefined || index === -1) return undefined
    return rankingsByMeasure.get(selectedMeasure)?.[index + 1]
  }, [rankingsByMeasure, selectedMeasure, selectedPlayer, previousPlayerRanking])

  const playerProfileRef = useRef<HTMLDivElement>(null)
  const playerProfileThumbnailRef = useRef<HTMLDivElement>(null)
  const [animationKey, setAnimationKey] = useState(0)

  return (
    <div
      className='fixed left-0 top-0 z-10 flex h-svh w-svw flex-col items-center justify-center gap-4 bg-black/80
        shadow-2xl backdrop-blur-sm'>
      <MeasureDividersTitle
        className='mt-4 h-fit'
        measure={selectedMeasure}
        selectedDivision={selectedDivision}
        selectedRound={selectedRound}
      />
      <div className='mb-20 flex min-h-0 w-full flex-1 flex-col items-center justify-stretch gap-2'>
        <div className='flex w-full flex-row justify-center gap-4'>
          <RoundButton
            className='aspect-square'
            onClick={onClose}
            icon={<CloseIcon />}
            alt='close player profile'
          />
          <Suspense fallback={<ThreeDots />}>
            <StatsProfileShareButton
              firebase={firebase}
              groupId={groupId}
              animationRequest={() => setAnimationKey((key) => key + 1)}
              leaderboardTitle={leaderboardTitle}
              playerProfileRef={playerProfileRef}
              playerProfileThumbnailRef={playerProfileThumbnailRef}
              selectedPlayer={selectedPlayer}
            />
          </Suspense>
        </div>
        <div className='flex max-h-[600px] min-h-0 max-w-[100svw] flex-1 flex-row items-end gap-4'>
          <RoundButton
            className='z-10 aspect-square w-fit'
            onClick={() =>
              previousPlayerRanking && onPlayerSelected?.(previousPlayerRanking.player)
            }
            aria-disabled={!previousPlayerRanking}
            icon={
              <ChevronLeftIcon
                w={30}
                h={30}
              />
            }
            alt='close player profile'
          />
          <div
            className='relative box-content h-full max-h-full min-h-0 min-w-0 max-w-[360px] flex-1 overflow-y-visible
              scrollbar-thumb-dark-grey'>
            <PlayerLeaderboardStatProfile
              ref={playerProfileRef}
              // key={selectedPlayer}
              animationKey={animationKey}
              className={cn('h-[600px] max-h-fit w-full max-w-[360px]')}
              firebase={firebase}
              groupId={groupId}
              selectedDivision={selectedDivision}
              selectedMeasure={selectedMeasure}
              includeForFun={includeForFun}
              leaderboardRef={leaderboardRef}
              isMemberOfGroup={isGroupMember}
              playerName={selectedPlayer}
              onMeasureSelected={onMeasureSelected}
              onDivisionSelected={onDivisionSelected}
              measureRankings={rankingsByMeasure}
              maxRanking={MAX_RANKING}
            />
          </div>
          <div className='pointer-events-none absolute opacity-0'>
            <PlayerLeaderboardStatProfile
              ref={playerProfileThumbnailRef}
              // key={selectedPlayer}
              animationKey={animationKey}
              className={cn('pointer-events-none h-[350px] max-h-fit w-full')}
              firebase={firebase}
              groupId={groupId}
              selectedDivision={selectedDivision}
              selectedMeasure={selectedMeasure}
              includeForFun={includeForFun}
              leaderboardRef={leaderboardRef}
              isMemberOfGroup={isGroupMember}
              playerName={selectedPlayer}
              onMeasureSelected={onMeasureSelected}
              onDivisionSelected={onDivisionSelected}
              measureRankings={rankingsByMeasure}
              maxRanking={MAX_RANKING}
              onlySpotlight={true}
            />
          </div>

          <RoundButton
            className='z-10 aspect-square w-fit'
            onClick={() => nextPlayerRanking && onPlayerSelected?.(nextPlayerRanking.player)}
            aria-disabled={!nextPlayerRanking}
            icon={
              <ChevronRightIcon
                w={30}
                h={30}
              />
            }
            alt='close player profile'
          />
        </div>
        <div className='relative flex w-svw max-w-[500px] flex-row items-center justify-between gap-4 px-4'>
          <div className='pointer-events-none flex flex-row items-center gap-4 rounded-full bg-black/60 p-2'>
            <span className='text-nowrap font-montserrat text-sm leading-4 font-semibold'>
              {previousPlayerRanking && (
                <>
                  <span>{previousPlayerRanking.player}</span>
                  {previousPlayerRanking.displayRank <= 20 && (
                    <> 🏆 {previousPlayerRanking.displayRank}</>
                  )}
                </>
              )}
            </span>
          </div>
          <div className='pointer-events-none flex flex-row items-center gap-4 rounded-full bg-black/60 p-2'>
            {nextPlayerRanking && (
              <span className='text-nowrap font-montserrat text-sm leading-4 font-semibold'>
                {nextPlayerRanking.displayRank <= 20 && <>{nextPlayerRanking.displayRank} 🏆 </>}
                <span className=''>{nextPlayerRanking.player}</span>
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
