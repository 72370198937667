import { Box } from '@chakra-ui/react'
import { cn } from 'components/common/utils/tailwindUtils'
import React, {
  CSSProperties,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { isMobile } from 'react-device-detect'
import { useWindowDimensions } from '../UseWindowDimensions'
import { ScrollMaintainingDiv, useScrollState } from '../components/ScrollMaintainingDiv'
import { BackgroundIcon } from '../components/common/BackgroundIcon'
import minimizeButton from '../icons/exit_fullscreen_button.png'
import openExpandButton from '../icons/open_expand_button.png'
import zoomInIcon from '../icons/zoom_in_icon.png'
import zoomOutIcon from '../icons/zoom_out_icon.png'

export function ShowHidePanel({
  userVisibilityState,
  defaultVisibility = false,
  tabIcon,
  children,
  width,
  defaultFontSize = 15,
  title,
  color,
  fontScaleStorageKey,
  style,
  side = 'right',
  showExpandButton = true,
  backgroundColor = 'rgba(17, 24, 27, 0.9)',
  FloatingFooter = () => <></>,
}: PropsWithChildren<{
  scrollable?: boolean
  backgroundColor?: string
  userVisibilityState?: [boolean | undefined, Dispatch<SetStateAction<boolean | undefined>>]
  style?: CSSProperties
  defaultVisibility?: boolean
  tabIcon: string
  width: string | number
  defaultFontSize?: number
  title: string
  color: string
  fontScaleStorageKey?: string
  showExpandButton?: boolean
  side?: 'right' | 'left'
  FloatingFooter?: () => JSX.Element
}>) {
  const windowDimensions = useWindowDimensions()
  const internalUserSetVisibilityState = useState<boolean | undefined>(undefined)
  const [userSetVisibility, setUserSetVisibility] =
    userVisibilityState ?? internalUserSetVisibilityState

  const visible = useMemo(() => {
    if (userSetVisibility === undefined) return defaultVisibility
    else return userSetVisibility
  }, [defaultVisibility, userSetVisibility])

  const handleToggleClick = useCallback(() => {
    setUserSetVisibility((state: boolean | undefined) => {
      if (state === undefined) {
        return !defaultVisibility
      }
      return !state
    })
  }, [setUserSetVisibility, defaultVisibility])

  const scrollState = useScrollState()

  const [isExpanded, setExpanded] = useState<boolean>(false)
  useEffect(() => {
    if (isExpanded) {
      if (document.body !== document.fullscreenElement && isMobile) {
        document.body.requestFullscreen()
      }
    }
  }, [isExpanded])
  const globalFontKey = 'GlobalFontKey'
  const [fontScale, setFontScale] = useState(
    parseFloat(localStorage.getItem(fontScaleStorageKey ?? globalFontKey) ?? '1.0'),
  )
  useEffect(() => {
    localStorage.setItem(fontScaleStorageKey ?? globalFontKey, String(fontScale))
  }, [fontScale, fontScaleStorageKey])
  const contentFontSize = `${fontScale * defaultFontSize}px`
  return (
    <Box
      boxSizing={'border-box'}
      overflowY={'visible'}
      transition={'all 300ms ease-in-out'}
      minHeight={'60%'}
      maxWidth={isExpanded ? '800px' : 'calc(60vw + 40px)'}
      marginLeft={`calc(${visible ? width : '0'} - 40px - ${
        !visible ? '0'
        : isExpanded ? `${Math.min(windowDimensions.width - 50, 800)}px`
        : width
      })`}
      style={{
        position: 'relative',
        transform:
          visible ? 'translateX(0)'
          : side === 'right' ? 'translateX(calc(100% - 40px))'
          : 'translateX(calc(-100% + 40px))',
        height: isExpanded ? '100%' : undefined,
        width:
          !visible ? '0'
          : isExpanded ? windowDimensions.width - 50
          : width,
        ...style,
      }}>
      <Box
        className={cn(
          `pointer-events-auto absolute bottom-0 top-0 mb-auto mt-auto h-[80px] w-[40px] rounded-bl-[16px]
          rounded-tl-[16px] bg-[#f1bf46] shadow-[0_0_10px_rgb(0,0,0,0.5)]`,
          side === 'right' ?
            'rounded-bl-2xl rounded-br-none rounded-tl-2xl rounded-tr-none'
          : 'right-0 rounded-bl-none rounded-br-2xl rounded-tl-none rounded-tr-2xl',
        )}
        _hover={{
          filter: !isMobile ? 'brightness(80%)' : undefined,
        }}
        _active={{ filter: 'brightness(80%)' }}
        style={{
          backgroundColor: color,
        }}
        pointerEvents={'auto'}
        position={'absolute'}
        width={'40px'}
        height={'80px'}
        top={'0'}
        bottom={'0'}
        marginTop={'auto'}
        marginBottom={'auto'}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        alignContent={'center'}
        flexWrap={'wrap'}
        gap={'8px'}
        cursor={'pointer'}
        onClick={handleToggleClick}>
        <BackgroundIcon
          style={{
            // width: '100%',
            height: '40px',
            objectFit: 'scale-down',
            margin: '-8px',
          }}
          src={tabIcon}
          alt={'Show or hide'}
        />
        <div
          style={{
            color: 'black',
            fontFamily: 'LeagueSpartan,sans-serif',
            fontWeight: 550,
            fontSize: '12px',
            textAlign: 'center',
          }}>
          {visible ? 'Hide' : 'Show'}
        </div>
      </Box>
      <Box
        style={{
          marginLeft: side === 'right' ? '40px' : undefined,
          marginRight: side === 'left' ? '40px' : undefined,
          backgroundColor: backgroundColor,
          fontSize: contentFontSize,
        }}
        boxShadow={'0 0 10px 0 rgb(0, 0, 0, 0.5)'}
        position={'relative'}
        pointerEvents={'auto'}
        overflowY={'hidden'}
        display={'flex'}
        flexDirection={'column'}
        height={'100%'}
        borderRadius={'16px'}>
        <Box
          className='text-shadow-custom flex h-[44px] items-center justify-start rounded-tl-lg rounded-tr-lg bg-[#f1bf46]
            py-0 pl-5 pr-3 font-league-spartan text-[20px] font-[550] text-white'
          style={{
            backgroundColor: color,
            textShadow: '1px 0 0 #ffffff',
          }}>
          <Box
            overflowX={'hidden'}
            textOverflow={'ellipsis'}>
            {title}
          </Box>
          <div
            style={{
              flexGrow: 1,
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'flex-end',
              gap: isExpanded ? '12px' : '8px',
            }}>
            <BackgroundIcon
              className='hover:bg-[rgb(0,0,0,0.3)]'
              src={zoomOutIcon}
              onClick={() => setFontScale((it) => Math.max(it - 0.2, 0.8))}
              style={{
                width: isExpanded ? '40px' : '30px',
                padding: '5px',
                boxSizing: 'border-box',
                borderRadius: '4px',
              }}
            />
            <BackgroundIcon
              className='hover:bg-[rgb(0,0,0,0.3)]'
              src={zoomInIcon}
              onClick={() => setFontScale((it) => it + 0.2)}
              style={{
                width: isExpanded ? '40px' : '30px',
                padding: '5px',
                boxSizing: 'border-box',
                borderRadius: '4px',
              }}
            />
            {showExpandButton && (
              <BackgroundIcon
                className='hover:bg-[rgb(0,0,0,0.3)]'
                src={isExpanded ? minimizeButton : openExpandButton}
                onClick={() => setExpanded((it) => !it)}
                style={{
                  width: isExpanded ? '40px' : '30px',
                  padding: '5px',
                  boxSizing: 'border-box',
                  borderRadius: '4px',
                }}
              />
            )}
          </div>
        </Box>
        {
          <ScrollMaintainingDiv
            className='relative box-border flex h-[calc(100%-50px)] flex-col gap-[10px] p-3'
            style={{
              overflowY: 'auto',
              boxShadow: '0 -20px 10px -10px inset rgb(0,0,0,0.5)',
            }}
            useScrollState={scrollState}>
            {children}
          </ScrollMaintainingDiv>
        }
        <Box
          bottom={0}
          zIndex={10}>
          {<FloatingFooter />}
        </Box>
      </Box>
    </Box>
  )
}
