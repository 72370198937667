import i18next from 'i18next'
import I18nPaths from 'locales/i18nPaths'
import '../../util/stringUtils'

export default class DodgeballTemplateLocales {
  static getTitleLocale(eventKey: string): string {
    const titleLocale =
      i18next.t(I18nPaths.Templates_Dodgeball.concat('.', eventKey, '.title')) ?? eventKey
    return titleLocale.toTitleCase()
  }

  static getDescriptionLocale(eventKey: string): string {
    const descriptionLocale =
      i18next.t(I18nPaths.Templates_Dodgeball.concat('.', eventKey, '.description')) ?? eventKey
    return descriptionLocale.toTitleCase()
  }
}
