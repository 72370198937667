import { Flex } from '@chakra-ui/react'
import { Colors } from 'Colors'
import { DocumentPermissions } from 'data/common'
import { User } from 'firebase/auth'
import * as React from 'react'
import {
  CSSProperties,
  ReactElement,
  ReactNode,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { toast } from 'react-toastify'
import {
  EventDefinition,
  POINT_LOSE_DEFINITION,
  POINT_WIN_DEFINITION,
  SKETCH_NOTE_DEFINITION,
} from 'templates/TemplateConfig'
import { Player } from '../data/Player'
import { TimelineEvent } from '../data/TimelineEvent'
import { ViewingMode } from '../data/ViewingMode'
import { isNoteDefinition } from '../data/common'
import addPersonIcon from '../icons/add_person.png'
import binBlackIcon from '../icons/bin_icon.png'
import binWhiteIcon from '../icons/bin_white_icon.png'
import editIcon from '../icons/edit_button_black.png'
import playIcon from '../icons/play.png'
import { getPlayerInitials } from '../ui/PlayerTile'
import { showCommentPanel } from '../ui/VerticalCommentsPanel'
import { isInputEvent } from '../util/inputUtils'
import { Marker } from './Marker'
import { NoteCard } from './NoteCard'
import { ShowPlayerChooser } from './PlayerChooser'
import { InlineShortcutIcon } from './ShortcutIcon'
import { VideoController } from './VideoController'
import { BackgroundIcon } from './common/BackgroundIcon'
import { EditableStringDiv } from './common/EditableStringDiv'
import { LayeredIconView } from './common/LayeredIconView'
import { RoundButton } from './common/RoundButton'
import { useSingleAndDoubleClick } from './common/UseSingleAndDoubleClick'
import { cn } from './common/utils/tailwindUtils'

export interface TimelineMarkerProps<T> {
  positionRelative?: boolean
  event: TimelineEvent<T>
  reversePoints?: boolean
  left?: string
  user: User | undefined
  videoController: VideoController
  showSignInToEditDialog: undefined | (() => void)
  onUpdate?: (event: TimelineEvent<T>) => void
  onClick?: (event: TimelineEvent<T>) => void
  onDeleteClicked?: (event: TimelineEvent<T>) => void
  onEditSketchClicked?: (event: TimelineEvent<T>) => void
  onDblClick?: (event: TimelineEvent<T>) => void
  onLongPress?: (event: TimelineEvent<T>) => void
  onExtraClicked?: (event: TimelineEvent<T>) => void
  onBlur?: (event: TimelineEvent<T>) => void
  viewingMode: ViewingMode
  baseViewingMode: ViewingMode
  documentPermissions: DocumentPermissions | undefined
  showPlayerChooser?: ShowPlayerChooser
  selectedPlayers?: Player[]
  getMatchNumberAtTime: (time: number) => number
  eventFilters?: EventDefinition[]
  opacity?: string
  onHoverEnter?: ((e: React.PointerEvent, event: TimelineEvent<T>) => void) | undefined
  onHoverExit?: ((e: React.PointerEvent, event: TimelineEvent<T>) => void) | undefined
  displayStyle: 'above' | 'below' | 'centre'
  editOnMount?: boolean
  simplified?: boolean
  isFocused?: boolean
  isSemiSelected?: boolean
  aboveHoverPanel?: () => ReactElement
  style?: CSSProperties
}

export function TimelineMarker<T>({ event, ...props }: TimelineMarkerProps<T>): JSX.Element {
  const { isFocused: isFocusedExternally } = props
  const setPlayerButton = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const deleteButtonRef = useRef<RoundButton>(null)
  const playButtonRef = useRef<RoundButton>(null)
  const drawButtonRef = useRef<RoundButton>(null)
  const playerInitials = useRef<HTMLDivElement>(null)
  const extraEditableDivRef = useRef<EditableStringDiv>(null)
  const [editOnMount, setEditOnMount] = useState(props.editOnMount)
  const isNote = isNoteDefinition((event.tag as EventDefinition).key)
  const viewingMode: ViewingMode = props.viewingMode
  const displayStyle = props.displayStyle
  const [isDeletePending, setIsDeletePending] = useState(false)

  const [showPlayerClickInfo, setShowPlayerClickInfo] = useState<() => void>(() => () => {
    toast('Double click to change the Player', {
      position: 'bottom-center',
      type: 'info',
      theme: 'colored',
      autoClose: 5000,
    })

    setShowPlayerClickInfo(() => () => {
      // do nothing
    })
  })
  const [playButtonPosition, _setPlayButtonPosition] = useState('beside')

  const hasFocus = useCallback(() => {
    return (
      containerRef.current?.contains(document.activeElement) ||
      extraEditableDivRef.current?.hasFocus() ||
      deleteButtonRef.current?.hasFocus()
    )
  }, [])

  useEffect(() => {
    if (hasFocus()) return
    if (((event.extra?.message || isNote) && editOnMount) || isFocusedExternally) {
      containerRef.current?.focus()
    }
  }, [event, isNote, hasFocus, editOnMount, isFocusedExternally])

  const handleOnBlur = useCallback(
    (e: React.FocusEvent) => {
      if (!containerRef.current?.contains(e.relatedTarget)) {
        props.onBlur?.(event)
        if (isDeletePending) setIsDeletePending(false)
        // only edit on mount once
        if (editOnMount) {
          setEditOnMount(false)
        }
      }
    },
    [props, isDeletePending, setIsDeletePending, editOnMount, event],
  )

  const handleDeleteButtonBlur = useCallback((e: React.FocusEvent) => {
    e.stopPropagation()
    containerRef.current?.focus()
  }, [])

  const handleOnSetPlayerClicked = useCallback(() => {
    props.showPlayerChooser?.({
      reason: {
        type: 'timeline-marker',
        eventDefinitionKey: event.definition_key,
        time: event.time,
      },
      team: event.team,
      matchNumber: props.getMatchNumberAtTime(event.time),
      displayStyle: displayStyle === 'above' ? 'above' : 'below',
      location: setPlayerButton.current?.getBoundingClientRect(),
      onChoose: (player) => {
        if (player) props.onUpdate?.({ ...event, who: [player] })
      },
    })
  }, [displayStyle, props, event])

  const handleMarkerClick = useSingleAndDoubleClick({
    actionSingleClick: () => {
      props.onClick?.(event)
    },
    actionDoubleClick: () => {
      props.onDblClick?.(event)
    },
    actionLongPress: () => {
      props.onLongPress?.(event)
    },
    stopPointerUpPropagation: true,
  })

  const canDelete =
    (viewingMode === 'edit' || (props.documentPermissions === 'edit' && isNote && props.user)) &&
    event.source === 'local'
  const canEdit =
    (viewingMode === 'edit' || (props.documentPermissions === 'edit' && isNote && props.user)) &&
    event.source === 'local'
  const canPlay = true

  const handlePlayerInitialsClick = useSingleAndDoubleClick({
    actionSingleClick: () => {
      props.onClick?.(event)
      if (canEdit && event.who?.length) showPlayerClickInfo()
      // setNoteExpanded(value => !value)
    },
    actionDoubleClick: () => {
      if (canEdit) {
        if ((event.who?.length ?? 0) <= 1) {
          props.showPlayerChooser?.({
            reason: {
              type: 'timeline-marker',
              eventDefinitionKey: event.definition_key,
              time: event.time,
            },
            team: event.team,
            matchNumber: props.getMatchNumberAtTime(event.time),
            displayStyle: displayStyle === 'above' ? 'above' : 'below',
            location: playerInitials.current?.getBoundingClientRect(),
            onChoose: (player) => {
              if (player)
                props.onUpdate?.({
                  ...event,
                  who: event.who?.filter((it) => it.id === player.id).length ? [] : [player],
                })
            },
          })
        } else {
          props.onDblClick?.(event)
          showCommentPanel()
          props.showPlayerChooser?.({
            reason: {
              type: 'timeline-marker',
              eventDefinitionKey: event.definition_key,
              time: event.time,
            },
            team: event.team,
            matchNumber: props.getMatchNumberAtTime(event.time),
            displayStyle: displayStyle === 'above' ? 'above' : 'below',
            location: setPlayerButton.current?.getBoundingClientRect(),
            onChoose: (player) => {
              if (player) {
                let who = event.who?.slice() ?? []
                if (who.any((it) => it.id === player.id)) {
                  who = who.distinct()
                  who.remove(player)
                  props?.onUpdate?.({
                    ...event,
                    who: who,
                  })
                } else {
                  props?.onUpdate?.({
                    ...event,
                    who: who.concat(player).distinct(),
                  })
                }
              }
            },
          })
        }
      } else {
        props.onDblClick?.(event)
      }
    },
    stopPointerUpPropagation: canEdit,
  })

  const isRoundEnd = ['point_win', 'point_lose', 'point_draw'].includes(
    (event.tag as EventDefinition).key,
  )
  let {
    icon: displayIcon,
    description: displayDescription,
    color: displayColor,
    title: displayTitle,
  } = event
  if (props.reversePoints && isRoundEnd) {
    switch (event.definition_key) {
      case POINT_WIN_DEFINITION.key:
        displayIcon = POINT_LOSE_DEFINITION.icon
        displayTitle = POINT_LOSE_DEFINITION.title
        displayDescription = POINT_LOSE_DEFINITION.description
        displayColor = POINT_LOSE_DEFINITION.color
        break
      case POINT_LOSE_DEFINITION.key:
        displayIcon = POINT_WIN_DEFINITION.icon
        displayTitle = POINT_WIN_DEFINITION.title
        displayDescription = POINT_WIN_DEFINITION.description
        displayColor = POINT_WIN_DEFINITION.color
        break
    }
  }
  const isMatchReset = ['match_reset'].includes((event.tag as EventDefinition).key)

  const onDeleteClicked = props.onDeleteClicked
  const handleDeleteClicked = useCallback(() => {
    if (canDelete && isNote && !event.extra?.message?.trim() && !event.extra?.drawing?.length)
      onDeleteClicked?.(event)
    else if (canDelete) setIsDeletePending(true)
  }, [setIsDeletePending, canDelete, isNote, event, onDeleteClicked])
  const handleConfirmDeleteClicked = useCallback(() => {
    if (canDelete) onDeleteClicked?.(event)
    if (canDelete) setIsDeletePending(false)
  }, [event, onDeleteClicked, setIsDeletePending, canDelete])
  const handleEditSketchClicked = useCallback(
    (ev: React.MouseEvent) => {
      props.onEditSketchClicked?.(event)
      ev.preventDefault()
      containerRef.current?.blur()
    },
    [event, props],
  )

  const handlePlayClicked = useCallback(
    async (event: TimelineEvent<T>) => {
      const playbackRate = props.videoController.currentStatus.playbackRate
      await props.videoController.play()
      await props.videoController.seekTo(event.time - 4 * playbackRate)
    },
    [props.videoController],
  )

  const onKeyDown = useCallback(
    (ev: React.KeyboardEvent) => {
      if (isInputEvent()) return
      if (ev.key === 'Backspace' && !isDeletePending) {
        ev.stopPropagation()
        handleDeleteClicked()
      } else if (ev.key === 'Enter' && isDeletePending) {
        ev.stopPropagation()
        handleConfirmDeleteClicked()
      } else if (ev.key === 'Escape' && isDeletePending) {
        ev.stopPropagation()
        setIsDeletePending(false)
      } else if (ev.key === 'Escape' && !isDeletePending) {
        ev.stopPropagation()
        containerRef.current?.blur()
      }
    },
    [handleDeleteClicked, isDeletePending, handleConfirmDeleteClicked],
  )
  const { onHoverEnter, onHoverExit } = props
  const handleMarkerOnPointerEnter = useCallback(
    (e: React.PointerEvent) => {
      onHoverEnter?.(e, event)
    },
    [onHoverEnter, event],
  )
  const handleMarkerOnPointerOut = useCallback(
    (e: React.PointerEvent) => {
      onHoverExit?.(e, event)
    },
    [onHoverExit, event],
  )
  const handleContainerOnPointerEnter = useCallback((e: React.PointerEvent) => {
    setIsHovering(true)
  }, [])
  const handleContainerOnPointerLeave = useCallback((e: React.PointerEvent) => {
    setIsHovering(false)
  }, [])

  const [isHovering, setIsHovering] = useState(false)

  useEffect(() => {
    const listener = () => {
      if (isHovering) {
        const isActuallyHovered = containerRef.current?.parentElement?.querySelector(':hover')
        if (!isActuallyHovered) {
          setIsHovering(false)
        }
      }
    }

    document.addEventListener('pointermove', listener)
    return () => {
      document.removeEventListener('pointermove', listener)
    }
  }, [isHovering, props.simplified])
  const dontAssignPlayer = isRoundEnd || isMatchReset

  const shouldSimplify = props.simplified && !isHovering && !hasFocus() && !props.isSemiSelected

  return (
    <>
      {shouldSimplify && (
        <div
          className={cn(
            `top-[-5px] flex h-[35px] w-[20px] -translate-x-2/4 translate-y-[24px] cursor-grab justify-center
            rounded-[10px] border-0 border-solid border-white bg-[rgba(141,141,141,0.25)]`,
            isNote ? 'bookmark' : '',
            displayStyle === 'above' ? '!-translate-x-2/4 !-translate-y-[-36px]'
            : displayStyle === 'centre' ?
              '!h-[20px] !-translate-x-2/4 !translate-y-0 !bg-[rgba(141,141,141,0.8)]'
            : '',
          )}
          tabIndex={0}
          style={{
            left: props.left,
            opacity: props.opacity,
            zIndex: 0,
            position: props.positionRelative ? 'relative' : 'absolute',
          }}
          onPointerDown={handleMarkerClick.onDown}
          onPointerUp={handleMarkerClick.onUp}
          onPointerMove={handleMarkerClick.onMove}
          onPointerOver={handleContainerOnPointerEnter}
          onPointerOut={handleContainerOnPointerLeave}
        />
      )}
      {!shouldSimplify && (
        <>
          <Marker
            ref={containerRef}
            _hover={{
              transform: 'scale(120%)',
            }}
            _focusWithin={{
              transform: 'scale(150%)',
            }}
            style={{
              translate: `-50% ${
                displayStyle === 'below' ? '100%'
                : displayStyle === 'above' ? '-100%'
                : '0'
              }`,
              opacity: (props.opacity ?? event.source === 'stat_record') ? 1 : 1,
              filter: 'drop-shadow(0 0 5px rgb(0,0,0,0.3))',
              zIndex:
                hasFocus() ? 3
                : displayStyle === 'centre' ? 2
                : 1,
              left: props.left,
              ...props.style,
            }}
            displayStyle={
              displayStyle === 'above' ? 'down'
              : displayStyle === 'below' ?
                'up'
              : 'circle'
            }
            draggable={false}
            onKeyDown={onKeyDown}
            backgroundColor={displayColor}
            tabIndex={0}
            onBlur={handleOnBlur}
            onPointerOver={handleContainerOnPointerEnter}
            onPointerOut={handleContainerOnPointerLeave}
            tagBackgroundColor={
              event.who && event.who.length === 1 ? event.who[0].color || undefined : 'black'
            }
            outline={props.isSemiSelected || hasFocus()}
            // outlineColor={event.source === 'stat_record' ? 'black' : undefined}
            tag={
              event.who?.length && props.showPlayerChooser ?
                () => (
                  <div
                    ref={playerInitials}
                    onPointerDown={handlePlayerInitialsClick.onDown}
                    onPointerUp={handlePlayerInitialsClick.onUp}
                    onPointerMove={handlePlayerInitialsClick.onMove}>
                    <span
                      translate={'no'}
                      style={{
                        textTransform: 'uppercase',
                        backgroundColor: event.who?.[0].color || 'black',
                        filter: 'invert() brightness(2) grayscale(1) contrast(30)',
                      }}
                      className='bg-inherit bg-clip-text text-transparent'>
                      {event.who?.length === 1 ? getPlayerInitials(event.who[0]) : '👬'}
                    </span>
                  </div>
                )
              : undefined
            }
            verticalUi={() => (
              <>
                {(!event.who?.length && !dontAssignPlayer && props.showPlayerChooser && canEdit && (
                  <div style={{ paddingTop: 4, paddingBottom: 4, width: '100%' }}>
                    <SetPlayerButton
                      ref={setPlayerButton}
                      onClicked={handleOnSetPlayerClicked}
                    />
                  </div>
                )) ||
                  undefined}
                {(event.extra?.message || isNote) &&
                  (isHovering ||
                    hasFocus() ||
                    // isFocusedExternally ||
                    // props.isSemiSelected ||
                    displayStyle === 'below') && (
                    <NoteCard<T>
                      ref={extraEditableDivRef}
                      onClicked={props.onExtraClicked}
                      onBlur={props.onBlur}
                      editMode={props.baseViewingMode === 'edit' && event.source === 'local'}
                      canEnableEditing={
                        props.documentPermissions === 'edit' && event.source === 'local'
                      }
                      onDeleteClicked={props.onDeleteClicked}
                      onUpdate={props.onUpdate}
                      event={event}
                      isSemiSelected={hasFocus() || props.isSemiSelected}
                      editOnMount={editOnMount && isFocusedExternally}
                    />
                  )}
                {isHovering && props.aboveHoverPanel && (
                  <Flex
                    direction={'row'}
                    width={'fit-content'}
                    flexWrap={'nowrap'}
                    style={{
                      overflow: 'visible',
                      paddingBottom: 10,
                      zIndex: 6,
                      gap: 4,
                    }}>
                    {props.aboveHoverPanel?.()}
                  </Flex>
                )}
              </>
            )}
            horizontalUi={
              (hasFocus() &&
                !extraEditableDivRef.current?.hasFocus() &&
                (() => (
                  <Flex
                    direction={'column'}
                    justifyContent={'center'}
                    style={{
                      overflow: 'visible',
                      zIndex: 6,
                      gap: 4,
                    }}>
                    {canEdit && isNote && props.onEditSketchClicked && (
                      <HorizontalUIButton
                        ref={drawButtonRef}
                        alt={'Draw'}
                        backgroundColor={Colors.color_white}
                        color='black'
                        icon={editIcon}
                        onClick={handleEditSketchClicked}>
                        Draw
                      </HorizontalUIButton>
                    )}

                    {canPlay && (
                      <HorizontalUIButton
                        ref={playButtonRef}
                        alt={'Play video where the event was made'}
                        icon={playIcon}
                        iconVerticalPadding='4px'
                        onClick={() => handlePlayClicked(event)}
                        backgroundColor={Colors.color_playback_crimson}
                        color='white'>
                        Play
                      </HorizontalUIButton>
                    )}

                    {canDelete && props.onDeleteClicked && (
                      <HorizontalUIButton
                        ref={deleteButtonRef}
                        alt={'Confirm delete event'}
                        icon={isDeletePending ? binWhiteIcon : binBlackIcon}
                        onBlur={handleDeleteButtonBlur}
                        backgroundColor={isDeletePending ? '#DD3D4E' : '#ffffff'}
                        color={isDeletePending ? 'white' : 'black'}
                        style={{
                          boxShadow: isDeletePending ? '0 0 5px 5px rgb(0,0,0,0.5)' : undefined,
                        }}
                        onClick={
                          isDeletePending ? handleConfirmDeleteClicked : handleDeleteClicked
                        }>
                        {isDeletePending ? 'Confirm?' : ''}
                        {!extraEditableDivRef.current?.hasFocus() && (
                          <InlineShortcutIcon shortcut={isDeletePending ? 'Enter' : 'Backspace'} />
                        )}
                      </HorizontalUIButton>
                    )}
                  </Flex>
                ))) ||
              undefined
            }>
            {event.icon && (
              <>
                <LayeredIconView
                  src={event.extra?.drawing?.length ? SKETCH_NOTE_DEFINITION.icon : displayIcon}
                  draggable={false}
                  style={{
                    width: '100%',
                    height: '100%',
                    borderRadius: '50%',
                    backgroundColor:
                      event.extra?.drawing?.length ? SKETCH_NOTE_DEFINITION.color : displayColor,
                  }}
                  onPointerDown={handleMarkerClick.onDown}
                  onPointerUp={handleMarkerClick.onUp}
                  onPointerMove={handleMarkerClick.onMove}
                  onPointerEnter={handleMarkerOnPointerEnter}
                  onPointerOut={handleMarkerOnPointerOut}
                />
                {event.source === 'stat_record' && (
                  <Flex
                    style={{
                      position: 'absolute',
                      alignItems: 'flex-end',
                      justifyContent: 'flex-start',
                      width: '100%',
                      height: '100%',
                      fontVariationSettings: "'wght' 600",
                      fontSize: '10px',
                      transform: 'translateY(30%)',
                    }}>
                    👁️‍🗨️
                  </Flex>
                )}
              </>
            )}
          </Marker>
        </>
      )}
    </>
  )
}

const HorizontalUIButton = forwardRef<
  RoundButton,
  React.PropsWithChildren<{
    onClick: (e: React.MouseEvent) => void
    alt: string
    icon?: ReactNode
    backgroundColor?: string
    style?: CSSProperties
    color?: string
    onBlur?: (e: React.FocusEvent) => void
    className?: string
    iconVerticalPadding?: string
  }>
>(function HorizontalUIButton(props, ref) {
  return (
    <RoundButton
      ref={ref}
      alt={'Draw'}
      className={cn(
        'pointer-events-auto relative h-[20px] w-fit shadow-md hover:scale-[120%]',
        props.className,
      )}
      iconVerticalPadding={props.iconVerticalPadding}
      tabIndex={0}
      icon={props.icon}
      onClick={props.onClick}
      backgroundColor={props.backgroundColor}
      color={props.color}
      onBlur={props.onBlur}
      style={props.style}>
      {props.children}
    </RoundButton>
  )
})

const SetPlayerButton = forwardRef<HTMLDivElement, { onClicked: () => void }>(
  function SetPlayerButton(props, ref) {
    const handleOnSetPlayerClicked = useSingleAndDoubleClick({
      actionSingleClick: props.onClicked,
    })
    return (
      <div
        ref={ref}
        style={{
          zIndex: 1,
          display: 'flex',
          width: '100%',
          aspectRatio: '1 / 1',
          backgroundColor: '#4D4E53',
          borderRadius: '4px',
          alignItems: 'center',
          justifyContent: 'center',
          pointerEvents: 'all',
        }}
        onPointerDown={handleOnSetPlayerClicked.onDown}
        onPointerUp={handleOnSetPlayerClicked.onUp}
        onPointerMove={handleOnSetPlayerClicked.onMove}>
        <BackgroundIcon
          draggable={false}
          src={addPersonIcon}
          className='absolute aspect-[1/1] w-[calc(100%_-_6px)]'
          alt={'add a player to this event'}
        />
      </div>
    )
  },
)
