import {
  EventDimension,
  EventDimensionsDefinition,
  generateEventDefinitions,
} from 'templates/MetaEvents'
import '../MetaEvents'
import {
  EventDefinition,
  NOTE_DEFINITION,
  UISetup,
  extendBaseSportsTemplate,
} from '../TemplateConfig'
import { ThrowDimensions, _Dodgeball, opposingFromThrow, throws } from './DodgeballDefinitions'

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace DodgeballTemplate {
  export const Definitions = _Dodgeball.BaseDefinitions

  export const buttonSetup: UISetup = extendBaseSportsTemplate({
    defaultViewMode: 'view',
    definitions: Definitions.asMap(NOTE_DEFINITION),
    baseNodes: {
      default: Definitions.getAll('died', 'throw', 'defend', 'defend_catch').asButtonConfigs(
        Definitions,
      ),
      basic: Definitions.getAll('died', 'throw', 'defend', 'defend_catch').asButtonConfigs(
        Definitions,
      ),
    },
    followUpEvents: new Map<EventDefinition, EventDefinition[]>([
      [Definitions.get('defend_catch_success'), [Definitions.get('revive')]],
    ]),
    statCategories: _Dodgeball.generateStatHierarchy(Definitions),
    buttonHierarchy: _Dodgeball.generateButtonConfig(Definitions),
    defaultHighlightSuggestions: [
      Definitions.get('throw_hit'),
      Definitions.get('throw'),
      Definitions.get('defend_catch_success'),
      Definitions.get('died'),
    ],
  })

  // call this whenever a custom button is changed
  export function updateDodgeBallTemplate(
    newThrowDimensions: Partial<
      EventDimensionsDefinition<EventDimension<ThrowDimensions[0], string>>
    >,
  ) {
    Definitions.addDefinitions(
      generateEventDefinitions({
        baseDefinition: throws,
        newDimensions: newThrowDimensions,
        opposing: opposingFromThrow,
      }),
    )

    DodgeballTemplate.buttonSetup.buttonHierarchy = _Dodgeball.generateButtonConfig(Definitions)
    DodgeballTemplate.buttonSetup.statCategories = _Dodgeball.generateStatHierarchy(
      Definitions,
      newThrowDimensions,
    )
  }
}
