import { ReviewFirebaseEntry, VideoSource } from 'data/common'
import {
  FirebaseStatRecordEntry,
  StatRecord,
  StatRecordAlias,
  StatRecordStorage,
} from 'data/statrecordtypes'
import { useMemo } from 'react'
import { ReviewActivityType } from 'ui/ReviewMetaStore'
import { FirebaseDb, useDatabasePathLiveValue } from '../components/common/Firebase'
import { publishNewBaseRecordAlias, useStatsRecordStore } from './UseStatsRecordStore'

export async function getParentStatRecordEntry({
  firebase,
  reviewId,
}: {
  firebase: FirebaseDb
  reviewId: string
}) {
  const statsRecordId = await firebase.getVal(`reviews/${reviewId}/parentStatRecord`)
  return statsRecordId ? getStatRecord({ firebase, statsRecordId }) : undefined
}

/**
 * returns the statRecord if one exists for the given review
 * @param firebase
 * @param reviewId
 */
export async function getStatRecordAlias({
  firebase,
  reviewId,
}: {
  firebase: FirebaseDb
  reviewId: string
}): Promise<StatRecordAlias | undefined> {
  const result = await firebase.getVal<ReviewFirebaseEntry['statRecordAlias']>(
    `reviews/${reviewId}/statRecordAlias`,
  )
  if (typeof result === 'string')
    return StatRecordStorage.mapFirebaseToStatRecordAlias({ allTeams: result })

  return result && StatRecordStorage.mapFirebaseToStatRecordAlias(result)
}

/**
 * returns the statRecord if one exists for the given review
 * @param firebase
 * @param reviewId
 */
export async function getReviewWatcherStatRecordId({
  firebase,
  reviewId,
}: {
  firebase: FirebaseDb
  reviewId: string
}): Promise<string> {
  const statRecordAlias = await getStatRecordAlias({ firebase, reviewId })

  const watcherStatRecordId = statRecordAlias?.watcherOnly

  if (watcherStatRecordId) return watcherStatRecordId

  const activityType = await firebase.getVal<ReviewActivityType>(`reviews/${reviewId}/activityType`)
  const videoId = await firebase.getVal<string>(`reviews/${reviewId}/videoId`)
  const source = await firebase.getVal<VideoSource>(`reviews/${reviewId}/source`)

  if (!activityType || !videoId || !source) {
    throw new Error('Could not build WatcherLink')
  }

  const ensureStatRecordAlias =
    statRecordAlias ??
    (await publishNewBaseRecordAlias(firebase, {
      activityType,
      reviewId,
      source,
      videoId,
    }))

  const watcherStatRecord = {
    activityType,
    reviewId,
    source,
    videoId,
    rules: { 0: 'stats_locked', 1: 'stats_locked' },
  } satisfies StatRecord

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const newStatRecordId = firebase.getRef(`stat_records`).push(watcherStatRecord).key!

  const newStatRecordAlias = {
    allTeams: ensureStatRecordAlias.allTeams,
    watcherOnly: newStatRecordId,
    allLinks: {
      ...ensureStatRecordAlias.allLinks,
      watcherStatRecordId: watcherStatRecord.rules,
    },
  } satisfies StatRecordAlias

  await setStatRecordAlias({
    firebase,
    reviewId,
    statRecordAlias: newStatRecordAlias,
  })

  return newStatRecordId
}

/**
 * returns the statRecordId if one exists for the given review
 * @param firebase
 * @param reviewId
 */
export async function getParentStatRecordId({
  firebase,
  reviewId,
}: {
  firebase: FirebaseDb
  reviewId: string
}) {
  return await firebase.getVal(`reviews/${reviewId}/parentStatRecord`)
}

/**
 * Sets the statRecordAlias for the given review
 * @param firebase
 * @param reviewId
 * @param startRecordAlias
 */
export async function setStatRecordAlias({
  firebase,
  reviewId,
  statRecordAlias,
}: {
  firebase: FirebaseDb
  reviewId: string
  statRecordAlias: StatRecordAlias
}) {
  await firebase
    .getRef(`reviews/${reviewId}/statRecordAlias`)
    .set(StatRecordStorage.mapFirebaseFromStatRecordAlias(statRecordAlias))
}

export async function getStatRecord({
  firebase,
  statsRecordId,
}: {
  firebase: FirebaseDb
  statsRecordId: string
}): Promise<StatRecord | undefined> {
  const statRecordEntry = await firebase.getVal<FirebaseStatRecordEntry>(
    `stat_records/${statsRecordId}`,
  )
  return (
    (statRecordEntry && StatRecordStorage.mapFirebaseToStatRecord(statRecordEntry)) || undefined
  )
}

export function useParentStatRecord({
  firebase: firebase,
  reviewId,
}: {
  firebase: FirebaseDb
  reviewId: string | undefined
}): StatRecord | undefined {
  const statRecordId = useDatabasePathLiveValue<string>(
    firebase,
    `reviews/${reviewId}/parentStatRecord`,
  )
  const statRecord = useDatabasePathLiveValue<FirebaseStatRecordEntry>(
    firebase,
    `stat_records/${statRecordId}`,
  )
  return useMemo(
    () => statRecord && StatRecordStorage.mapFirebaseToStatRecord(statRecord),
    [statRecord],
  )
}
