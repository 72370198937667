export const Flags = {
  ENABLE_STAT_RECORDS: true,
  STAFF: [
    // davey
    'AV8a5Tn4goWNWJbtKCZk31BLAt73',
    // duy VN
    '3yDdr7m5L1fAWcmJtksE4z2h54t1',
    // nathan
    'v7K5A5wgbLOlDUK0JvYynnGI3WG2',
    // alvin
    'yzwZTf0grdgAREtg7woJerCNG192',
    'OZZI9hbczIRs8x8i3O4SX1ViFdN2',
    // phillip nong
    'dyoWXURdm3Wpvf1er1Wm7kx6fkT2',
    // josh fuller,
    'kDCUruAjdmfGt28cDXxVLAQK9d72',
    // tran nguyen VN
    'vEDmZXffC4YwIE727wgDqDJyrlg1',
    // Linh,
    'unP6OAeZBjcyJVZINGdctnFgRia2',
    // Phuong VN
    'OZrVOUPRI7MRSlz1aBNrr9WolZg2',
  ],
  PRO_TRIAL: [
    // Simon Harison
    'HACGVpZmZ6S0zQvff4TlVHI0TsZ2',
    // nicholas bull,
    'y19brcd4cqZoOEK4uZfhKU3Z1k62',
  ],
  LEAGUE_PRO: [
    // NSWDL
    'iwy3f8hdrjw',
  ],
}
