/**
 *
 * @param value between 0 .. 1
 * @param min
 * @param max
 */
function mapRange(value: number, min: number, max: number): number {
  const diff = max - min
  return min + value * diff
}

function clampColorBetweenTwoColors(
  value: number,
  hsl1: [number, number, number],
  hsl2: [number, number, number],
): [h: number, s: number, l: number] {
  const [h1, s1, l1] = hsl1
  const [h2, s2, l2] = hsl2
  return [mapRange(value, h1, h2), mapRange(value, s1, s2), mapRange(value, l1, l2)]
}

function clampColorBetweenThreeColors(
  value: number,
  hsl1: [number, number, number],
  hsl2: [number, number, number],
  hsl3: [number, number, number],
): [h: number, s: number, l: number] {
  if (value < 0.5) {
    return clampColorBetweenTwoColors(value * 2, hsl1, hsl2)
  } else {
    return clampColorBetweenTwoColors(value * 2 - 1, hsl2, hsl3)
  }
}

export function clampColorString({
  value,
  maxColor = [109, 64, 55],
  midColor = [80, 80, 50],
  minColor = [0, 100, 62],
}: {
  value: number
  maxColor?: [number, number, number]
  midColor?: [number, number, number]
  minColor?: [number, number, number]
}): string {
  const [h, s, l] = clampColorBetweenThreeColors(value, minColor, midColor, maxColor)
  return `hsl(${Math.floor(h)}deg ${Math.floor(s)}% ${Math.floor(l)}%)`
}
