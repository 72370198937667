import { Player } from 'data/Player'
import { getReviewEntryRefs } from 'data/ReviewSelectionInit'
import { FirebaseStatRecordAliasEntry } from 'data/statrecordtypes'
import { User } from 'firebase/auth'
import { useCallback, useMemo, useState } from 'react'
import {
  EventDefinition,
  POINT_DRAW_DEFINITION,
  POINT_LOSE_DEFINITION,
  POINT_WIN_DEFINITION,
  SPECIAL_DEFINITIONS,
} from 'templates/TemplateConfig'
import { MetaStats, TimelineStorage } from '../AppDataRepository'
import {
  FirebaseDb,
  FirebaseDbReference,
  useDatabaseNullableRefLiveValue,
  useDatabasePathLiveValue,
  useDatabaseRefLiveValue,
  useDatabaseRefLiveValueMemo,
} from '../components/common/Firebase'
import { useDidUpdateEffect, usePromiseState } from '../components/common/utils/reactUtils'
import { TimelineEvent } from '../data/TimelineEvent'
import {
  DocumentPermissions,
  FirebaseActivityType,
  FirebaseEventMetaStatsEntry,
  ReviewFirebaseEntry,
  TimelineMarkerFirebaseDictionary,
  isNoteDefinition,
} from '../data/common'
import { getParentStatRecordEntry } from '../hooks/UseStatRecordReviewId'
import { FILTER_STAT_RECORD_EVENTS, mergeDistinctWithOverride } from './TimelineStore'

import mapFirebaseToTimelineEvent = TimelineStorage.mapFirebaseToTimelineEvent
import mapFirebaseToMetaStats = TimelineStorage.mapFirebaseToMetaStats
import mapFirebaseFromActivityType = TimelineStorage.mapFirebaseFromActivityType
import mapFirebaseToActivityType = TimelineStorage.mapFirebaseToActivityType

export interface ReviewMetaStore {
  title: string | undefined
  changeTitle: ((title: string, mode: DocumentPermissions) => Promise<void>) | undefined
  // To modify the team names use teamNameStore
  teamNames: ReviewFirebaseEntry['teamNames'] | undefined
  activityType: ReviewActivityType | undefined
  changeActivity: ((type: ReviewActivityType) => Promise<void>) | undefined
  // onEventsChanged:
  //   | ((events: TimelineEvent<EventDefinition>[]) => Promise<void>)
  //   | undefined;
  // /**
  //  * This function is called when a requested change is submitted on stat_records
  //  */
  // onRequestedChangeSubmitted:
  //   | ((players: Player[]) => Promise<void>)
  //   | undefined;
  initMeta:
    | (() => Promise<FirebaseEventMetaStatsEntry & { visits: ReviewFirebaseEntry['visits'] }>)
    | undefined
  regenerateMeta:
    | (() => Promise<FirebaseEventMetaStatsEntry & { visits: ReviewFirebaseEntry['visits'] }>)
    | undefined

  commentSummary: string | undefined
  updateCommentSummary: (summary: string) => void
  statRecordAlias: FirebaseStatRecordAliasEntry | undefined
  parentStatRecord: string | undefined
  // only present if the tracking progress was changed during this editing session
  trackingProgressUpdate: number | undefined
}

export function useReviewMetaStore(
  firebaseDb: FirebaseDb,
  user: User | undefined,
  reviewId: string | undefined,
  videoDuration: number | undefined,
  players: Player[] | undefined,
  events: TimelineEvent<EventDefinition>[] | undefined,
): ReviewMetaStore {
  const reviewRef = useMemo(
    () => (reviewId ? firebaseDb.getRef<ReviewFirebaseEntry>(`reviews/${reviewId}`) : undefined),
    [firebaseDb, reviewId],
  )

  const ownerRef = useMemo(() => reviewRef?.childFromKey('owner'), [reviewRef])
  const titleRef = useMemo(() => reviewRef?.childFromKey('title'), [reviewRef])
  const teamNamesRef = useMemo(() => reviewRef?.childFromKey('teamNames'), [reviewRef])
  const statRecordAlias = useDatabaseRefLiveValueMemo(
    () => reviewRef?.childFromKey('statRecordAlias'),
    [reviewRef],
  )
  const parentStatRecord = useDatabaseRefLiveValueMemo(
    () => reviewRef?.childFromKey('parentStatRecord'),
    [reviewRef],
  )
  const activityTypeRef = useMemo(() => reviewRef?.childFromKey('activityType'), [reviewRef])
  const metaStatsRef = useMemo(() => reviewRef?.childFromKey('metaStats'), [reviewRef])
  const commentSummaryRef = useMemo(() => reviewRef?.childFromKey('commentSummary'), [reviewRef])

  const owner = useDatabaseRefLiveValue<string | undefined>({ ref: ownerRef })
  const title = useDatabaseRefLiveValue<string | undefined>({ ref: titleRef })
  const teamNames = useDatabaseRefLiveValue<ReviewFirebaseEntry['teamNames'] | undefined>({
    ref: teamNamesRef,
  })
  const firebaseActivityType = useDatabaseNullableRefLiveValue<FirebaseActivityType>({
    ref: activityTypeRef,
  })
  const activityType: ReviewActivityType | undefined =
    firebaseActivityType === undefined || firebaseActivityType instanceof Error ?
      undefined
    : mapFirebaseToActivityType(firebaseActivityType ?? undefined)

  const commentSummary = useDatabaseRefLiveValue<string | undefined>({
    ref: commentSummaryRef,
  })
  const updateCommentSummary = useCallback(
    async (summary: string) => {
      await commentSummaryRef?.set(summary)
    },
    [commentSummaryRef],
  )
  const recentsMetaStatsRefs = usePromiseState(() => {
    if (!reviewId || !user) return

    return getReviewEntryRefs(owner, user, reviewId, firebaseDb)
  }, [firebaseDb, owner, reviewId, user])
  const updateMetaStats = useCallback(
    async (
      updateMetaStats: (
        currentData: FirebaseEventMetaStatsEntry | undefined,
      ) => FirebaseEventMetaStatsEntry,
    ) => {
      if (!metaStatsRef) return
      await metaStatsRef.runTransaction(updateMetaStats)
      if (!recentsMetaStatsRefs) return
      recentsMetaStatsRefs.forEach(async (it) => {
        try {
          await it.runTransaction(updateMetaStats)
        } finally {
        }
      })
    },
    [metaStatsRef, recentsMetaStatsRefs],
  )

  const initMetaStats = useCallback(async () => {
    return await initializeMetaStats(reviewId, firebaseDb, metaStatsRef)
  }, [firebaseDb, metaStatsRef, reviewId])
  const regenMetaStats = useCallback(
    async (reviewId: string) => {
      return await regenerateMetaStats(reviewId, firebaseDb, metaStatsRef)
    },
    [firebaseDb, metaStatsRef],
  )

  // const recalculateEventMetaStats = useCallback(
  //   async (events: TimelineEvent<EventDefinition>[]) => {
  //     await updateMetaStats((currentData) => {
  //       return createMetaStats(
  //         players,
  //         events,
  //         currentData && mapFirebaseToMetaStats(currentData),
  //         videoDuration,
  //       );
  //     });
  //   },
  //   [updateMetaStats, videoDuration, players],
  // );

  // const recalculatePlayerMetaStats = useCallback(
  //   async (players: Player[]) => {
  //     await updateMetaStats((currentData) => {
  //       return createMetaStats(
  //         players,
  //         events,
  //         currentData && mapFirebaseToMetaStats(currentData),
  //         videoDuration,
  //       );
  //     });
  //   },
  //   [updateMetaStats, videoDuration, events],
  // );

  // update tracking progress only when events change
  const [trackingProgressUpdate, setTrackingProgressUpdate] = useState<number>()

  useDidUpdateEffect(() => {
    ;(events && events.length) ||
      (players &&
        players.length &&
        updateMetaStats((currentData) => {
          const newStats = createMetaStats(
            players,
            events,
            currentData && mapFirebaseToMetaStats(currentData),
            videoDuration,
          )

          setTrackingProgressUpdate(newStats.trackingProgress)

          return newStats
        }))
  }, [events, videoDuration, players])

  const changeTitle = useCallback(
    async (title: string, mode: DocumentPermissions) => {
      if (mode === 'edit' && user && reviewId && reviewRef) {
        const refs = await getReviewEntryRefs(owner, user, reviewId, firebaseDb)
        reviewRef.childFromKey('title').set(title.trim())
        refs.forEach(async (it) => {
          try {
            await it.childFromKey('title').set(title.trim())
          } catch (e) {
            console.error(e)
          }
        })
      }
    },
    [user, reviewId, owner, firebaseDb, reviewRef],
  )
  const changeActivity = useCallback(
    async (type: ReviewActivityType) => {
      if (owner === user?.uid) {
        const mappedType = mapFirebaseFromActivityType(type)
        await firebaseDb.getRef(`reviews/${reviewId}/activityType`).set(mappedType)
        if (user?.uid)
          await firebaseDb
            .getRef(`users/${user.uid}/reviews/owned/${reviewId}/activityType`)
            .set(mappedType)
      }
    },
    [reviewId, user, firebaseDb, owner],
  )

  return useMemo(
    () => ({
      trackingProgressUpdate,

      changeTitle: changeTitle,
      title,
      // onEventsChanged: reviewId ? recalculateEventMetaStats : undefined,
      // onRequestedChangeSubmitted: reviewId
      //   ? recalculatePlayerMetaStats
      //   : undefined,
      initMeta: reviewId ? initMetaStats : undefined,
      regenerateMeta: reviewId ? () => regenMetaStats(reviewId) : undefined,
      updateCommentSummary: updateCommentSummary,
      commentSummary,
      activityType,
      teamNames,
      changeActivity,
      statRecordAlias:
        typeof statRecordAlias === 'string' ? { allTeams: statRecordAlias } : statRecordAlias,
      parentStatRecord,
    }),
    [
      trackingProgressUpdate,
      reviewId,
      changeTitle,
      activityType,
      teamNames,
      changeActivity,
      title,
      regenMetaStats,
      initMetaStats,
      // recalculateEventMetaStats,
      // recalculatePlayerMetaStats,
      commentSummary,
      updateCommentSummary,
      statRecordAlias,
      parentStatRecord,
    ],
  )
}

function calculateReviewTrackingProgress(
  events: TimelineEvent<EventDefinition>[],
  duration?: number,
) {
  const period = 180
  const totalPeriods =
    duration ? Math.min((30 * 60) / period, Math.floor(duration / period)) : (30 * 60) / period
  const excludedEventKeys = SPECIAL_DEFINITIONS.map((it) => it.key)
  const periodsFilled = events.reduce<{ [minute: number]: boolean }>((p, c) => {
    if (excludedEventKeys.includes(c.definition_key)) return p
    p[Math.floor(c.time / period)] = true
    return p
  }, {})
  return Math.min(Object.values(periodsFilled).length / totalPeriods, 1.0)
}

export function createMetaStats(
  players: Player[] | undefined,
  events: TimelineEvent<EventDefinition>[] | undefined,
  existingMeta: MetaStats | undefined,
  videoDuration: number | undefined,
): MetaStats {
  const newTrackingProgress = events && calculateReviewTrackingProgress(events, videoDuration)

  return {
    eventCount: existingMeta?.eventCount ?? 0,
    commentCount: existingMeta?.commentCount ?? 0,
    score: existingMeta?.score ?? { draw: 0, win: 0, lose: 0 },
    ...(events?.reduce(
      (p, c) => {
        if (isNoteDefinition(c.definition_key)) p.commentCount++
        else if (
          (c.definition_key === POINT_WIN_DEFINITION.key && c.team === 0) ||
          (c.definition_key === POINT_LOSE_DEFINITION.key && c.team === 1)
        )
          p.score.win++
        else if (
          (c.definition_key === POINT_LOSE_DEFINITION.key && c.team === 0) ||
          (c.definition_key === POINT_WIN_DEFINITION.key && c.team === 1)
        )
          p.score.lose++
        else if (c.definition_key === POINT_DRAW_DEFINITION.key) p.score.draw++
        else p.eventCount++
        return p
      },
      {
        eventCount: 0,
        commentCount: 0,
        score: { draw: 0, win: 0, lose: 0 },
      },
    ) ?? existingMeta),
    requestedChangesCount:
      players?.filter((it) => it.requestedChanges?.name).length ??
      existingMeta?.requestedChangesCount ??
      0,
    trackingProgress:
      newTrackingProgress ?
        existingMeta?.trackingProgress ?
          Math.max(newTrackingProgress, existingMeta.trackingProgress)
        : newTrackingProgress
      : (existingMeta?.trackingProgress ?? 0),
  } satisfies MetaStats
}

export async function regenerateMetaStats(
  reviewId: string,
  firebaseDb: FirebaseDb,
  metaStatsRef: FirebaseDbReference<ReviewFirebaseEntry['metaStats']> = firebaseDb.getRef(
    `reviews/${reviewId}/metaStats`,
  ),
): Promise<FirebaseEventMetaStatsEntry & { visits: { [id: string]: number } | undefined }> {
  const reviewPlayers: ReviewFirebaseEntry['players'] = (
    await firebaseDb.getRef(`reviews/${reviewId}/players`).get()
  ).val()

  const statRecord =
    reviewId ? await getParentStatRecordEntry({ firebase: firebaseDb, reviewId }) : undefined

  let mergedPlayers: Player[]
  if (statRecord) {
    const statsRecordPlayers: ReviewFirebaseEntry['players'] = (
      await firebaseDb.getRef(`reviews/${statRecord?.reviewId}/players`).get()
    ).val()

    mergedPlayers = mergeDistinctWithOverride({
      overrider: Object.values(reviewPlayers ?? {}).map(
        TimelineStorage.mapFirebaseToPlayer('local'),
      ),
      base: Object.values(statsRecordPlayers ?? {}).map(
        TimelineStorage.mapFirebaseToPlayer('stat_record'),
      ),
      overrideBy: (it) => it.id,
    })
  } else {
    mergedPlayers = Object.values(reviewPlayers ?? {}).map(
      TimelineStorage.mapFirebaseToPlayer('local'),
    )
  }

  const reviewEvents: TimelineMarkerFirebaseDictionary = (
    await firebaseDb.getRef(`reviews/${reviewId}/events`).get()
  ).val()
  const visits: ReviewFirebaseEntry['visits'] = (
    await firebaseDb.getRef(`reviews/${reviewId}/visits`).get()
  ).val()

  let mergedTimeline: TimelineEvent<EventDefinition>[]

  if (statRecord) {
    const statsRecordEvents: TimelineMarkerFirebaseDictionary = (
      await firebaseDb.getRef(`reviews/${statRecord?.reviewId}/events`).get()
    ).val()
    mergedTimeline = mergeDistinctWithOverride({
      overrider: Object.values(reviewEvents ?? {}).map(mapFirebaseToTimelineEvent('local')),
      base: Object.values(statsRecordEvents ?? {})
        .map(mapFirebaseToTimelineEvent('stat_record'))
        .filter(FILTER_STAT_RECORD_EVENTS(statRecord)),
      overrideBy: (it) => it.id,
    })
  } else {
    mergedTimeline = Object.values(reviewEvents ?? {}).map(mapFirebaseToTimelineEvent('local'))
  }

  const newStats = createMetaStats(mergedPlayers, mergedTimeline, undefined, undefined)
  await metaStatsRef.set(newStats)
  return { ...newStats, visits }
}

/*
 * Initializes the meta stats for a review
 */
export async function initializeMetaStats(
  reviewId: string | undefined,
  firebaseDb: FirebaseDb,
  metaStatsRef: FirebaseDbReference<ReviewFirebaseEntry['metaStats']> = firebaseDb.getRef(
    `reviews/${reviewId}/metaStats`,
  ),
): Promise<FirebaseEventMetaStatsEntry & { visits: { [id: string]: number } | undefined }> {
  if (!reviewId)
    return {
      trackingProgress: 0,
      visits: {},
      score: { win: 0, lose: 0, draw: 0 },
      commentCount: 0,
      requestedChangesCount: 0,
      eventCount: 0,
    }
  const snapshot = await metaStatsRef.get()
  const oldMetaStats: FirebaseEventMetaStatsEntry = snapshot.val()

  if (!oldMetaStats || oldMetaStats.trackingProgress === undefined) {
    return regenerateMetaStats(reviewId, firebaseDb, metaStatsRef)
  }

  const reviewPlayers: ReviewFirebaseEntry['players'] = (
    await firebaseDb.getRef(`reviews/${reviewId}/players`).get()
  ).val()

  const statRecord =
    reviewId ? await getParentStatRecordEntry({ firebase: firebaseDb, reviewId }) : undefined

  let mergedPlayers: Player[]
  if (statRecord) {
    const statsRecordPlayers: ReviewFirebaseEntry['players'] = (
      await firebaseDb.getRef(`reviews/${statRecord?.reviewId}/players`).get()
    ).val()

    mergedPlayers = mergeDistinctWithOverride({
      overrider: Object.values(reviewPlayers ?? {}).map(
        TimelineStorage.mapFirebaseToPlayer('local'),
      ),
      base: Object.values(statsRecordPlayers ?? {}).map(
        TimelineStorage.mapFirebaseToPlayer('stat_record'),
      ),
      overrideBy: (it) => it.id,
    })
  } else {
    mergedPlayers = Object.values(reviewPlayers ?? {}).map(
      TimelineStorage.mapFirebaseToPlayer('local'),
    )
  }

  const visits: ReviewFirebaseEntry['visits'] = await firebaseDb
    .getRef(`reviews/${reviewId}/visits`)
    .get()
    .then((it) => it.val())

  return {
    ...createMetaStats(
      mergedPlayers,
      undefined,
      oldMetaStats && mapFirebaseToMetaStats(oldMetaStats),
      undefined,
    ),
    visits: visits,
  }
}

export type ReviewActivityType = 'dodgeball' | 'sports_notes' | 'general_notes'
